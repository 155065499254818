import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';

@Component({
  selector: 'app-parent-dashboard',
  templateUrl: './parent-dashboard.component.html',
  styleUrls: ['./parent-dashboard.component.scss']
})
export class ParentDashboardComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Secure K-12 Parent Dashboard to monitor their children');
    this.metaService.addTags([
      {name: 'keywords', content: 'Ecosystem User friendly parent dashboard'},
      {name: 'description', content: 'Explore our Parent Dashboard, a powerful tool for monitoring and supporting your childs learning journey. Discover how we are transforming education for the innovation era.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/parent-dashboard');
    window.scroll(0,0);
  }

}
