export class Event {
    EventId:number;
    UserId:number;
    EventDate:string;
    StartTime:string;
    EndTime:string;
    Description:string;
    // ClassId:number;
    IsMeeting:boolean;
    IsActive:boolean;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    AcademicYearId:number;
}

export class EventViewData{
    EventInfo: Event;
    ClassMappingList:Array<any>;
    IsSaved:boolean;
    ErrorMessage:string;
    EventListInfo:Array<Event>
}
