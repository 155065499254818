// src/app/store/actions.ts
    
export enum ActionTypes {
    Login = 'Login',
    Guest = '[Product] Remove from cart',
    LoadItems = '[Products] Load items from server',
    LoadSuccess = '[Products] Load success'
  }
  export const Login = payload => {
    return {
      type: ActionTypes.Login,
      payload
    };
  };
  export const Guest = payload => ({
    type: ActionTypes.Guest,
    payload
  });
  