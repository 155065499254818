import { Component, OnInit,EventEmitter } from '@angular/core';
import { SchoolService } from 'src/app/shared/school.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UserService } from 'src/app/shared/user.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { School } from 'src/app/shared/school.model';
import { EditbannerComponent } from './editbanner.component';
@Component({
  selector: 'app-schooledit',
  templateUrl: './schooledit.component.html',
  styleUrls: ['./schooledit.component.scss']
})
export class SchooleditComponent implements OnInit {
  gridApi;
  gridColumnApi;
  public bsModalRef2: BsModalRef;

  public event: EventEmitter<any> = new EventEmitter();
  constructor(public userService:UserService,public service:SchoolService, public bsModalRef: BsModalRef, 
    public modalService:BsModalService, public notificationSrv: NotificationService,
    private commonService:CommonService) { }
  
  schoollist : any = [];
  list: any[] = [];
  // bModalRef:any;
  ngOnInit(): void {
    this.service.getSchoolInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData =  res; 
    });
    // this.service.formData.FileInfo.CroppedContentString
    
  }

  onSave(form:NgForm){
    if(this.service.formData.SchoolId > 0){
    this.service.updateSchool().subscribe((res:School)=> {
      if(res.IsSaved){
        this.notificationSrv.ShowInfo('School saved successfully');
        this.commonService.CallOnRefreshGrid();
        this.service.formData = res;
      }
      else{
        this.notificationSrv.ShowError(res.ErrorMessage);
      }
      
    });
  }
  else{
    this.service.addSchool().subscribe((res:School)=> {
      this.notificationSrv.ShowInfo('School saved successfully');
      this.commonService.CallOnRefreshGrid();
      this.bsModalRef.hide();
    });
  }
}

 openbranchmodal() {
    
    this.bsModalRef2 = this.modalService.show(EditbannerComponent,{class: 'modal-xl box-shadow',ignoreBackdropClick: true});
    this.bsModalRef2.content.event.subscribe(res => {
       this.service.formData.FileInfo = res.data;
      //  this.service.formData.UserInfo.CroppedFileContent = res.data;
    });
  }
  editSchoolBanner()
  {
    this.openbranchmodal();
  }
  generateGuid(){
    this.userService.getnewguid().subscribe((res:any)=> {
      this.service.formData.Id = res;
    });
  }
}
