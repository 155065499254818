import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import{HttpClient,HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from "ngx-bootstrap/modal";
import { CommonModule } from '@angular/common';
import { EmbedVideo } from 'ngx-embed-video';
import {WebcamModule} from 'ngx-webcam';

import { TokenInterceptor } from './auth/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { SmsAuthenticateComponent } from './sms-authenticate/sms-authenticate.component';
import { CustommenuComponent } from './custommenu/custommenu.component';
import { CustomHeader } from './custommenu/custom-header.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AuthGuard } from './auth/auth.gaurd.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SignalrService } from './shared/signalr.service';

import { ProfileHostDirective } from './profile/profile-host.directive';
import { ProfileComponent } from './profile/profile.component';
import { LoginReducer, InitialState, initialState } from './store/reducer';
import { NgReduxModule, NgRedux } from '@angular-redux/store';
import { ValueEducationComponent } from './course-details/value-education/value-education.component';
import { CustomGrideCelComponent } from './custommenu/custom-gride-cel/custom-gride-cel.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CustomControlsComponent } from './custommenu/custom-controls/custom-controls.component';
// import { PaymentComponent } from './payment/payment.component';
import { 
  Ng7LargeFilesUploadLibModule,
  Ng7LargeFilesUploadLibComponent
} from 'ng7-large-files-upload-lib';
// import { CalendarComponent } from './calendar/calendar.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { FaqComponent } from './faq/faq.component';
import { TimeEditorComponent } from './custommenu/time-editor/time-editor.component';
import { ConfirmComponent } from './common/confirm.component';
import { AboutComponent } from './about/about.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { GoogleChartsModule } from 'angular-google-charts';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faStar, faStarHalfAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';


import { GridImageComponent } from './grid-image/grid-image.component';
import { HelpComponent } from './common/help.component';
import { PasswordResetComponent } from './login/password-reset.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { StuOfferComponent } from './student/stu-offer.component';
import { ParentNavComponent } from './navbar/parent-nav.component';
import { StudentNavComponent } from './navbar/student-nav.component';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  // FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { AdminNavComponent } from './navbar/admin-nav.component';
import { SuperadminNavComponent } from './navbar/superadmin-nav.component';
import { TeacherNavComponent } from './navbar/teacher-nav.component';
import { CordinatorNavComponent } from './navbar/cordinator-nav.component';
import { ChatboxComponent } from './feature/chat/component/chatbox.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { ParentDashboardComponent } from './parent-dashboard/parent-dashboard.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { EducatorDashboardComponent } from './educator-dashboard/educator-dashboard.component';
import { ContentProviderDashboardComponent } from './content-provider-dashboard/content-provider-dashboard.component';
import { SocialImpactComponent } from './social-impact/social-impact.component';
import { EcosystemPlatformComponent } from './ecosystem-platform/ecosystem-platform.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ViewYoutubeComponent } from './view-youtube/view-youtube.component';
// import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { YoutubeTestComponent } from './youtube-test/youtube-test.component';
import { YouTubePlayerModule } from "@angular/youtube-player";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ContactComponent,
    HomeComponent,
    SmsAuthenticateComponent,
    CustommenuComponent,
    CustomHeader,
    ProfileHostDirective, 
    ProfileComponent, 
    ValueEducationComponent, 
    CustomGrideCelComponent, CustomControlsComponent, 
      ChatboxComponent,  CustomTooltipComponent, FaqComponent,
       TimeEditorComponent, 
       ConfirmComponent, 
       AboutComponent,  
     GridImageComponent, HelpComponent, PasswordResetComponent, 

     StuOfferComponent,
     ParentNavComponent,
     StudentNavComponent,
     AdminNavComponent,
     SuperadminNavComponent,
     TeacherNavComponent,
     CordinatorNavComponent,
     StudentDashboardComponent,
     ParentDashboardComponent,
     TeacherDashboardComponent,
     EducatorDashboardComponent,
     ContentProviderDashboardComponent,
     SocialImpactComponent,
     EcosystemPlatformComponent,
     PageNotFoundComponent,
     ViewYoutubeComponent,
     YoutubeTestComponent,
  ],
  imports: [
    
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatGridListModule,
    MatRadioModule,
    MatCheckboxModule,
    ModalModule.forRoot(),
    NgReduxModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      
      
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateFactory,
        deps: [HttpClient]
      },
    }),
    CarouselModule,
    MatTabsModule,
    EmbedVideo.forRoot(),
    MatProgressSpinnerModule,
    Ng7LargeFilesUploadLibModule,
    LottieModule.forRoot({ player: playerFactory }),
    GoogleChartsModule,
    FontAwesomeModule,
    WebcamModule,
    SocialLoginModule,
    // NgxYoutubePlayerModule.forRoot(),
    YouTubePlayerModule
  ],
  exports: [
		Ng7LargeFilesUploadLibComponent
	],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },AuthGuard,SignalrService,BnNgIdleService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com'
            )
          },
          // 1043793751452-kbjvih3ts3e48k90hq7e9spd92t1fla8.apps.googleusercontent.com
          // 115997875589-cnjhqsedi2n5m3fjtr4v081k6mofpnre.apps.googleusercontent.com
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('clientId')
          // }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<InitialState>,library: FaIconLibrary) {
    ngRedux.configureStore(LoginReducer, initialState);
    library.addIcons(faStar, faStarHalfAlt, farStar, faTimesCircle);
  }
 }
export function translateFactory(httpClient: HttpClient) {
  //return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
// /*  webpack:///./node_modules/ng7-large-files-upload-lib/__ivy_ngcc__/fesm2015/ng7-large-files-upload-lib.js */