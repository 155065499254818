import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr:ToastrService) { }
   ShowError(msg:string){
    this.toastr.error(msg,'',{positionClass: 'toast-center-center' });
  }
  ShowInfo(msg:string){
    this.toastr.success(msg,'',{positionClass: 'toast-center-center' });
  }
  ShowWarning(msg:string){
this.toastr.warning(msg,'',{positionClass: 'toast-center-center' });
  }
}
