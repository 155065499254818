export class QuestionMaster{
    QuestionMasterId:number;
    SchoolId:number;
    BranchId:number;
    Name:string;
    Description:string;
    ClassId:number;
    SubjectId:number;
    TopicId:number;
    NoOfQuestions:number;
    IsActive:boolean;
    QuestionList:Array<QuestionData>;
    Date:string;
    StartDate:string;
    StartTime:string;
    EndDate:string;
    EndTime:string;
    StudentIdList:Array<number>;
    ResponseFileId:number;
    TotalTime:string;
    ResponseFileIds:string;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    YearId:string;
    AcademicYearId:number;
}
export class QuestionData {
    QuestionId:number = 0;
    QuestionMasterId:number;
    QuestionTypeId:number;
    QuestionType:string;
    Title:string;
    Question:string;
    QuestionNo:number;
    Explanation:string;
    ExternalUrl:string;
    IsActive:boolean;
    QAnswerList:Array<QAnswerData>;
    OptionType : string;
    Points:number;
    
}
export class QAnswerData{
    QAnswerDataId: number;
    QuestionId:number;
    Label:string;
    GroupNo:string;
    GroupNo1:string;
    AnswerNo:number;
    Answer:string;
    Answer1:string;
    IsCorrect:boolean;
    CorrectLabel:string;
    IsActive:boolean;
    IsAnswer:boolean;
    StrAnswer:string;
    isCorrectLabel:string;
    FileId:number;
    FilePath:string;
    
}
export class QuestionList{
    QuestionMasterId: number = 0;
    Name:string;
    Description:string;
    Subject:string;
    Topic:string;
    NoOfQuestions:number;
}
export class QuestionViewData{
    QuestionMasterInfo: QuestionMaster;
    QuestionInfo:Array<QuestionData>;
    QuestionListInfo:QuestionList;
    IsSaved:boolean;
    ErrorMessage:string;
    SubjectList:any;
    TopicList:any;
    QuestionTypeList:any;
    AcademicYearList: any;
}
export class QAssignStudentList{
    Description: string;
    Name: string;
    NoofQuestions: number;
    QuestionMasterId: number;
    StartTime:string;
    EndTim:string;
}
export class ClassData{
    ClassId:number;
    Name:string;
    Description:string;
    IsActive:boolean;
    Id:number;
    IdActive:string;
}
export class StdQuestionMaster{
    QuestionMasterId:number;
    StudentAnswerMasterDataId:number;
    UserId:number;
    NoOfQuestions:number;
    NoOfCorrectAnswers:number;
    IsActive:boolean;
    Comments:string;
    Points:number;
    AssignmentId:number;
    ResponseFilePath:string;
}
export class StdQuestionViewData{
    QuestionMasterInfo: QuestionMaster;
    QuestionInfo:Array<QuestionData>;
    StdAnswerInfo:Array<QuestionData>;
    StdAnswerMasterInfo: StdQuestionMaster;
    ErrorMessage:string;
}