<!-- <p>help works!</p> -->


<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1">{{helpTabName | translate}}</h3>
      </div>
      <div class="modal-body p-2">
          <div *ngIf="helpTabName === 'EditProfile' ">
            <h3>About you and Goal Based Learning plan</h3>
            <p>Write about you and Set Term wise plan your learning goalsso that your teachers and school can help you accordingly.</p>
            <h4>About Student:</h4>
            <p>An ideal student is one who studies dedicatedly, behaves sincerely in school and at home and also takes part in co-curricular activities. Every parent wants his child to become an ideal student who serves as a role model for others.</p>
            <h4>Goal Setting for Learning:</h4>
            <p>Setting goals is a vital practice that can benefit anyone with a dream or a vision for your future. Young people who are just starting out on the grand journey of life are at a particularly opportune time to start building your goal setting skills—not only will these skills serve you throughout your life.</p>
            <p>Parents can encourage goal setting in your children—and absolutely should do so—but the importance of this skill justifies its inclusion in your schools’ curriculum. </p>
          </div>
          <div *ngIf="helpTabName === 'Attendance'">
            <h3>Attendance</h3>
            <p>Your attendance will be stored in system automatically, so don’t miss attending any classes. </p>
            <p>The platform has a plethora of features to make the online class more interesting for the students and also to make the entire teaching activity easier for the teachers. Even if you attend physically teachers take attendance in system.</p>
            
          </div>
          <div *ngIf="helpTabName === 'PBAssignment'">
            <h3>Points Based Assignments.</h3>
            <p>The Golden rule is that whenever a student complete assignments, you will get automatic points. If you present particular assignment from what you learn then you will get additional points from teacher based on your performance.</p>
            
          </div>
          <div *ngIf="helpTabName === 'InteractiveClasses'">
            <h3>Interactive classes</h3>
            <p>In interactive classes you can join a particular class on particular time.</p>
            <p>For students, learning is not effective when they’re listening to a lecture and simply taking notes. Learning for today’s students from all generation is all about combining different multimedia and sensory experiences. Interactive classes scenarios that encourage participation, choice, collaboration, and discussion are much more engaging. In fact, interactive learning helps students learn faster than other learning methods.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Feedback'">
            <h3>Interactive classes</h3>
            <p>In interactive classes you can join a particular class on particular time.</p>
            <p>For students, learning is not effective when they’re listening to a lecture and simply taking notes. Learning for today’s students from all generation is all about combining different multimedia and sensory experiences. Interactive classes scenarios that encourage participation, choice, collaboration, and discussion are much more engaging. In fact, interactive learning helps students learn faster than other learning methods.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Assessments'">
            <h3>Assessments classes</h3>
            <p>When teacher conducts Assessments (Formative, Summative, Slip test, Any kind of Exams) the students will learn better with the Assessments conducted by the teachers and teachers will also understand the learning of students.</p>
            <p>Assessment is important because the information gained can be used to improve student learning, improve teaching, and communicate to others evidence about the learning process. All this progress will update year wise student portfolio.</p>
            
          </div>
          <div *ngIf="helpTabName === 'Assignments'">
            <h3>Assignments classes</h3>
            <p>Teachers assign assignments every day for understanding the topic or the lesson better.</p>
            <p>Assignments are the tasks given to students by their teachers to complete in a defined time. They can also be referred to as the work given to someone as a part of learning. </p>
            
          </div>

          <div *ngIf="helpTabName === 'HomeWork'">
            <h3>Home Work</h3>
            <p>Teachers assign assignments every day for understanding the topic or the lesson better.</p>
            <p>It can help students recognize that learning can occur at home as well as at school. Homework can foster independent learning and responsible character traits. And it can give parents an opportunity to see what's going on at school and let them express positive attitudes toward achievement.</p>
            
          </div>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Cancel</button>
      </div>
    </div>
  </div>
