<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-11">Teacher Dashboard:</h2> 
          <h1 class="w-100 text-left theam-color-4">Fits the needs of Teachers</h1> 
          <h4 class="w-100">Teachers need the tools to experiment with new pedagogies in the classroom, so how can we empower them to become digital innovators?</h4>
                  
        </div>
      </div>
      <section class="bg-theam-body investing-yourself">
        <div class="container-fluid" >
          <div class="position-relative py-80px">
            <div class="row justify-content-center position-relative z-inxex-1">
              <div class="col-12 col-md-8 align-self-center">
                <div class="header ">

                  <h2 class="d-block text-left theam-color-5 title">Why Choose HLEP's Dynamic Teacher Dashboard?</h2>
                  <h2 class="d-block text-left theam-color-9 title">Seamless Teaching Experience:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">HLEP offers a unified dashboard that streamlines your teaching activities, making it easier to manage your courses, assignments, and interactions with students.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Enhanced Engagement:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Utilize multimedia resources, interactive content, and collaborative tools to keep your students engaged and excited about learning.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Adaptive Teaching:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Our platform supports personalized learning, allowing you to tailor your teaching to meet the unique needs and learning styles of your students.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Real-time Insights:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Access real-time data and analytics to monitor student progress, identify areas that need attention, and adjust your teaching strategies accordingly.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Resource Library:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Discover a vast library of educational resources, including lesson plans, multimedia materials, and assessments, to enrich your teaching materials.</h2>
                  
                  <h2 class="d-block text-left theam-color-5 title">Teaching Tools and Features:</h2>
                  <h2 class="d-block text-left theam-color-9 title">Virtual Classroom:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Host live, interactive lessons with our virtual classroom tools, enabling students to participate in real-time discussions and activities.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Hybrid Instruction:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Seamlessly combine in-person and online teaching resources to create a flexible and effective learning environment.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Interactive Content Creation:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Develop engaging course content using videos, quizzes, simulations, and more, fostering deeper understanding and retention.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Assessment and Grading:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Create quizzes, assignments, and assessments with ease, and provide timely feedback to guide your students' progress.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Collaborative Learning: </h2>
                  <h2 class="theam-color-11 m-0 mb-1">Encourage collaboration among students with built-in tools for group projects, discussions, and resource sharing.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Professional Development:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Access a wealth of professional development resources and connect with a community of educators to enhance your teaching skills.</h2>
                  <h2 class="d-block text-left theam-color-5 title">Join the Dynamic Teacher Community:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">At HLEP, we believe in the transformative power of educators. Our platform is built to support you in your mission to inspire and educate the next generation. Whether you're a seasoned educator or just beginning your teaching journey, HLEP welcomes you to explore the endless possibilities of dynamic teaching and learning.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">By joining our dynamic teacher community, you become part of an innovative movement dedicated to reshaping education for the better. Together, we can provide students with the tools they need to succeed and thrive in an ever-changing world.</h2>
                                   
                </div>
              </div>
             </div>
          </div>
          </div>
          
        
      </section>
</div>