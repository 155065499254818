<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="service.formData.EventInfo.EventId<=0">{{'AddEvent' | translate}}</h3>
            <h3 class="modal-title h3 m-1" *ngIf="service.formData.EventInfo.EventId>0">{{'EditEvent' | translate}}</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="modal-body p-2">
                <div >
                    <div  class="row form-row m-0">
                        <div class="form-group col-6 mb-0">
                            <label>Academic year</label>
                            <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel" 
                            [(ngModel)]="service.formData.EventInfo.AcademicYearId">
                              <option [ngValue]="0">Select</option>
                              <option *ngFor="let year of yearList" [ngValue]="year.Id">{{year.Name}}</option>
                            </select>
                          </div>
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Description' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text" placeholder="{{'Description' | translate}}" name="Description" #Description="ngModel" [(ngModel)]="service.formData.EventInfo.Description"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            
                            <div class="form-group mb-0">
                                <label>{{'Select Class' | translate}}</label>
                                <!-- <select class="form-control form-control-sm appearance-auto" name="ClassId" #ClassId="ngModel" 
                                [(ngModel)]="service.formData.EventInfo.ClassId">
                                  <option [ngValue]="0">Select Class</option>
                                  <option *ngFor="let class of classList" [ngValue]="class.Id">{{class.Name}}</option>
                                </select> -->
                                <ng-multiselect-dropdown
                                    [placeholder]="'Search'"
                                    [settings]="dropdownSettings"
                                    name="StdClass"
                                    [data]="classList"
                                    [(ngModel)]="service.formData.ClassMappingList"
                                    (onSelect)="onItemSelect($event,'Class')"
                                    (onSelectAll)="onSelectAll($event,'Class')"
                                    (onDeSelect)="onItemSelect($event,'Class')"
                                    (onSelectAll)="onSelectAll($event,'Class')">
                                    </ng-multiselect-dropdown>
                              </div>
                          </div>
                          
                        <div class="col-6 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'Date' | translate}}</label>
                                <input type="text" 
                                class="form-control form-control-sm" 
                                placeholder="{{'Date' | translate}}" 
                                name="StartDate" 
                                #StartDate="ngModel" 
                                [(ngModel)]="service.formData.EventInfo.EventDate" 
                                [bsConfig]="datepickerConfig" 
                                [minDate]="minDate"
                                bsDatepicker
                                timepicker
                                >
                            </div>
                            
                          </div>
                          <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartTime' | translate}}</label>
                                <ng-template #popTemplate>
                                    <timepicker [(ngModel)]="service.formData.EventInfo.StartTime" name="startTime" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.EventInfo.StartTime| date:'shortTime'" class="form-control form-control-sm" name="startTime" [popover]="popTemplate" [outsideClick]="true" />
                            </div>
                          </div>
                      
                          <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndTime' | translate}}</label>
                                <ng-template #popTemplate1>
                                    <timepicker [(ngModel)]="service.formData.EventInfo.EndTime" name="endTime" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.EventInfo.EndTime | date:'shortTime'" class="form-control form-control-sm" name="endTime" [popover]="popTemplate1" [outsideClick]="true" />
                            </div>
                          </div>
                          
                          <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <label class="mb-2px">&nbsp;</label>
                            <div class="form-groupmb-0">
                                <mat-checkbox class="example-margin"  name="IsMeeting" #IsMeeting="ngModel" 
                                [(ngModel)]="service.formData.EventInfo.IsMeeting" >{{'MeetingUrl' | translate}}</mat-checkbox>
                              </div>
                          </div>
                    </div>
                    <div class="row form-row m-0" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                                [(ngModel)]="service.formData.EventInfo.IsHumanityLife">{{'HumanityLife' |
                                translate}}</mat-checkbox>
                                
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                                [(ngModel)]="service.formData.EventInfo.IsSkills">{{'Skills' | translate}}
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                                [(ngModel)]="service.formData.EventInfo.IsAcademics">{{'Academics' | translate}}
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                                [(ngModel)]="service.formData.EventInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                                translate}}</mat-checkbox>
                        </div>

                    </div>
                </div>
                
            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addEventInfo(form)" class="btn btn-success"  type="button" >Save</button>
                <button type="button" class="btn btn-danger"  (click)="bsModalRef.hide()" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
