// src/app/store/reducer.ts
import { ActionTypes } from './actions';

export interface InitialState {
  User : string
}
export const initialState = {
  User: ""
};

export function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.Login:
      return {
        ...state,
        User: action.payload
      };
    case ActionTypes.Guest:
      return {
        ...state,
        User: action.payload
      };   
    default:
      return state;
  }
}