<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="service.formData.AssignmentInfo.AssignmentId<=0">{{'AddAssignment' |
                translate}}</h3>
            <h3 class="modal-title h3 m-1" *ngIf="service.formData.AssignmentInfo.AssignmentId>0">{{'EditAssignment' |
                translate}}</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off" enctype="multipart/form-data">
            <div class="modal-body p-2">
                <div *ngIf="stepNo == 1">
                    <div class="row form-row m-0">
                        <div class="col-4">
                            <div class="form-group mb-0">
                                <label>{{'Name' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Name' | translate}}" name="Name" #Name="ngModel"
                                    [(ngModel)]="service.formData.AssignmentInfo.Name" required
                                    [class.invalid]="Name.invalid && Name.touched">

                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Type' | translate}}*</label>
                                <select class="form-control form-control-sm appearance-auto" name="TypeId"
                                    #TypeId="ngModel" [(ngModel)]="service.formData.AssignmentInfo.TypeId"
                                    (change)="AssignmentTypeChange()" required
                                    [class.invalid]="TypeId.invalid && TypeId.touched">
                                    <option [ngValue]="0">Select Type</option>
                                    <option *ngFor="let assignType of typeList" [ngValue]="assignType.Id">
                                        {{assignType.Name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="form-group col-4 mb-0">
                            <label>Academic year</label>
                            <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel" 
                            [(ngModel)]="service.formData.AssignmentInfo.AcademicYearId">
                              <option [ngValue]="0">Select</option>
                              <option *ngFor="let year of yearList" [ngValue]="year.Id">{{year.Name}}</option>
                            </select>
                          </div>
                    </div>
                    <div class="row form-row m-0">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.formData.AssignmentInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    timepicker required [class.invalid]="StartDate.invalid && StartDate.touched">
                            </div>

                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartTime' | translate}}</label>
                                <ng-template #popTemplate>
                                    <timepicker [(ngModel)]="service.formData.AssignmentInfo.StartTime" name="StartTime"
                                        [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.AssignmentInfo.StartTime | date:'shortTime'"
                                    class="form-control form-control-sm" name="StartTime" [popover]="popTemplate"
                                    [outsideClick]="true" #StartTime="ngModel" required
                                    [class.invalid]="StartTime.invalid && StartTime.touched" />
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.formData.AssignmentInfo.EndDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker [bsValue]="bsValue" [minDate]="minDate" required
                                    [class.invalid]="EndDate.invalid && EndDate.touched">
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndTime' | translate}}</label>
                                <ng-template #popTemplate1>
                                    <timepicker [(ngModel)]="service.formData.AssignmentInfo.EndTime" name="EndTime"
                                        [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.AssignmentInfo.EndTime | date:'shortTime'"
                                    class="form-control form-control-sm" name="EndTime" [popover]="popTemplate1"
                                    [outsideClick]="true" #EndTime="ngModel" required
                                    [class.invalid]="EndTime.invalid && EndTime.touched" />
                            </div>
                        </div>
                    </div>
                    <div class="row form-row m-0" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.IsHumanityLife">{{'HumanityLife' |
                                translate}}</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.IsSkills">{{'Skills' | translate}}
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.IsAcademics">{{'Academics' | translate}}
                            </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                            <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                                translate}}</mat-checkbox>
                        </div>

                    </div>
                </div>
                <div *ngIf="stepNo == 2">

                    <div *ngIf="assignmentType == 'Watch'">
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    name="UrlType" [(ngModel)]="service.formData.AssignmentInfo.UrlType">
                                    <mat-radio-button class="example-radio-button mr-3" (change)="radioChange($event)"
                                        *ngFor="let videotype of videoTypeList" [value]="videotype">
                                        {{videotype}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div class="row form-row m-0 min-h-60px">
                            <div class="col-4" *ngIf="service.formData.AssignmentInfo.UrlType == 'File'">
                                <div class="form-group mb-0">

                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        (updatedResponseEmitter)="filesuploaded($event)"
                                        title="{{'Upload video' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="">
                                    </mb-large-files-uploader>

                                </div>
                            </div>
                            <div class="col-4" *ngIf="service.formData.AssignmentInfo.UrlType == 'Url'">
                                <div class="form-group mb-0">
                                    <label>{{'Youtube Link' | translate}}</label>
                                    <input class="form-control form-control-sm" name="Url" #Url="ngModel"
                                        [(ngModel)]="service.formData.AssignmentInfo.Url" type='text' required
                                        [class.invalid]="Url.invalid && Url.touched" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <video [src]="url"
                                        *ngIf="(format == 'Watch' || format==='video') && url  && (service.formData.AssignmentInfo.UrlType =='File')"
                                        height="200" controls></video>
                                    <div [innerHtml]="enbedUrl"
                                        *ngIf="(service.formData.AssignmentInfo.UrlType =='Url')"></div>

                                    <img src="../../assets/images/loader/loader.gif" *ngIf="fileUploading" height="200">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="assignmentType == 'Listen'">
                        <div class="row form-row m-0">
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <label>{{'Upload Audio' | translate}}</label>
                                    <input class="form-control form-control-sm" type='file'
                                        (change)="selectAudioFile($event)" name="Url" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <img [src]="url" *ngIf="format==='image' && url" height="200">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="assignmentType == 'Read'">
                        <div class="row form-row m-0">
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <label>{{'Upload PDF' | translate}}</label>
                                    <input class="form-control form-control-sm" type='file'
                                        (change)="selectDocumentFile($event)" name="Url" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <img [src]="url"
                                        *ngIf="(format==='image' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && url"
                                        height="200">
                                    <iframe [src]="urlSafe" *ngIf="fileType === 'pdf' && url" frameborder="0"
                                        height="200"></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="assignmentType == 'Do'">
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label><span>*</span>{{'Description' | translate}}</label>
                                    <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                                        [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config"
                                        required [class.invalid]="Description.invalid && Description.touched">
                                    </angular-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="assignmentType == 'Home work'">
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label><span>*</span>{{'Description' | translate}}</label>
                                    <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                                        [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config"
                                        required [class.invalid]="Description.invalid && Description.touched">
                                    </angular-editor>
                                </div>
                            </div>
                        </div>
                        <div class="row form-row m-0">
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <label>{{'Upload File' | translate}}</label>
                                    <input class="form-control form-control-sm" type='file'
                                        (change)="selectDocumentFile($event)" name="Url" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <img [src]="url"
                                        *ngIf="(format==='image' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && url"
                                        height="200">
                                    <iframe [src]="urlSafe" *ngIf="fileType === 'pdf' && url" frameborder="0"
                                        height="200"></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        *ngIf="assignmentType == 'Watch' || assignmentType == 'Listen' || assignmentType == 'Read' || assignmentType == 'Do'">
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <section class="example-section">
                                    <mat-checkbox class="example-margin" name="IsQuestionir" #IsQuestionir="ngModel"
                                        [(ngModel)]="service.formData.AssignmentInfo.IsQuestionir"
                                        (click)="changeIsQuestionirStatus()"> Do you have any questionnaire?
                                    </mat-checkbox>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
                <div *ngIf="stepNo == 3">
                    <div *ngIf="isProject">
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label><span>*</span>{{'Description' | translate}}</label>
                                    <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                                        [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config"
                                        required [class.invalid]="Description.invalid && Description.touched">
                                    </angular-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isQuestionir && questionInfo.QuestionNo>0">
                        <div class="row form-row m-0">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <div class="form-group mb-0">
                                    <label>{{'QuestionType' | translate}}</label>
                                    <select class="form-control form-control-sm appearance-auto" name="QuestionTypeId"
                                        #QuestionTypeId="ngModel" [(ngModel)]="questionInfo.QuestionTypeId"
                                        (change)="questionTypeChange()" required
                                        [class.invalid]="QuestionTypeId.invalid && QuestionTypeId.touched">
                                        <option [ngValue]="0">Select Type</option>
                                        <option *ngFor="let questiontype of questiontypelist"
                                            [ngValue]="questiontype.Id">{{questiontype.Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 float-right">
                                <div class="form-group mb-0">
                                    <label class="float-right">Question No. {{questionInfo.QuestionNo}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row form-row m-0">
                            <div class="col-12">
                                <mat-card class="example-card min-h-fitscreen1 p-1">
                                    <mat-card-content class="mb-0">
                                        <div class="row form-row m-0 mb-2"
                                            *ngIf="(QuestionType == 'MC') || (QuestionType == 'MR') || (QuestionType == 'FIB')  || (QuestionType == 'Eassy')  || (QuestionType == 'TF') || (QuestionType == 'MF') || (QuestionType == 'MM')">
                                            <div class="col-12">
                                                <div class="form-group mb-0">
                                                    <label><span>*</span>{{'Type Your Question' | translate}}</label>
                                                    <angular-editor class="resizebele-false" name="Question"
                                                        #Question="ngModel" [(ngModel)]="questionInfo.Question"
                                                        [config]="config" required
                                                        [class.invalid]="Question.invalid && Question.touched">
                                                    </angular-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="QuestionType == 'MC'">
                                            <div class="col-12 mt-3">
                                                <mat-radio-group aria-labelledby="example-radio-group-label"
                                                    class="example-radio-group" name="OptionType"
                                                    [(ngModel)]="questionInfo.OptionType">
                                                    <mat-radio-button class="example-radio-button mr-3"
                                                        *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                                        {{optiontype}}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                <div class="row form-row m-0" *ngIf="i==0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <label>{{'Correct' | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-11">
                                                        <div class="form-group mb-0">
                                                            <label>{{'QUESTION OPTION' | translate}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-row m-0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                style="height: 54px;">
                                                                <div class="bg-theam text-center custom-p-1">
                                                                    <label
                                                                        class="text-white">{{QindexLabel[i].text}}</label>
                                                                </div>
                                                                <div class="text-center custom-p-2">
                                                                    <mat-radio-group aria-label="Select an option"
                                                                        class="mat-radio-label-content-false"
                                                                        name="CorrectLabel-{{i}}"
                                                                        #CorrectLabel="ngModel"
                                                                        [(ngModel)]="qAnswer.CorrectLabel"
                                                                        (change)="changeMC(questionInfo.QAnswerList.length, i )">
                                                                        <mat-radio-button [value]="QindexLabel[i].text">
                                                                        </mat-radio-button>
                                                                    </mat-radio-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-10" *ngIf="questionInfo.OptionType == 'Text'">
                                                        <div class="form-group mb-0">
                                                            <textarea
                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                name="Answer{{i}}" #Answer="ngModel"
                                                                [(ngModel)]="qAnswer.Answer" required
                                                                [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-10" *ngIf="questionInfo.OptionType == 'Image'">

                                                        <div class="form-group mb-0">
                                                            <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                                #FileId="ngModel" required
                                                                [class.invalid]="FileId.invalid && FileId.touched"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButtonText="" saveButton="false">
                                                            </mb-large-files-uploader>
                                                        </div>
                                                        <img [src]="qAnswer.FilePath" alt=""
                                                            class="img img-fluid max-width-100px">
                                                    </div>

                                                    <div class="col-1" *ngIf="i>3">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                style="height: 54px;">
                                                                <div
                                                                    class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                    <mat-icon (click)="deleteRow(qAnswer)"
                                                                        class="text-danger cursor-pointer">
                                                                        delete_outline</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <button class="btn btn-primary mt-2 ml-5px" type="submit"
                                                (click)='addNewRow("MC")'>Add New Row</button>
                                        </div>
                                        <div *ngIf="QuestionType == 'Eassy'">
                                            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                <div class="row form-row m-0" *ngIf="i==0">

                                                    <div class="col-12">
                                                        <div class="form-group mb-0">
                                                            <label>{{'Enter Answer' | translate}}</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row form-row m-0">

                                                    <div class="col-12">
                                                        <div class="form-group mb-0">
                                                            <textarea
                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                rows="10" name="Answer" #Answer="ngModel"
                                                                [(ngModel)]="qAnswer.Answer" required
                                                                [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group mb-0">
                                                            <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButtonText="">
                                                            </mb-large-files-uploader>
                                                        </div>
                                                        <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                                            class="img img-fluid max-width-100px">
                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                        <div *ngIf="QuestionType == 'FIB'">
                                            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                <div class="row form-row m-0" *ngIf="i==0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <label>{{'Correct' | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-7">
                                                        <div class="form-group mb-0">
                                                            <label>{{'QUESTION OPTION' | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group mb-0">
                                                            <label>{{'Attachement' | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-1"></div>
                                                </div>
                                                <div class="row form-row m-0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                style="height: 54px;">
                                                                <div
                                                                    class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                    <label class="text-white"><span
                                                                            ngIF="i<9">0</span>{{i+1}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-7">
                                                        <div class="form-group mb-0">
                                                            <textarea
                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                name="qAnswer.Answer{{i}}" #Answer="ngModel"
                                                                [(ngModel)]="qAnswer.Answer" required
                                                                [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group mb-0">
                                                            <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButton="false" saveButtonText="">
                                                            </mb-large-files-uploader>
                                                        </div>
                                                        <img [src]="qAnswer.FilePath" alt=""
                                                            class="img img-fluid max-width-100px">

                                                    </div>
                                                    <div class="col-1" *ngIf="i!=0">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                style="height: 54px;">
                                                                <div
                                                                    class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                    <mat-icon (click)="deleteRow(qAnswer)"
                                                                        class="text-danger cursor-pointer">
                                                                        delete_outline</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <button class="btn btn-primary mt-2 ml-5px" type="submit"
                                                (click)='addNewRow("FIB")'>Add New Row</button>
                                        </div>
                                        <div *ngIf="QuestionType == 'MR'">
                                            <div class="col-12 mt-3">
                                                <mat-radio-group aria-labelledby="example-radio-group-label"
                                                    class="example-radio-group" name="OptionType"
                                                    [(ngModel)]="questionInfo.OptionType">
                                                    <mat-radio-button class="example-radio-button mr-3"
                                                        *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                                        {{optiontype}}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                <div class="row form-row m-0" *ngIf="i==0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <label>{{'Correct' | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <div class="form-group mb-0">
                                                            <label>{{'QUESTION OPTION' | translate}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-row m-0">
                                                    <div class="col-1">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                style="height: 54px;">
                                                                <div class="bg-theam text-center custom-p-1">
                                                                    <label
                                                                        class="text-white">{{QindexLabel[i].text}}</label>
                                                                </div>
                                                                <div class="text-center custom-p-2 custom-mt-1">
                                                                    <mat-checkbox class="example-margin"
                                                                        name="qAnswer.IsCorrect{{i}}"
                                                                        #IsCorrect="ngModel"
                                                                        [(ngModel)]="qAnswer.IsCorrect" value="1">
                                                                    </mat-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-10" *ngIf="questionInfo.OptionType == 'Text'">
                                                        <div class="form-group mb-0">
                                                            <textarea name=""
                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                name="qAnswer.Answer{{i}}" #Answer="ngModel"
                                                                [(ngModel)]="qAnswer.Answer" required
                                                                [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-10" *ngIf="questionInfo.OptionType == 'Image'">
                                                        <div class="form-group mb-0">
                                                            <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButtonText="">
                                                            </mb-large-files-uploader>
                                                        </div>
                                                        <img [src]="qAnswer.FilePath" alt=""
                                                            class="img img-fluid max-width-100px">
                                                    </div>
                                                    <div class="col-1" *ngIf="i>3">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                style="height: 54px;">
                                                                <div
                                                                    class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                    <mat-icon (click)="deleteRow(qAnswer)"
                                                                        class="text-danger cursor-pointer">
                                                                        delete_outline</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <button class="btn btn-primary mt-2 ml-5px" type="submit"
                                                (click)='addNewRow("MR")'>Add New Row</button>
                                        </div>
                                        <div *ngIf="QuestionType == 'TF'">

                                            <div class="row form-row m-0 mt-3">
                                                <div class="col-3">
                                                    <div class="form-group mb-0">
                                                        <div class="appearance-auto mb-3">

                                                            <div class="custom-p-2">
                                                                <mat-radio-group aria-label="Select an option"
                                                                    name="IsCorrect" #IsCorrect="ngModel"
                                                                    [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect"
                                                                    (change)="changeTF()" required
                                                                    [class.invalid]="IsCorrect.invalid && IsCorrect.touched">
                                                                    <mat-radio-button class="mr-3"
                                                                        *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index;"
                                                                        [value]="QindexLabel[i].text">
                                                                        {{qAnswer.Answer}}

                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group mb-0">
                                                        <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader>
                                                    </div>
                                                    <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                                        class="img img-fluid max-width-100px">
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="QuestionType == 'MF'">
                                            <section>

                                                <div class="row form-row m-0">
                                                    <div class="col-12 mt-3">
                                                        <mat-radio-group aria-labelledby="example-radio-group-label"
                                                            class="example-radio-group" name="OptionType"
                                                            [(ngModel)]="questionInfo.OptionType">
                                                            <mat-radio-button class="example-radio-button mr-3"
                                                                *ngFor="let optiontype of optionTypeList"
                                                                [value]="optiontype">
                                                                {{optiontype}}
                                                            </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-12 col-md-8">
                                                        <div class="row mb-3"
                                                            *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                            <div class="col-6">
                                                                <div class="row form-row " *ngIf="i==0">
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="form-group mb-0">
                                                                            <label>{{'S.NO' | translate}}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-10">
                                                                        <div class="form-group mb-0">
                                                                            <label>{{'QUESTION OPTION' |
                                                                                translate}}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row form-row">
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="form-group mb-0">

                                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                                style="height: 54px;">
                                                                                <div
                                                                                    class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                                    <label
                                                                                        class="text-white">{{qAnswer.GroupNo}}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-9 col-md-10">
                                                                        <div class="form-group mb-0">
                                                                            <textarea name=""
                                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                                name="qAnswer.Answer{{i}}"
                                                                                #Answer="ngModel"
                                                                                [(ngModel)]="qAnswer.Answer" required
                                                                                [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-6">
                                                                <div class="row form-row " *ngIf="i==0">
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="form-group mb-0">
                                                                            <label>{{'S.NO' | translate}}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-10">
                                                                        <div class="form-group mb-0">
                                                                            <label>{{'QUESTION OPTION' |
                                                                                translate}}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row form-row">
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="form-group mb-0">
                                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                                style="height: 54px;">
                                                                                <div
                                                                                    class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                                    <label
                                                                                        class="text-white">{{qAnswer.GroupNo1}}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-10"
                                                                        *ngIf="questionInfo.OptionType == 'Text'">
                                                                        <div class="form-group mb-0">
                                                                            <textarea name=""
                                                                                class="form-control form-control-sm appearance-auto resizebele-false"
                                                                                name="qAnswer.Answer1{{i}}"
                                                                                #Answer1="ngModel"
                                                                                [(ngModel)]="qAnswer.Answer1" required
                                                                                [class.invalid]="Answer1.invalid && Answer1.touched"></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-10"
                                                                        *ngIf="questionInfo.OptionType == 'Image'">
                                                                        <div class="form-group mb-0">
                                                                            <mb-large-files-uploader
                                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                                name="qAnswer.FileId"
                                                                                [(ngModel)]="qAnswer.FileId"
                                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                                title="{{'Upload Image' | translate}}"
                                                                                canUpload="true" canSendData="true"
                                                                                saveButtonText="">
                                                                            </mb-large-files-uploader>
                                                                        </div>
                                                                        <img [src]="qAnswer.FilePath" alt=""
                                                                            class="img img-fluid max-width-100px">
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div class="row mb-3"
                                                            *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                            <div class="col-12" *ngIf="i==0">
                                                                <div class="form-group mb-0">
                                                                    <label>{{'CORRECT ANSWER' | translate}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="d-flex mb-2">
                                                                    <div class="form-group mb-0 mr-1"
                                                                        *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                                        <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                            style="height: 54px;">
                                                                            <div
                                                                                class="bg-theam text-center custom-p-1">
                                                                                <label
                                                                                    class="text-white">{{qAnswer1.GroupNo1}}</label>
                                                                            </div>
                                                                            <div
                                                                                class="text-center custom-p-2 custom-mt-1">
                                                                                <mat-radio-group
                                                                                    aria-label="Select an option"
                                                                                    class="mat-radio-label-content-false"
                                                                                    name="{{i}}" #CorrectLabel="ngModel"
                                                                                    [(ngModel)]="qAnswer.CorrectLabel"
                                                                                    required
                                                                                    [class.invalid]="CorrectLabel.invalid && CorrectLabel.touched">
                                                                                    <mat-radio-button
                                                                                        [checked]="qAnswer.CorrectLabel"
                                                                                        value="{{qAnswer1.GroupNo1}}">
                                                                                    </mat-radio-button>
                                                                                </mat-radio-group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-2" *ngIf="i>3">
                                                                <div class="form-group mb-0">
                                                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                        style="height: 54px;">
                                                                        <div
                                                                            class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                            <mat-icon (click)="deleteRow(qAnswer)"
                                                                                class="text-danger cursor-pointer">
                                                                                delete_outline</mat-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                            <button class="btn btn-primary mt-2 ml-5px" type="submit"
                                                (click)='addNewRow("MF")'>Add New Row</button>
                                        </div>
                                        <div *ngIf="QuestionType == 'MM'">
                                            <div class="row form-row m-0">
                                                <div class="col-12">
                                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                                        class="example-radio-group" name="OptionType"
                                                        [(ngModel)]="questionInfo.OptionType">
                                                        <mat-radio-button class="example-radio-button mr-3"
                                                            *ngFor="let optiontype of optionTypeList"
                                                            [value]="optiontype">
                                                            {{optiontype}}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                                                <div class="row form-row m-0" *ngIf="i==0">
                                                    <div class="col-4">
                                                        <div class="row form-row " *ngIf="i==0">
                                                            <div class="col-2">
                                                                <div class="form-group mb-0">
                                                                    <label>{{'S.NO' | translate}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="form-group mb-0">
                                                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row form-row " *ngIf="i==0">
                                                            <div class="col-2">
                                                                <div class="form-group mb-0">
                                                                    <label>{{'S.NO' | translate}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="form-group mb-0">
                                                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group mb-0">
                                                            <label>{{'CORRECT ANSWER' | translate}}</label>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row form-row m-0">

                                                    <div class="col-4">
                                                        <div class="row form-row">
                                                            <div class="col-2">
                                                                <div class="form-group mb-0">

                                                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                        style="height: 54px;">
                                                                        <div
                                                                            class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                            <label
                                                                                class="text-white">{{qAnswer.GroupNo}}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-10">
                                                                <div class="form-group mb-0">
                                                                    <textarea name=""
                                                                        class="form-control form-control-sm appearance-auto resizebele-false"
                                                                        name="qAnswer.Answer{{i}}" #Answer="ngModel"
                                                                        [(ngModel)]="qAnswer.Answer" required
                                                                        [class.invalid]="Answer.invalid && Answer.touched"></textarea>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row form-row">
                                                            <div class="col-2">
                                                                <div class="form-group mb-0">

                                                                    <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                        style="height: 54px;">
                                                                        <div
                                                                            class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                            <label
                                                                                class="text-white">{{qAnswer.GroupNo1}}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-10"
                                                                *ngIf="questionInfo.OptionType == 'Text'">
                                                                <div class="form-group mb-0">
                                                                    <textarea
                                                                        class="form-control form-control-sm appearance-auto resizebele-false"
                                                                        name="qAnswer.Answer{{i}}" #Answer1="ngModel"
                                                                        [(ngModel)]="qAnswer.Answer1" required
                                                                        [class.invalid]="Answer1.invalid && Answer1.touched"></textarea>
                                                                </div>

                                                            </div>
                                                            <div class="col-10"
                                                                *ngIf="questionInfo.OptionType == 'Image'">
                                                                <div class="form-group mb-0">
                                                                    <mb-large-files-uploader
                                                                        (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                        name="qAnswer.FileId"
                                                                        [(ngModel)]="qAnswer.FileId"
                                                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                                                        title="{{'Upload Image' | translate}}"
                                                                        canUpload="true" canSendData="true"
                                                                        saveButtonText="">
                                                                    </mb-large-files-uploader>
                                                                </div>
                                                                <img [src]="qAnswer.FilePath" alt=""
                                                                    class="img img-fluid max-width-100px">
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-3">
                                                        <div class="d-flex">
                                                            <div class="form-group mb-0 mr-1"
                                                                *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                                <div class="form-control form-control-sm appearance-auto rounded-0 p-0"
                                                                    style="height: 54px;">
                                                                    <div class="bg-theam text-center custom-p-1">
                                                                        <label
                                                                            class="text-white">{{qAnswer1.GroupNo1}}</label>
                                                                    </div>
                                                                    <div class="text-center custom-p-2 custom-mt-1">
                                                                        <mat-checkbox class="example-margin"
                                                                            name="{{i}}{{j}}" #CorrectLabel="ngModel"
                                                                            [(ngModel)]="qAnswer.CorrectLabel"
                                                                            value="{{qAnswer1.GroupNo1}}"
                                                                            [checked]="qAnswer.CorrectLabel" required
                                                                            [class.invalid]="CorrectLabel.invalid && CorrectLabel.touched">
                                                                        </mat-checkbox>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-1" *ngIf="i>3">
                                                        <div class="form-group mb-0">
                                                            <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0 "
                                                                style="height: 54px;">
                                                                <div
                                                                    class="text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                                                                    <mat-icon (click)="deleteRow(qAnswer)"
                                                                        class="text-danger cursor-pointer">
                                                                        delete_outline</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <button class="btn btn-primary mt-2 ml-5px" type="submit"
                                                (click)='addNewRow("MM")'>Add New Row</button>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button *ngIf="stepNo > 1" type="button" class="btn btn-primary" (click)="prevQuestion()">Back</button>
                <button *ngIf="stepNo > 1 && questionInfo.QuestionNo <= 0 && (isQuestionir || isProject)" type="button"
                    (click)="proceedNextStep(form)" [disabled]="form.invalid || fileUploading" class="btn btn-primary"
                    type="button">Next</button>
                <button *ngIf="stepNo > 1 && questionInfo.QuestionNo > 0 && (isQuestionir || isProject)" type="button"
                    (click)="addNewAnswer(form)"
                    [disabled]="form.invalid || fileUploading || (questionInfo.QuestionTypeId<=0)"
                    class="btn btn-primary" type="button">Next</button>
                <button *ngIf="stepNo > 1" type="button" (click)="saveAssignment(form)" class="btn btn-success"
                    type="button"
                    [disabled]="form.invalid || fileUploading || (questionInfo.QuestionTypeId<=0)">Save</button>
                <button *ngIf="stepNo == 1" type="button" class="btn btn-success" (click)="startAsignment(form)"
                    type="button"
                    [disabled]="form.invalid || service.formData.AssignmentInfo.TypeId <= 0">Start</button>
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>