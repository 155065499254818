
<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >Add Analytics Questions</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0 p-2">
                    <div class="row border-bottom m-0 mb-2 justify-content-between p-0" >
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Question</label>
                                <input class="form-control form-control-sm" type="text" placeholder="Question" 
                                name="Question" #Question="ngModel" 
                                [(ngModel)]="questionObj.Question"  
                                required [class.invalid]="Question.invalid && Question.touched">
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Category</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="CategoryId" 
                                [(ngModel)]="questionObj.CategoryId"  required>
                                    <option [ngValue]="0">Select Category</option>
                                    <option *ngFor="let class of categoryList"  [ngValue]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>
        
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Score</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="Score" 
                                [(ngModel)]="questionObj.Score"  required>
                                    <option [ngValue]="0">Select Score</option>
                                    <option *ngFor="let score of scoreList"  [ngValue]="score.Id">
                                    {{score.Name}}
                                    </option>
                                </select>
        
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="saveAnalysisQuestionair()" class="btn btn-success float-right" type="button" >Save</button>
                    <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
