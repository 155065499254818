import { Component, OnInit } from '@angular/core';
import { faHome,faFolder,faCalendarDays,faDollarSign,faEnvelope,faPencilSquare,faHouse, faDashboard } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-student-nav',
  templateUrl: './student-nav.component.html',
  styleUrls: ['./student-nav.component.scss']
})
export class StudentNavComponent implements OnInit {
  dashboardIcon = faDashboard;
  folderIcon = faFolder;
  calendarIcon = faCalendarDays;
  financeIcon = faDollarSign;
  messageIcon = faEnvelope;
  editIcon = faPencilSquare;
  
  constructor() { }

  ngOnInit(): void {
  }
 
}