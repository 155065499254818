import { Component, OnInit, Output , EventEmitter} from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UsereditComponent } from '../feature/user/component/useredit.component';
//import EventEmitter from 'node:events';
@Component({
  selector: 'app-custommenu',
  templateUrl: './custommenu.component.html',
  styleUrls: ['./custommenu.component.scss']
})
export class CustommenuComponent implements OnInit {
  @Output() Editclick = new EventEmitter<string>();
  params:any;
  bsModalRef: BsModalRef;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }
  MenuList : any;
  //SaveClick : any;

  ngOnInit(): void {
  }
  agInit(params: any) {
    this.params=params.value;
    this.MenuList = params.MenuList;
    //this.SaveClick = params.SaveClick;
   }

  refresh(params: any) {
    return true;
  }
  openusermodal(userid) {
    const initialState = {
      list: [
        {id:userid}
      ]
      
    };
    this.bsModalRef = this.modalService.show(UsereditComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
  }
  
  edit(){
    //this.openusermodal(this.params);
    this.Editclick.emit(this.params);
  }
}
