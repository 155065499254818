export class Chat {
}
export class MessageMasterData
{
    MessageMasterId : number;
    Name :string;
    Description :string;
    UserIdList: Array<any>;    
    IsActive:boolean;
    CreatedOn : string ;
    CreatedBy:string;
    UserName:string;
}
export class MessageData
{
    MessageId:number;
    MessageMasterId:number;
    Message :string;
    IsRead : boolean;
    IsActive :boolean;
    CreatedOn :string;
    CreatedBy :number;
    CreatedByName :string;
} 
export class MessageViewData
{
    MessageMasterInfo :MessageMasterData;
    MessageInfo : Array<MessageData>;
    ClassMappingList:Array<any>;
    IsSaved :boolean;
    ErrorMessage:string;
}