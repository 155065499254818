import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import {  QuestionMaster, QuestionViewData } from './question.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http:HttpClient) {

    //this.formData.QuestionMasterInfo = new QuestionMaster();
    //this.formData.QuestionInfo = new QuestionMaster();
    //this.formData.QuestionMasterInfo = new QuestionMaster();
  }
  readonly baseUrl = CONFIG.BASE_URL+'api/Question';
  //readonly baseUrl = 'http://172.24.235.14//SAPI/api/Home';
  // readonly baseUrl = 'http://172.24.235.14//SAPI/api/User';
  //readonly baseUrl = 'https://api.hybridlearning.in/api/User';
  formData:QuestionViewData = new QuestionViewData();

  addquestioninfo(formData:QuestionViewData){
   this.formData.QuestionMasterInfo.IsActive = true;
    return this.http.post(this.baseUrl+'/addquestioninfo',formData);
  }
  savequestioninfo(formData:QuestionViewData){
    if(formData.QuestionMasterInfo.QuestionMasterId > 0){
      return this.udpatequestioninfo(formData);
    }
    else {
      return this.addquestioninfo(formData);
    }
  }
  udpatequestioninfo(formData:QuestionViewData){
    return this.http.post(this.baseUrl+'/udpatequestioninfo',formData);
  }
  getquestionlist(date:string){
    var config = {
      params: {
        selectedDate: date,
      }
    };
    return this.http.get(this.baseUrl+'/getquestionlist',config);
  }
  getallquestionlist(){
    return this.http.get(this.baseUrl+'/getallquestionlist');
  }
  getallquestionlistByParams(config){
    return this.http.get(this.baseUrl+'/getallquestionlist',config);
  }
  getstudentsquestions(date:string){
    var config = {
      params: {
        selectedDate: date,
      }
    };
    return this.http.get(this.baseUrl+'/getstudentsquestions',config);
  }
  getallstudentsquestions(){
    return this.http.get(this.baseUrl+'/getallstudentsquestions');
  }
  getquestioninfo(questionMasterId:number){
     var config = {
      params: {
        questionId: questionMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getquestioninfo',config);
  }
  addStudentAnswers(){
   // this.formData.QuestionMasterInfo.IsActive = true;
     return this.http.post(this.baseUrl+'/savestudentanswers',this.formData);
   }
  //  getclasses(){
  //   return this.http.get(this.baseUrl+'/getclasses');
  // }
  // getassignments(){
  //   return this.http.get(this.baseUrl+'/getassignments');
  // }
  assignToClass(assignedList){
    return this.http.post(this.baseUrl+'/associateQuestionTClasses',assignedList);
  }
  getquestionoverviewinfo(questionMasterId:number){
    var config = {
     params: {
      questionMasterId: questionMasterId,
     }
   };
   return this.http.get(this.baseUrl+'/getquestionoverviewinfo',config);
  }
  getstdquestionfullinfo(stdquestionMasterId:number){
    var config = {
     params: {
      stdQuestionMaterId: stdquestionMasterId,
     }
   };
   return this.http.get(this.baseUrl+'/getstdquestioninfo',config);
  }
  getStudentAnswerInfo(questionMasterId:number){
    var config = {
      params: {
        questionMasterId: questionMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getstudentanswerinfo',config);
  }
  getquestionoverview(questionMasterId:number){
    var config ={
      params:{
        questionMasterId:questionMasterId
      }
    }
    return this.http.get(this.baseUrl+'/getquestionoverview',config);
  }
  deleteQuestion(questionMasterId){
  var questionMasterinfo = new QuestionMaster();
  questionMasterinfo.IsActive = false;
  questionMasterinfo.QuestionMasterId = questionMasterId;
  return this.http.post(this.baseUrl+'/deletequestioninfo',questionMasterinfo);
  }
  getallstudentsquestionsbyId(userid ){
    var config ={
      params:{
        userId:userid
      }
    }
    return this.http.get(this.baseUrl+'/getallstudentsquestionsbyId',config);
  }
  getStudentAnswerInfoForParent(params)
  {
    return this.http.get(this.baseUrl+'/getstudentanswerinfoforParent',params);

  }
}
