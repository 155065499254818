<section *ngIf="IsEnableCheckbox">
    <mat-checkbox class="example-margin" [checked]="CheckboxChecked" *ngFor="let control of controlList" (click)="control.Click(params, control.that)"></mat-checkbox>
</section>

<section *ngIf="IsEnableStartTime">
    <div class="form-group mb-0">
        <ng-template #popTemplate>
            <timepicker [(ngModel)]="StartTime" name="startTime" [showMeridian]="true"></timepicker>
        </ng-template>
        <input [ngModel]="StartTime| date:'shortTime'" class="form-control form-control-sm" name="startTime" [popover]="popTemplate" [outsideClick]="true" />
    </div>
</section>
