import { Component, OnInit } from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  list:any[];
  helpTabName:string;
  CUSTOMCONST:any;
  constructor(public bsModalRef: BsModalRef) {
    
   }

  ngOnInit(): void {
    this.helpTabName = this.list[0].name;
  }

}
