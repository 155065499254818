<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'UserEdit' | translate}}</h3>
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId <= 0">Add User</h3>
      </div>
      <div class="modal-body p-2">
        <form novalidate #form="ngForm" (submit)="onSave(form)" autocomplete="off">
          <div class="row form-row m-0">
            <div class="form-group col-8 p-0 pr-1 mb-0">
              <div class="row form-row m-0 border border-secondary h-100  pt-2">
               
                <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                  <label>{{'UserId' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}" name="UserLogin" #UserLogin="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.UserLogin">
                </div>
                <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                  <label>{{'Password' | translate}}</label>
                  <input class="form-control form-control-sm" type="password" placeholder="********" name="Password" #Password="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Password" required [class.invalid]="Password.invalid && Password.touched">
                </div>
                
                <div class="form-group col-6 mb-0">
                  <label>{{'School' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="SchoolId" #SchoolId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.SchoolId" (change)="schoolChange()">
                    <option [ngValue]="0">Select School</option>
                    <option *ngFor="let school of schoollist" [ngValue]="school.Id">{{school.Name}}</option>
                  </select>
                </div>
           
                <div class="form-group col-6 mb-0">
                  <label>{{'Branch' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="BranchId" #BranchId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.BranchId" (change)="branchChange()">
                    <option [ngValue]="0">Select Branch</option>
                    <option *ngFor="let branch of branchlist" [ngValue]="branch.Id">{{branch.Name}}</option>
                  </select>
                </div>
              

                <div class="form-group col-6 mb-0">
                  <label>{{'EnrollmentId' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'EnrollmentId' | translate}}" name="EnrollmentId" #EnrollmentId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.EnrollmentId" required minlength="8" [class.invalid]="EnrollmentId.invalid && EnrollmentId.touched">
                </div>
                <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                  <label>{{'UserId' | translate}} - {{service.formData.UserInfo.UserId}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}" name="UserLogin" #UserLogin="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.UserLogin" [readonly]="service.formData.UserInfo.UserId > 0">
                </div>
                <div class="form-group col-6 mb-0">
                  <label>Academic year</label>
                  <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.AcademicYearId">
                    <option [ngValue]="0">Select</option>
                    <option *ngFor="let year of yearList.slice().reverse()" [ngValue]="year.Id">{{year.Name}}</option>
                  </select>
                </div>
                
                <div class="form-group col-4 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                  <label class="d-block">User Share Id - {{service.formData.UserInfo.UserShareId}}</label>
                  <mat-checkbox class="example-margin h-30px lh-30px"  name="IsUserShare" #IsUserShare="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.IsUserShare" >Is User Share</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="form-group col-4 mb-0 p-0 pl-1">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 position-relative">
                <div class="cover-image">
                  <img src="{{service.formData.UserInfo.ProfileFileInfo.CroppedContentString}}" alt="" class="w-100 img-fluid" *ngIf="service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                  <img [src]="service.formData.UserInfo.CroppedProfileFilepath" alt="" class="w-100 img-fluid" *ngIf="!service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                </div>
                <div class="form-group col-12 text-center">
                  
                  <a class="addImageIcon cursor-pointer" (click)="editCoverImage()">
                    <mat-icon>add_a_photo</mat-icon>
                  </a>
                  <img src="{{service.formData.UserInfo.FileInfo.CroppedContentString}}" alt="" height="110" width="110" class="img-circle border border-w-5 shadow-08" (click)="editPic()" *ngIf="service.formData.UserInfo.FileInfo.CroppedContentString">
                  <img [src]="service.formData.UserInfo.CroppedFilepath" alt="" height="110" width="110" class="border border-w-5 img-circle shadow-08" (click)="editPic()" *ngIf="!service.formData.UserInfo.FileInfo.CroppedContentString">
              </div>
            </div>
            </div>
          </div>
          <div class="row form-row m-0 mb-2">
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                <div class="form-group col-4 mb-0">
                  <label>{{'FirstName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.FirstName" required [class.invalid]="FirstName.invalid && FirstName.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'MidName' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}" name="MiddleName" #MiddleName="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.MiddleName" required [class.invalid]="MiddleName.invalid && MiddleName.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Surname' | translate}}</label>
                  <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Surname" required [class.invalid]="Surname.invalid && Surname.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Gender' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel" [(ngModel)]="service.formData.UserInfo.GenderId">
                    <option  [ngValue]="0">Select Gender</option>
                    <option *ngFor="let gender of genderlist" [ngValue]="gender.Id">{{gender.Name}}</option>
                  </select>
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'DateOfBirth' | translate}}</label>
                  <input type="text" class="form-control form-control-sm" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig" bsDatepicker>
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Role' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="RoleId" #RoleId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.RoleId" (change)="onRoleChange()">
                    <option [ngValue]="0">Select Role</option>
                    <option *ngFor="let role of rolelist" [ngValue]="role.Id">{{role.Name}}</option>
                  </select>
                </div>

                <div class="form-group col-4 mb-0">
                  <label>{{'Mobile' | translate}}</label>
                  <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8" [class.invalid]="Mobile.invalid && Mobile.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'SMobile' | translate}}</label>
                  <input class="form-control form-control-sm appearance-none" type="number" placeholder="{{'SMobile' | translate}}" name="SMobile" #SMobile="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.SMobile" >
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Email' | translate}}</label>
                  <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Email" required [class.invalid]="Email.invalid && Email.touched">
                </div>
                <div class="form-group col-8 mb-0">
                  <label>{{'Address' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Address' | translate}}" name="Address" #Address="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Address" required  [class.invalid]="Address.invalid && Address.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'Street' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Street' | translate}}" name="Street" #Street="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Street" >
                </div>
    
                <div class="form-group col-4 mb-0">
                  <label>{{'PostalNo' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'PostalNo' | translate}}" name="PostalNo" #PostalNo="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.PostalNo" required [class.invalid]="PostalNo.invalid && PostalNo.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'City' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'City' | translate}}" name="City" #City="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.City" required  [class.invalid]="City.invalid && City.touched">
                </div>
                <div class="form-group col-4 mb-0">
                  <label>{{'State' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'State' | translate}}" name="State" #State="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.State" required [class.invalid]="State.invalid && State.touched">
                </div>
                <div class="form-group col-4 mb-0" *ngIf="role != 'Student'">
                  <label>{{'Profession' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Profession' | translate}}" name="Profession" #Profession="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Profession">
                </div>
                <div class="form-group col-8 mb-0" *ngIf="role != 'Student'">
                  <label>{{'Url' | translate}}</label>
                  <input class="form-control form-control-sm" type="text" placeholder="{{'Url' | translate}}" name="Url" #Url="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Url" >
                </div>
                <div class="form-group col-12 mb-0">
                  <label>Comments</label>
                  <textarea class="form-control form-control-sm appearance-auto resizebele-false" type="text" placeholder="Comments" name="Comments" #Comments="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.Comments" ></textarea>
                </div>
                <!-- <div class="form-group col-8 mb-0">
                  <label>User Share Id</label>
                  <label class="form-control form-control-sm readonly">{{service.formData.UserInfo.UserShareId}}</label>
                </div> 
                <div class="form-group col-4 mb-0">
                  <label class="d-block">User Share Id - {{service.formData.UserInfo.UserShareId}}</label>
                  <mat-checkbox class="example-margin h-30px lh-30px"  name="IsUserShare" #IsUserShare="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.IsUserShare" >Is User Share</mat-checkbox>
                </div> -->
              </div>
              
              
            </div>
          </div>
          <!-- *ngIf="role == 'Student'" -->
          <div class="row form-row m-0 mb-2" *ngIf="role == 'Student'">  
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'HumanityLife' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.IsHumanityLife" >{{'HumanityLife' | translate}}</mat-checkbox>
                  
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Skills' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.IsSkills" >{{'Skills' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Academics' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.IsAcademics" >{{'Academics' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <mat-checkbox class="example-margin"  name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.IsLearnOutOfBox" >{{'LearnOutOfBox' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'Class' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="ClassId" #ClassId="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.ClassId" (change)="classChange()">
                    <option [ngValue]="0">Select Class</option>
                    <option *ngFor="let class of classList" [ngValue]="class.Id">{{class.Name}}</option>
                    <option [ngValue]="-1">Add Class</option>
                  </select>
                </div>

                <div class="form-group col-3 mb-0">
                  <label>{{'Batch' | translate}}</label>
                  <select class="form-control form-control-sm appearance-auto" name="BatchId" #BatchId="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.BatchId"(click)="branchChange()" >
                    <option [ngValue]="0">Select Batch</option>
                    <option *ngFor="let batch of batchList" [ngValue]="batch.Id">{{batch.Name}}</option>
                    <option [ngValue]="-1" *ngIf="service.formData.StudentInfo.ClassId>0">Add Batch</option>
                  </select>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'StartDate' | translate}}</label>
                  
                  <input type="text" class="form-control form-control-sm" placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.StartDate" [bsConfig]="datepickerConfig" bsDatepicker>
                 
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'ExpiryDate' | translate}}</label>
                  
                  <input type="text" class="form-control form-control-sm" placeholder="{{'ExpiryDate' | translate}}" name="ExpiryDate" #ExpiryDate="ngModel" 
                  [(ngModel)]="service.formData.StudentInfo.ExpiryDate" [bsConfig]="datepickerConfig" bsDatepicker>
                 
                </div>
                <div class="form-group col-3 mb-0">
                  <mat-checkbox class="example-margin"  name="IsEditAllowed" #IsEditAllowed="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.IsEditAllowed" >{{'AllowedEdit' | translate}}</mat-checkbox>
                </div>
              </div>
              
            </div>
          </div>
          <div class="row form-row m-0 mb-2" *ngIf="role == 'Teacher'">  
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'HumanityLife' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel" 
                  [(ngModel)]="service.formData.TeacherInfo.IsHumanityLife" >{{'HumanityLife' | translate}}</mat-checkbox>
                  
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Skills' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel" 
                  [(ngModel)]="service.formData.TeacherInfo.IsSkills" >{{'Skills' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Academics' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel" 
                  [(ngModel)]="service.formData.TeacherInfo.IsAcademics" >{{'Academics' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'LearnOutOfBox' | translate}}</label> -->
                  <mat-checkbox class="example-margin"  name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel" 
                  [(ngModel)]="service.formData.TeacherInfo.IsLearnOutOfBox" >{{'LearnOutOfBox' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'Class' | translate}}</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Search'"
                    [settings]="dropdownSettings"
                    name="StdClass"
                    [data]="classList"
                    [(ngModel)]="service.formData.ClassMappingList"
                    (onSelect)="onItemSelect($event,'Class')"
                    (onSelectAll)="onSelectAll($event,'Class')"
                    (onDeSelect)="onItemSelect($event,'Class')"
                    (onSelectAll)="onSelectAll($event,'Class')">
                      </ng-multiselect-dropdown>
         
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'Batch' | translate}}</label>
                  <ng-multiselect-dropdown
                  name="StdBatch"
                  [placeholder]="'Search'"
                  [settings]="dropdownSettings"
                  [data]="batchList"
                  [(ngModel)]="service.formData.BatchMappingList"
                  (onSelect)="onItemSelect($event,'branch')"
                  (onSelectAll)="onSelectAll($event,'branch')"
                  (onDeSelect)="onItemSelect($event,'branch')"
                  (onSelectAll)="onSelectAll($event,'branch')">
                    </ng-multiselect-dropdown>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'ExpiryDate' | translate}}</label>
                  
                  <input type="text" class="form-control form-control-sm" placeholder="{{'ExpiryDate' | translate}}" name="ExpiryDate" #ExpiryDate="ngModel" 
                  [(ngModel)]="service.formData.TeacherInfo.ExpiryDate" [bsConfig]="datepickerConfig" bsDatepicker>
                 
                </div>
                <div class="form-group col-3 mb-0">
                  <label class="d-block">&nbsp;</label>
                  <mat-checkbox class="example-margin h-30px lh-30px"  name="IsEditAllowed" #IsEditAllowed="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.IsEditAllowed" >{{'AllowedEdit' | translate}}</mat-checkbox>
                </div>
              </div>
              
            </div>
          </div>
          <div class="row form-row m-0 mb-2" *ngIf=" role == 'Parent'">  
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'HumanityLife' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel" 
                  [(ngModel)]="service.formData.ParentInfo.IsHumanityLife" >{{'HumanityLife' | translate}}</mat-checkbox>
                  
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Skills' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel" 
                  [(ngModel)]="service.formData.ParentInfo.IsSkills" >{{'Skills' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'Academics' | translate}}</label> -->
                  <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel" 
                  [(ngModel)]="service.formData.ParentInfo.IsAcademics" >{{'Academics' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <!-- <label>{{'LearnOutOfBox' | translate}}</label> -->
                  <mat-checkbox class="example-margin"  name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel" 
                  [(ngModel)]="service.formData.ParentInfo.IsLearnOutOfBox" >{{'LearnOutOfBox' | translate}}</mat-checkbox>
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'Children' | translate}}</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Search'"
                    [settings]="dropdownSettings"
                    name="ChildrenList"
                    [data]="childList"
                    [(ngModel)]="service.formData.ParentInfo.ChildrenList"
                    (onSelect)="onItemSelect($event,'Child')"
                    (onDeSelect)="onItemSelect($event,'Child')">
                      </ng-multiselect-dropdown>
         
                </div>
                <div class="form-group col-3 mb-0">
                  <label>{{'ExpiryDate' | translate}}</label>
                  
                  <input type="text" class="form-control form-control-sm" placeholder="{{'ExpiryDate' | translate}}" name="ExpiryDate" #ExpiryDate="ngModel" 
                  [(ngModel)]="service.formData.ParentInfo.ExpiryDate" [bsConfig]="datepickerConfig" bsDatepicker>
                 
                </div>
                <div class="form-group col-3 mb-0">
                  <label class="d-block">&nbsp;</label>
                  <mat-checkbox class="example-margin h-30px lh-30px"  name="IsEditAllowed" #IsEditAllowed="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.IsEditAllowed" >{{'AllowedEdit' | translate}}</mat-checkbox>
                </div>
              </div>
              
            </div>
          </div>
          <div class="row form-row m-0 mb-2" *ngIf="role == 'Student' || role == 'Teacher'|| role == 'Parent'">  
            <div class="form-group col-12 p-0 mb-0">
              <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                <div class="form-group col-12 mb-0">
                  <label class="d-block">{{'About' | translate}} {{role}} <span class="d-inline-block float-right">{{currentLength}}/{{maxLength}}</span></label>
                  <textarea class="form-control form-control-sm appearance-auto resizebele-false m-0" name="About" #About="ngModel"  [(ngModel)]="service.formData.UserInfo.About" rows="5" maxlength="2000"  (keyup)="lenghtCounter()"></textarea>
                </div>
              </div>
            </div>
          </div>
                         
      </form>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-primary" (click)="studentOffer(service.formData.UserInfo.UserId)" *ngIf="(this.role === 'Student' && service.formData.UserInfo.UserId > 0)">Student Offer</button>
        <button type="button" class="btn btn-primary" (click)="userLogHistory()" *ngIf="service.formData.UserInfo.UserId > 0">User Log</button>
        <button type="button" class="btn btn-primary" (click)="userAcademicYearLogInfo()" *ngIf="service.formData.UserInfo.UserId > 0">Academic Year Log</button>
        <button type="button" class="btn btn-primary" (click)="resetPwd()" *ngIf="service.formData.UserInfo.UserId > 0">Reset Password</button>
        <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>
        <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)" aria-label="Close">Close</button>
      </div>
    </div>
  </div>
