

<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/dashboard/" routerLinkActive="active">{{'Dashboard' | translate}}</a>
  </li>
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Functions
    </a>
    <div class="dropdown-menu" aria-labelledby="functionsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/question/" routerLinkActive="active">Question</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/analysis/" routerLinkActive="active">Analysis</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/survey/" routerLinkActive="active">Survey</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/assignment/" routerLinkActive="active">Assignment</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/event/" routerLinkActive="active">Events</a>
   </div>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/user-list/" routerLinkActive="active">{{'Students' | translate}}</a>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/user/" routerLinkActive="active">{{'User' | translate}}</a>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/chat/" routerLinkActive="active">{{'Chat' | translate}}</a>
  </li>
<li class="nav-item" >
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/attendence/" routerLinkActive="active">Attendance</a>
  </li>
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="paymentsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
      Payments
    </a>
    <div class="dropdown-menu" aria-labelledby="paymentsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/terms/" routerLinkActive="active">Terms</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/payments/" routerLinkActive="active">Payments</a>
      
   </div>
  </li><li class="nav-item dropdown" >
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="filesDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Files
    </a>
    <div class="dropdown-menu" aria-labelledby="filesDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/unusedfiles/" routerLinkActive="active">Unused files</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/file-management/" routerLinkActive="active">Filemanagement</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/own-documents/" routerLinkActive="active">Own Documents</a>
    </div>
  </li>