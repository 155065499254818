import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageViewData } from './chat.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http:HttpClient) { }
  readonly baseUrl = CONFIG.BASE_URL+'api/Message';
  formData:MessageViewData = new MessageViewData();
  
  getMessages(){
    return this.http.get(this.baseUrl+'/getmessages');
  }
  getMessageInfo(MessageMasterId:number){
    var config = {
      params: {
        messageMasterId: MessageMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getmessageinfo',config);
  }
  saveMessageMasterInfo(){
      return this.http.post(this.baseUrl+'/savemessagemasterinfo',this.formData.MessageMasterInfo);
  }
  getMessageLineInfo(messageId:number){
    var config = {
      params: {
        messageId: messageId,
      }
    };
    return this.http.get(this.baseUrl+'/getmessagelineinfo',config);
  }
  saveMessageInfo(msgInfo){
    return this.http.post(this.baseUrl+'/savemessageinfo',msgInfo);
  }
  
}
