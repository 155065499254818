<div class="m-auto text-center position-relative">
    <i class="fa fa-times" aria-hidden="true" (click)="closeModal()"></i>
    <youtube-player 
    videoId="{{vid}}"
    suggestedQuality="highres" 
    [height]="winHeight" 
    [width]="winWidth" 
    [startSeconds]="7"
    [endSeconds]="80"
    (ready)="savePlayer($event)"
    (change)="onStateChange($event)"
    >
  </youtube-player>

  <!-- <youtube-player
          [videoId]="vid"
          (ready)="savePlayer($event)"
          (change)="onStateChange($event)"
          [playerVars]="playerVars"
          [height]="winHeight" 
    [width]="winWidth">
        </youtube-player> -->

</div>
