import { Component, OnInit } from '@angular/core';
import { MessageData, MessageMasterData } from 'src/app/shared/chat.model';
import { ChatService } from 'src/app/shared/chat.service';
import { CommonService } from 'src/app/shared/common.service';
import { SignalrService } from 'src/app/shared/signalr.service';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnInit {
  list:any[]=[];
  isAdmin:boolean=false;
  joinedlist:Array<any>;
messageInfo:MessageData;
messageList:Array<MessageData>;
masterMessageList:Array<MessageMasterData>;
isChatExpend:boolean = false;
// ChatExpend:any;
  constructor(public service:ChatService,public commonServ:CommonService,public signalRServ:SignalrService) {
    //this.commonServ.isChatting.next(false);
    this.messageInfo = new MessageData();
    // if(this.commonServ.messageMasterInfo.MessageMasterId && !this.commonServ.messageMasterInfo.messageMasterId){
    //   this.commonServ.messageMasterInfo.messageMasterId = this.commonServ.messageMasterInfo.MessageMasterId
    // }
    if(this.commonServ.messageMasterInfo){
       this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo ? this.commonServ.messageMasterInfo.MessageMasterId : "";
       this.messageList = this.commonServ.MessageList;     
       if(this.messageInfo)
       this.signalRServ.joinGroup(this.messageInfo.MessageMasterId.toString());
    }
    // this.signalRServ.chatStarted.subscribe(
    //   item => {
    //    // this.commonServ.isChatting.next(true);
    //     if(this.commonServ.messageMasterInfo.MessageMasterId != item.MessageMasterId){
    //       this.commonServ.messageMasterInfo = item;
    //    }
       
    //   }
    // );
    this.signalRServ.messageReceived.subscribe(
      item => {
       this.messageList.push(item);
      }
    );
    this.signalRServ.chatClosed.subscribe(
      item => {
       this.messageList = [];
       this.signalRServ.UnSubscribe(this.commonServ.messageMasterInfo.MessageMasterId.toString());
       this.commonServ.isChatting.next(false);
      }
    );
    
    this.commonServ.sendMsg$.subscribe(
      (message) => {
        this.messageList = message;
      }
      );
      this.commonServ.loadMsg$.subscribe(
        (messageMasterInfo) => {
          this.service.getMessageInfo(messageMasterInfo.MessageMasterId).subscribe((res: any) => {
            this.messageList = res.MessageInfo;
          });      
        }
        );
    

   
    }
  ngOnInit(): void {
    // this.isChatExpend = false;
    // this.ChatExpend = "nvdshbvf";
    this.joinedlist = [];
    if(localStorage.getItem('UserRole') == "Admin" || localStorage.getItem('UserRole') == "SuperAdmin" || localStorage.getItem('UserRole') == "Teacher")
      this.isAdmin = true;
    this.service.getMessages().subscribe((res: any) => {
      console.log("chats" + res);
      this.masterMessageList = res;
     /* this.masterMessageList.forEach( function(val) {
        this.handleChatClick(val);
      }) */
  });
  }
  closeChat(){
   this.signalRServ.closeChat(this.commonServ.messageMasterInfo);
  }
  handleChatClick(masterMessage){
    this.commonServ.messageMasterInfo = masterMessage;
    if(this.commonServ.messageMasterInfo)
      this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo ? this.commonServ.messageMasterInfo.MessageMasterId : "";
    this.service.getMessageInfo(masterMessage.MessageMasterId).subscribe((res: any) => {
      if(this.messageInfo && !this.joinedlist.includes(this.messageInfo.MessageMasterId.toString())){
        this.joinedlist.push(this.messageInfo.MessageMasterId.toString());
        this.signalRServ.joinGroup(this.messageInfo.MessageMasterId.toString());
      }
      this.commonServ.SendMessageList(res.MessageInfo);
      this.signalRServ.startChat(res.MessageMasterInfo);
    });
  }
  sendMessageAction(action){
    console.log("action" + action);
    this.messageInfo.Message = '%' + action + '%';
    this.sendMessage();
  }

  sendMessage(){
    //this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo.MessageMasterId;
    this.service.saveMessageInfo(this.messageInfo).subscribe((res:MessageData)=>{
if(res.MessageId > 0){
  this.signalRServ.sendMessage(res);
  this.messageInfo = new MessageData();
  this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo.MessageMasterId;
}
else{
  
}
    })
  }
  textAreaAdjust(element) {
    const app = document.getElementsByClassName("js-auto-expand");

    // element.style.height = "1px";
    // element.style.height = (25+element.scrollHeight)+"px";
  }
  collapseToggle(){
    if(this.isChatExpend){
      this.isChatExpend = false;
    }
    else{
      this.isChatExpend = true;
    }
  }
}
