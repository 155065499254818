import {Component, ViewChild, ElementRef, EventEmitter, Output} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditassignmentComponent } from '../feature/assignment/component/editassignment.component';
import { EditchatComponent } from '../feature/chat/component/editchat.component';
import { EditeventComponent } from '../feature/event/component/editevent.component';
import { EditFeedbackComponent } from '../feature/feedback/component/edit-feedback.component';
import { EditOwnDocsComponent } from '../feature/files/component/edit-own-docs.component';
import { EditPaymentsComponent } from '../feature/payment/component/edit-payments.component';
import { SchooleditComponent } from '../feature/school/component/schooledit.component';
import { EdittermComponent } from '../feature/terms/component/editterm.component';
import { UsereditComponent } from '../feature/user/component/useredit.component';
import { CommonService } from '../shared/common.service';
import { AnalysisQuestionairComponent } from '../shared/component/analysis/analysis-questionair.component';
import { AnalysisComponent } from '../shared/component/analysis/analysis.component';
import { QuestioneditComponent } from '../shared/component/question/questionedit.component';
import { EditsurveyComponent } from '../shared/component/survey/editsurvey.component';
import { CUSTOMCONST } from '../shared/Const';

//import {ILoadingOverlayComponentAngularComp} from "ag-grid-angular";

@Component({
    selector: 'app-loading-overlay',
    template: `
        <div>
            <div *ngIf="params.enableMenu" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked()"><i class="fa {{params.menuIcon}}"></i></div> 
            <div *ngIf="params.enableButton" class="customHeaderMenuButton" (click)="onMenuClicked()"><button value={{params.button}}>{{params.button}}</button></div> 
            <div *ngIf="params.enableText" class="customHeaderLabel">{{params.displayName}}</div> 
            <section *ngIf="params.checkboxToggle" class="costomHeaderCheckbox" (click)="params.Click(0, params.that)"><mat-checkbox class="example-margin" ></mat-checkbox></section>
            
            <div *ngIf="params.enableSorting" (click)="onSortRequested('asc', $event)"  class="customSortDownLabel"><i class="fa fa-long-arrow-down"></i></div> 
            <div *ngIf="params.enableSorting" (click)="onSortRequested('desc', $event)" class="customSortUpLabel"><i class="fa fa-long-arrow-up"></i></div> 
            <div *ngIf="params.enableSorting" (click)="onSortRequested('', $event)"  class="customSortRemoveLabel"><i class="fa fa-times"></i></div>
        </div>
    `,
    // I removed below line from the tamplet if it is requred please add condition for that :- Anjan
    // <div class="action-holders__sort-number action-holders__sorting"><p id="sortingOrder{{colId}}"></p></div>
    styles: [
        `
        .customHeaderMenuButton,.customHeaderMenuIcon {
            margin-top: 7px;
            margin-left: 4px;
            float: left;
        }
        .costomHeaderCheckbox {
          margin-top: 7px;
      }
        .action-holders__sort-number.action-holders__sorting {
            position: relative;
            float: left;
            width: 7px;
            height: 32px;
        }
    
        .customHeaderLabel {
            margin-left: 5px;
            margin-top: 3px;
        }
    
        .customSortDownLabel {
            float: left;
            margin-left: 10px;
            margin-top: 5px;
        }
    
        .customSortUpLabel {
            float: left;
            margin-left: 3px;
            margin-top: 4px;
        }
    
        .customSortRemoveLabel {
            float: left;
            font-size: 11px;
            margin-left: 3px;
            margin-top: 6px;
        }
    
        .active {
            color: cornflowerblue;
        }
    `
    ]
})
export class CustomHeader {
  @Output() Editclick = new EventEmitter<string>();
    public params: any;

    public ascSort: string;
    
    public colId;
    public sortNumber = 0;


    public descSort: string;
    public noSort: string;

    public bsModalRef:BsModalRef;
    CUSTOMCONST:any=CUSTOMCONST; 
    
    @ViewChild('menuButton', {read: ElementRef}) public menuButton;
    constructor(private modalService: BsModalService,public commonServ: CommonService) { }
    agInit(params): void {
        this.params = params;
        params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
        this.colId = this.params.column.getColId().replace(/\s/g, '');

    }

    onMenuClicked() {
      if(this.params.displayName == 'SchoolId'){
        this.openschoolmodal();
      }else if(this.params.displayName == 'QuestionMasterId'){
        this.openquestionmodal();
      }else if(this.params.displayName == 'AssignmentId'){
        this.openAssignmentModal();
      }else if(this.params.displayName == 'EventId'){
        this.openEventModal();
      }else if(this.params.displayName == 'FeedbackId'){
        this.openFeedbackModal();
      }else if(this.params.displayName === "SurveyId"){
        this.openSurveyModal();
      }else if(this.params.displayName == 'MessageMasterId'){
        this.openChatModal();
      }else if(this.params.displayName == 'AnalysisMasterId'){
        this.openAnalysisModal();
      }else if(this.params.displayName == 'AnalysisQuestionId'){
        this.openAnalysisQuestionairModal();
      }else if(this.params.displayName == 'TermId'){
        this.openTermModal();
      }else if(this.params.displayName == 'PaymentDataId'){
        this.openPaymentModal();
      }else if(this.params.displayName == 'UserDocumentId'){
        this.openOwnDocumentModel();
      }else   {
        this.openusermodal(0);
      } 
    };
    
    openschoolmodal(){
      const initialState = {
        list: [
          {id:0}
        ]
        
      };
      this.bsModalRef = this.modalService.show(SchooleditComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
      this.bsModalRef.content.saved.take(1).subscribe(this.addNewRecord.bind(this))
    }
    openquestionmodal() {
      const initialState = {
        list: [
          {id:0}
        ]
        
      };
      this.bsModalRef = this.modalService.show(QuestioneditComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
      this.bsModalRef.content.saved.take(1).subscribe(this.addNewRecord.bind(this))
    }
    
    addUser(){
      if(this.params.displayName == 'SchoolId'){
        this.openschoolmodal();
      }
      else if(this.params.displayName == 'QuestionId'){
        this.openquestionmodal();
      }
      else    
        this.openusermodal(0);
    }
    openusermodal(userid) {
        const initialState = {
          list: [
            {id:userid}
          ]
          
        };
        this.bsModalRef = this.modalService.show(UsereditComponent,{id: 1, initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
        this.bsModalRef.content.saved.take(1).subscribe(this.addNewRecord.bind(this))
       // this.bsModalRef.content.event.subscribe(res => {
         // this.addNewRecord(res);
         //  this.service.formData.UserInfo.CroppedFileContent = res.data;
       //});
      }
      addNewRecord(someData: any){
        this.commonServ.CallOnRefreshGrid();
      }
      
    onSortChanged() {
      this.checkSortOrder();
        this.ascSort = this.descSort = this.noSort = 'inactive';
        if (this.params.column.isSortAscending()) {
            this.ascSort = 'active';
        } else if (this.params.column.isSortDescending()) {
            this.descSort = 'active';
        } else {
            this.noSort = 'active';
        }
    }
    
      /**
   * Check for sort Order
   */
  checkSortOrder() {
    const sortingArray = this.params.api.getSortModel();
    let j: number;
    if (sortingArray.length > 1) {
      setTimeout(() => {
        for (j = 0; j < sortingArray.length; j++) {
          this.sortNumber = j + 1;
          const sortingDom = <HTMLElement>document.getElementById('sortingOrder' + sortingArray[j].colId.replace(/\s/g, ''))
          sortingDom.innerHTML = this.sortNumber.toString();
        }
      });
    }
  }

    onSortRequested(order, event) {
        this.params.setSort(order, event.shiftKey);
        this.checkSortOrder();
    }
    openAssignmentModal(){
      const initialState = {
        list: [
          {id:0}
        ]
        
      };
      this.bsModalRef = this.modalService.show(EditassignmentComponent,{initialState, class: 'modal-xl col-12',ignoreBackdropClick: true});
      this.bsModalRef.content.saved.take(1).subscribe(this.addNewRecord.bind(this))
    }
    openEventModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditeventComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
    }
    openFeedbackModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditFeedbackComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
    }
    openSurveyModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditsurveyComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
    }
    openChatModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditchatComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
    }
    openAnalysisModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(AnalysisComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
    }
    openAnalysisQuestionairModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(AnalysisQuestionairComponent,{id: CUSTOMCONST.LABEL_TWO_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
    }
    openOwnDocumentModel(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditOwnDocsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
    }
    openTermModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EdittermComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
    }
    openPaymentModal(){
      const initialState = {
        list: [
          {id:0}
        ]      
      };
      this.bsModalRef = this.modalService.show(EditPaymentsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
    }
}
