<div class="card chatbox">
    <div class="card-header">
      <!-- <label>{{ChatExpend}}</label> -->
      <label>{{commonServ.messageMasterInfo && commonServ.messageMasterInfo.Name}} </label>
      <label class="float-right"><i class="border-0 fa  cursor-pointer" [ngClass]="isChatExpend ? 'fa-angle-up' : 'fa-angle-down'" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" (click)="collapseToggle()"></i> <i class="fa fa-times ml-2 cursor-pointer" aria-hidden="true" (click)="closeChat()" *ngIf="commonServ.messageMasterInfo && commonServ.messageMasterInfo.createdBy == commonServ.UserId"></i></label>
    </div>
    <div>
        <div *ngFor="let masterMessage of masterMessageList">
            <a (click)="handleChatClick(masterMessage)" href="Javascript:void(0);">{{masterMessage.Name}}</a>
        </div>
    </div>
    <div class="collapse show" id="collapseExample">
        <div class="card-body chat-body">
            <ul class="chat list-style-none pl-0">
                <li class="clearfix" *ngFor="let messagelist of messageList">
                    <div style="margin-bottom: 10px;" class="input-group post" [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing':'incoming'">
                        <div *ngIf="messagelist.Message != '%PAUSE%' && messagelist.Message != '%RESUME%'" class="w-100" [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing-chat':'incoming-chat'">
                            <label class="shadow-sm mb-0">
                                <span class="sender-name" *ngIf="messagelist.CreatedBy != commonServ.UserId">{{messagelist.CreatedByName}} <hr class="mt-0 mb-1"></span>
                                 
                                <pre class="mb-0 message">{{messagelist.Message}} </pre>
                            </label>
                        </div>
    
                        <div *ngIf="messagelist.Message != '%PAUSE%' && messagelist.Message != '%RESUME%'" [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing-chat-dateandtime':'incoming-chat-dateandtime'">
                            <label class="mb-0">
                                {{messagelist.CreatedOn}} 
                            </label>
                         </div>

    
                    </div>
                </li>
                <li>
                    <div *ngIf="isAdmin && messageList && messageList.length > 1">
                        <button type="button" class="btn btn-danger"  (click)="sendMessageAction(messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME' : 'PAUSE')" aria-label="Close">{{messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME' : 'PAUSE'}}</button>
                    </div>

                </li>
                <!-- <li class="clearfix" >
                    <div style="margin-bottom: 10px;" class="post" >
                         <div class="incoming-chat" >
                            <label >
                                Incoming Massages
                            </label>
                        </div>
    
                        <div class="incoming-chat-dateandtime">
                            <label >
                                22-07-2021 13:09
                            </label>
                        </div>
    
                    </div>
                </li>
                <li class="clearfix" >
                    <div style="margin-bottom: 10px;" class="post" >
                        <div class="outgoing-chat" >
                            <label >
                                outgoing test text outgoing test text outgoing test text outgoing test text outgoing test text
                            </label>
                        </div>
    
                        <div class="outgoing-chat-dateandtime ">
                            <label >
                                22-07-2021 13:08
                            </label>
                        </div>
    
                    </div>
                </li> -->
            </ul>
        </div>
        <div class="card-footer" *ngIf="messageList">
            <div class="chart-form position-relative">
                <div class="input-group text-group">
                    <textarea [disabled]="!isAdmin && messageList[messageList?.length-1].Message == '%PAUSE%'" autofocus type="text" class="form-control js-auto-expand resizebele-false" (keyup)="textAreaAdjust(this)" name="Message" #Message="ngModel" [(ngModel)]="messageInfo.Message" placeholder="" rows="1" max-rows="5" style="height:unset;"></textarea>
                </div>
                <div class="input-group btn-send-group">
                    <button [disabled]="!isAdmin && messageList[messageList?.length-1].Message == '%PAUSE%'"  class="btn btn-link" (click)="sendMessage()" ><i class="fa fa-paper-plane" ></i></button>
                </div>
            </div>
          </div>
    </div>
    
</div>