import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { Contact,ContactResponse } from '../shared/contact.model';
import contactPageData from '../schoolConst';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactPageData:any;
  jobTitleList:any;
  schoolTypeList:any;
  countryList:any;
  sourceList:any;
  constructor(public service:CommonService,
    private notificationSrv:NotificationService,
    private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Contact Us for a FREE Demo | HLEP');
    this.metaService.addTags([
      {name: 'keywords', content: 'Hybrid Learning, Global Collaboration, National Collaboration, Assignments, Assessments, Daily homeworks'},
      {name: 'description', content: 'Reach out to us for a free demo of our all-in-one learning management system. Streamline school management and enhance teaching and learning experiences with us.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/contact');
    window.scroll(0,0);
    this.service.formData.JobTitle ="";
    this.service.formData.Country ="";
    this.service.formData.SchoolType ="";
    this.service.formData.Source ="";
    this.jobTitleList = contactPageData.contact.JobTitle; 
    this.schoolTypeList = contactPageData.contact.SchoolType; 
    this.countryList = contactPageData.contact.CountryList; 
    this.sourceList = contactPageData.contact.Source;
    
  }
  onContact(form:NgForm){
this.service.contactMe().subscribe((res:ContactResponse)=>
  {
    if(res.status){
      this.notificationSrv.ShowInfo(res.message);
    }
    else{
      this.notificationSrv.ShowError(res.message);
    }
    this.resetForm(form);
    //this.toastr.success('Information sent successfully');
  },err=>{console.log(err); });
  }
resetForm(form:NgForm){
form.form.reset();
this.service.formData =  new Contact();
}

}
