import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import CONFIG from './Const';
import { OwnFile } from './own-file.model';
@Injectable({
  providedIn: 'root'
})
export class OwnFileService {

  readonly baseUrl = CONFIG.BASE_URL+'api/Home';
  public formData = new OwnFile();
  questionId = 0;
  QList:any[] = [];
  constructor(private http:HttpClient) {
    this.formData = new OwnFile();
   }
  

   getowndocumentlist(){
    return this.http.get(this.baseUrl+'/getowndocumentlist');
  }
  getowndocument(documentId:number){
    var config = {
      params: {
        documentId: documentId       
      }
   };
    return this.http.get(this.baseUrl+'/getowndocument',config);
  }
  generateshareddocumentid(){
    return this.http.post(this.baseUrl+'/generateshareddocumentid', this.formData);
  }
  setdocumentsettigns(){
    return this.http.post(this.baseUrl+'/setdocumentsettigns', this.formData); 
  }
  updatedocumentsettings(){
    return this.http.post(this.baseUrl+'/updatedocumentsettings', this.formData); 
  }
  deleteowndocument(){
    return this.http.post(this.baseUrl+'/deleteowndocument', this.formData); 
  }
  
}
