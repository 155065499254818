<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-11">A collaborative approach</h2> 
          <h1 class="w-100 text-left theam-color-4">Content-Provider-Dashboard</h1> 
          <h4 class="w-100">What type of partnership model is required to create an effective collaboration between content providers and schools?</h4>
          </div>
      </div>

      <section class="bg-theam-body investing-yourself">
        <div class="container-fluid" >
          <div class="position-relative py-80px">
            <div class="row justify-content-center position-relative z-inxex-1">
              <div class="col-12 col-md-8 align-self-center">
                <div class="header ">
                  <h2 class="d-block text-left theam-color-5 title">You can overcome these four challenges with one simple solution: the world's most powerful teaching and learning platform.</h2>
                  <h2 class="d-block text-left theam-color-9 title">1. Motivating and engaging students during their learning process</h2>
                  <h2 class="theam-color-10 m-0 mb-1">According to research, 70% of learning occurs through interaction with others, whereas only 30% occurs on-the-job and through course material. It is the lack of respect for this ratio that negatively affects the learning experience.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">It is inevitable that we will lose when we fight against human nature when it comes to social learning. By embracing social learning, learners can ask questions, receive mentorship, and share their accomplishments and growth. Using advanced techniques like gamification and microlearning, increase student engagement.</h2>
                  <h2 class="d-block text-left theam-color-9 title">2. Lack of interest in learning</h2>    
                  <h2 class="theam-color-10 m-0 mb-1">If you want to create meaningful learning experiences, you need to tailor them to each individual. A one-size-fits-all approach often means a one-size-fits-nobody approach.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Develop learner personas As a starting point, create learner personas that help you tailor your learning process to meet the needs of the audience based on their experience, preferences, and tech-savviness. Personalized learning journeys can be created with an AI-powered learning management system. By adapting to users' preferences, making smart recommendations, and giving learners control over their learning, it empowers them to take charge of their own learning.</h2>
                  <h2 class="d-block text-left theam-color-9 title">3. Research-based authoring tools and learning platforms</h2> 
                  <h2 class="theam-color-10 m-0 mb-1">We have so many platforms and tools to choose from-each designed for a specific purpose. Choosing the right company is crucial.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Get a feel for the product before you buy it and shrink the decision space Make a list of features you must have and only consider solutions that meet those features. Don't commit to a platform until you've seen it in your organization's ecosystem. You can also get intel on how fast your course content can be delivered by consulting trusted sources. Can the platform be integrated with legacy systems? What is the availability of off-the-shelf content? Are the data analytics and data management features robust? For even more information, check out the most important features of an LMS.</h2>
                  <h2 class="d-block text-left theam-color-9 title">4. Enhancing the role of learning and development</h2>
                  <h2 class="theam-color-10 m-0 mb-1">The importance of growing your students and educating your schools cannot be overstated. You should ensure that your learning platform helps you by</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Students are more engaged. Enhance school loyalty and parent satisfaction. Increase revenue through improved sales effectiveness and customer satisfaction. You will be more productive and save time by automating tedious, manual tasks. By demonstrating the impact of learning on business, L&D can improve. Find a learning platform that can do the above and you'll be well on your way to making learning a competitive advantage.</h2>
                </div>
              </div>
             </div>
          </div>
          </div>
          
          
          
          <div class="container-fluid bg-theam-body">
            <div class="row justify-content-center">
              <div class="col-10">
                <div class="row mt-5 align-items-center">
              
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">Tailored to today's needs and opportunities for tomorrow</h2>
                    </div>
          
                  <p></p>
                    
                  <ul class="pl-0 custom-list-style1"> 
                    <li class="mb-2 right-angle1">Invest in the industry's top providers to attract and upskill your people.</li>
                    <li class="mb-2 right-angle2">All your content development needs in one place.</li>
                    <li class="mb-2 right-angle3">Manage schools and student experiences more efficiently.</li>
                    
                  </ul>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3  order-1 order-md-2">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-600.png" alt="value_education" class="m-0 w-100">
                    </div>
                  </div>
                </div>
      
                <div class="row mt-5 align-items-center">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-597.png" alt="value_education" class="m-0 w-100">
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">Prepare your organization for the future</h2>
                    </div>
          
                    <p></p>
                      
                    <ul class="pl-0 custom-list-style1"> 
                      <li class="mb-2 right-angle1">You can choose from microlearning to deep transformational learning to meet the diverse needs of your workforce.</li>
                      <li class="mb-2 right-angle2">Using schools as a partner, you can deliver the most impactful content to your students.</li>
                      <li class="mb-2 right-angle3">Creating content that is aligned with your unique business needs is the responsibility of a content curation specialist.</li>
                      
                    </ul>
                  </div>
                  
                </div>
      
                <div class="row mt-5 align-items-center">
              
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">Providing content from a single source</h2>
                    </div>
                    <p><span class="font-weight-500"></span>  </p>    
                  <p></p>
                    
                  <ul class="pl-0 custom-list-style1"> 
                    <li class="mb-2 right-angle1">You can search, select, and deploy content directly from the HLEP LMS.</li>
                    <li class="mb-2 right-angle2">In addition, everything can be tracked and reported in one place.</li>
                    <li class="mb-2 right-angle3">Your content library should be developed.</li>
                    
                  </ul>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-598.png" alt="value_education" class="m-0 w-100">
                    </div>
                  </div>
                </div>
      
                <!-- <div class="row mt-5 align-items-center">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-599.png" alt="value_education" class="m-0 w-100">
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">ASSIGNMENTS and ASSESSMENTS</h2>
                    </div>
          
                    <p><span class="font-weight-500">Greater Flexibility, Happier Students</span>  </p>
      
                    <li class="mb-2 right-angle1">Easily assign homework online and set custom due dates to accommodate the needs of individual learners.</li>
                    <li class="mb-2 right-angle2">Allow students multiple attempts when resonding to assignments questions, or give them immediate eedback by making the correct answer visible so they can learn as they go.</li>
                    <li class="mb-2 right-angle3">Send customised and individual feedback to guide the students.</li>
                    <p><span class="font-weight-500">Get a real-time view of student performance</span> </p>
                      
                    <ul class="pl-0 custom-list-style1"> 
                      <li class="mb-2 right-angle1">Let notifications and insights give you an immediate pulse on student completion and comprehension</li>
                      <li class="mb-2 right-angle2">Receive a weekly smmmary repor to assess class and individual progress and pinpoint struggling students.</li>
                      <li class="mb-2 right-angle3">View all assignment score in the application automatically</li>
                    </ul>
                  </div>
                  
                </div>
      
                <div class="row mt-5 align-items-center">
              
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">PLANNING & MANAGEMENT SYSTEM</h2>
                    </div>
          
                  <p>Hybrid Learning Platform, with its Planning and Management feature, offers school administrators a foresight of the future of the institution through a comprehensive evaluation of all systems and thus enables them to create a strategical plan for anall inclusive growth within and beyond the school. It orients the school to a common goal and increases the quality of administration.</p>
                    
                  <ul class="pl-0 custom-list-style1"> 
                    <li class="mb-2 right-angle1">Insightful planning</li>
                    <li class="mb-2 right-angle2">Single Point Access</li>
                    <li class="mb-2 right-angle3">Time Saving Data Integrity</li>
                    <li class="mb-2 right-angle4">Data Integrity</li>
                  </ul>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
                    <div class="mt-2 mb-2 ml-auto mr-auto">
                        <img mat-card-image src="../../assets/images/2X/Group-600.png" alt="value_education" class="m-0 w-100">
                    </div>
                  </div>
                </div> -->
      
              </div>
            </div>
            
          </div>
        </section>
      <!--  <section class="mt-2">
        <div class="row justify-content-center">
            <div class="col-6 font-25px">
                <h2 class="text-primary my-5 text-center"></h2>
                <p class="mb-4"></p>
                <p class="mb-4"></p>
                <h2 class="text-primary my-5 text-center"></h2>
                <p class="mb-4"></p>
                <p class="mb-4"></p>
                <h2 class="text-primary my-5 text-center"></h2>
                <p class="mb-4"></p>
                <p class="mb-4"></p>
                <h2 class="text-primary my-5 text-center"></h2>
                <p class="mb-4">:,,,,,,,,,,,,,,,</p>
                <p class="mb-4"></p>
                
            </div>
        </div>
      </section> -->
</div>