import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssignamentView } from './assignemnt.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class AssignemntService {

  constructor(private http:HttpClient) { 

  }
  // readonly baseUrl = CONFIG.BASE_URL+'api/';
  readonly baseUrl = CONFIG.BASE_URL+'api/Assignment';
  formData:AssignamentView = new AssignamentView();

  getassignments(){
    return this.http.get(this.baseUrl+'/getassignments');
  }
  getassignmentsByParam(config){
    return this.http.get(this.baseUrl+'/getassignments',config);
  }
  getassignment(assignmentId:number){
       var config = {
      params: {
        assignmentId: assignmentId,
      }
    };
    return this.http.get(this.baseUrl+'/getassignment',config);
  }
  saveassignment(){
    return this.http.post(this.baseUrl+'/saveassignment',this.formData);
  }
  deleteassignment(assignmentId){
    var assObj = {AssignmentInfo:{AssignmentId:assignmentId,IsActive:false}};
    return this.http.post(this.baseUrl+'/deleteassignment',assObj);
  }
  getassignedassignments(){
    return this.http.get(this.baseUrl+'/getassignedassignments');
  }
  getassignedassignmentsbydate(date:string){
    var config = {
      params: {
        selectedDate: date,
      }
    };
    return this.http.get(this.baseUrl+'/getassignedassignmentsbydate',config);
  }

  assignToClass(assignedList){
    return this.http.post(this.baseUrl+'/associateAssignmentToClasses',assignedList);
  }
  savestudentassignment(){
    return this.http.post(this.baseUrl+'/savestudentassignment',this.formData);
  }
  getquestionoverviewinfo(assignmentId:number){
    var config = {
      params: {
        assignmentId: assignmentId,
      }
    };
    return this.http.get(this.baseUrl+'/getquestionoverviewinfobyassgnId',config);
  }
  updatePoints(data){
    return this.http.post(this.baseUrl+'/updatepoints',data);
  }
  updateStdResults(stdAnswerMasterInfo){
    return this.http.post(this.baseUrl+'/updatestdresults',stdAnswerMasterInfo);
  }
  getassignmentsprintinfo(){
    return this.http.get(this.baseUrl+'/getassignmentprintInfo');
  }
  getstudentmasterid(assingmentId:number){
    var config = {
      params: {
        assignmentId: assingmentId,
      }
    };
    return this.http.get(this.baseUrl+'/getstdmasterdataid',config);
  }
  //get Selected Student Assignedstd Assignments By Param
  getSSAAByParam(params){
    return this.http.get(this.baseUrl+'/getassignedstdassignmentsbydate',params);
  }

}