import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root'
})
export class StudentAuthGuard implements CanActivate {
  constructor(private router:Router, private commonServ: CommonService , public notificationSrv: NotificationService) {
 
  }
  canActivate(){
    var loggintooken = this.commonServ.GetToken();
    var  role = this.commonServ.GetRole();
    if(loggintooken == '' || role !== "Student") {
      this.notificationSrv.ShowError('Access Denied, You Do Not Have Authorize to Access This Page!');
      
      this.router.navigateByUrl('');
      return false;
    }
    else{
      return true;
    }
    
  }
  
}
