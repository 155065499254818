<section *ngIf="IsEnableButton && !IsStatus">
    <a  class="cursor-pointer"  *ngFor="let celldata of celName" (click)="celldata.Click(params, celldata.that)"> {{celldata.Name}}</a>
</section>

<section *ngIf="IsEnableButton  && IsStatus">
    <a  class="cursor-pointer" [ngClass]="{'text-primary':rowStatus == 'New', 'text-success': rowStatus == 'Completed', 'text-danger':rowStatus == 'Expired'}" [title]="rowStatus" *ngFor="let celldata of celName" (click)="celldata.Click(params, celldata.that)"> {{rowStatus == 'New'?'Start':rowStatus}}</a>
</section>

<section *ngIf="IsEnableMeetingLink && IsMeeting">
    <a  class="cursor-pointer" *ngFor="let celldata of celName" (click)="celldata.Click(params, celldata.that)"> {{celldata.Name}}</a>
</section>

<section *ngIf="IsEnableStudentLink">
    <a  class="cursor-pointer" *ngFor="let celldata of celName" (click)="celldata.Click(params, celldata.that)"> {{username}}</a>
</section>
<section >
    <div>
    <a  class="cursor-pointer" [ngClass]="!IsCompleted?' text-primary':'text-success'"  *ngFor="let celldata of celName" (click)="celldata.Click(params, celldata.that, IsCompleted)">{{!IsCompleted?'Not Completed':'Completed'}} </a>
    </div>
    
</section>
<section *ngIf="IsImageUrl">
    <!-- <img [src]="ImageUrl" alt=""> -->
    <p>{{ImageUrl}}</p>
</section>