import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { SignalrService } from './shared/signalr.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from './shared/notification.service';
import { Contact, UserInfo, UserRegisterData } from './shared/contact.model';
import { UserService } from './shared/user.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from './shared/canonical.service';
import { BnNgIdleService } from 'bn-ng-idle';
import contactPageData from './schoolConst'
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { ViewInfo } from './shared/user.model';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //@ViewChild(LoginComponent) loginRef;
  title = 'A New ERA Of Hybrid Learning';
  //@Input()
  logoutIcons = faSignOut
  isLogedIn: boolean = false;
  role: string;
  // isChatExpend:boolean=false;
  SignupName:string;
  SignupFName:string;
  SignupLName:string;
  SignupEmail:string;
  SignupRoleId:number=0;
  SignupRoleList:any[]= contactPageData.contact.SignupRoleList;
  schoolLogoUrl:string = '../assets/images/logo/logo.png';
  constructor(public translateService: TranslateService,public commonServ:CommonService,
    public router:Router,private signalRServ:SignalrService,
    private notificationSrv:NotificationService,
    public userServ: UserService, private titleService: Title, private metaService: Meta,
    private canonicalService: CanonicalService,
    private bnIdle: BnNgIdleService) {
  
    this.commonServ.schoolLogoUrl = this.schoolLogoUrl;
    translateService.addLangs(['en', 'da']);
    translateService.setDefaultLang('en');
    //translateService.use('en');
    //this.isLogedIn = this.usrService.isLoggedIn;
    this.commonServ.isUserLoggedIn = false;
    this.commonServ.IsLoggedIn$.subscribe(
      item => {
        this.isLogedIn = true;
        this.commonServ.isUserLoggedIn = true;
        this.role = localStorage.getItem('UserRole');
        this.commonServ.role = localStorage.getItem('UserRole');
        this.schoolLogoUrl = this.commonServ.schoolLogoUrl;
        this.startInactivityWatching();
      }
    );
    this.commonServ.isChatting.next(false);
    this.commonServ.openChat$.subscribe(
      item => {
        this.commonServ.isChatting.next(true);
      }
    );    
    this.signalRServ.chatStarted.subscribe(
      item => {
        if (this.commonServ.isChatting) {
          this.commonServ.isChatting.next(false);
        }
        this.commonServ.isChatting.next(true);
        if (!this.commonServ.messageMasterInfo || this.commonServ.messageMasterInfo.MessageMasterId != item.MessageMasterId) {
          this.commonServ.messageMasterInfo = item;
        }
        this.commonServ.loadMessage(item);
      }
    );
    // if(this.isLogedIn){
    //   this.startInactivityWatching();
    // }
  }
  ngOnInit() {
    this.commonServ.userFormData=new UserRegisterData();
    this.commonServ.userFormData.UserInfo = new UserInfo();
    this.commonServ.CheckLoggedInStatus();
    // console.log(this.SignupRoleList);
    this.getuseregisterinfo();
    // this.userServ.viewInfo.UserInfo.Role = '';
    
  }
  getuseregisterinfo(){
    this.commonServ.getuseregisterinfo().subscribe((res: any) => {
      this.commonServ.userFormData = res;
      
    });
  }
  startInactivityWatching(){
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.OnLogOut();
        this.bnIdle.stopTimer();
        // alert('Your session has been expired');
      }
    });
  }
  onRegister(form:NgForm){
    this.commonServ.saveuseregisterinfo().subscribe((res: any) => {
      if (res.status) {
        this.notificationSrv.ShowInfo(res.message);
      }
      else {
        this.notificationSrv.ShowError(res.ErrorMessage);
      }
      this.resetForm(form);
    }, err => { 
      this.notificationSrv.ShowError(err);
     });
  }
  resetForm(form: NgForm) {
    form.form.reset();
    this.SignupName = '';
    this.SignupEmail = '';
  }
  translateSite(langauge: string) {
    this.translateService.use(langauge);
  }
  OnLogOut() {
    this.userServ.logOut().subscribe((res: any) => {
      this.commonServ.clearLocalStorage();
      this.isLogedIn = false;
      this.commonServ.isUserLoggedIn = false;
      this.signalRServ.UnSubscribe(this.commonServ.UserId);
      localStorage.removeItem('schoolAuth');
      this.router.navigateByUrl('/login');
      this.commonServ.isChatting.next(false);
    }, err => { console.log(err); });
    // this.commonServ.clearLocalStorage();
    // this.isLogedIn = false;
    // this.commonServ.isUserLoggedIn = false;
    // this.signalRServ.UnSubscribe(this.commonServ.UserId);
    // localStorage.removeItem('schoolAuth');
    // this.router.navigateByUrl('/login');
  }
  onActivate(event: Event) {
    window.scrollTo(0, 0);
  }
  changeMenu(){
    var menuItem = document.querySelector("#navbarNav");
    if(menuItem.className === 'navbar-collapse collapse show'){
      menuItem.setAttribute('class','navbar-collapse collapse');
    }


    // var activeClass = document.querySelector(".active");
    // activeClass.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    // if(activeClass.getAttribute('class')=== 'dropdown-item'){
    //   activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand active');
    // }
    // else{
    //   // activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    // }
    
    
  }
  changeactiveMenu(event){
    if(event.target.className === 'dropdown-item'){
      event.target.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand active');
    }
    else{
      var activeClass = document.querySelector(".dropdown-toggle");
      activeClass.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    }

    // console.log();
    // var activeClass = document.querySelector(".active");
    // console.log(activeClass);
    
  }
}
