import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeedbackData } from './feedback.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http:HttpClient) { 

  }
  readonly baseUrl = CONFIG.BASE_URL+'api/Feedback';
  formData:any = new FeedbackData();

  getFeedback(){
    return this.http.get(this.baseUrl+'/getfeedbacks');
  }
  getfeedback(params:any){
    return this.http.get(this.baseUrl+'/getfeedback',params);
    
  }
  getStudentFeedbacks(params:any){
    return this.http.get(this.baseUrl+'/getstudentfeedbacks',params);
  }
  getfeedbackById(FeedbackId:any){
    var config = {
      params: {
        FeedbackId: FeedbackId,
      }
    };
    return this.http.get(this.baseUrl+'/getfeedbackById',config);
  }
  savefeedback(){
    return this.http.post(this.baseUrl+'/savefeedback',this.formData);
  }
  
  //survey apis
  getsurveylist(){
    return this.http.get(this.baseUrl+'/getsurveys',undefined);
  }
  getsurvey(params:any){
    return this.http.get(this.baseUrl+'/getsurvey',params);
  }
  getstudentsurveys(){
    return this.http.get(this.baseUrl+'/getstudentsurveys',undefined);
  }
  getsuveyresults(params:any){
    return this.http.get(this.baseUrl+'/getsuveyresults',params);
  }
  saveSurvey(surveyInfo:any){
    return this.http.post(this.baseUrl+'/savesurvey',surveyInfo);
  }
  saveSurveyResult(surveyResultInfo:any){
    return this.http.post(this.baseUrl+'/savesurveyresult',surveyResultInfo);
  }
}
