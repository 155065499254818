import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';

@Component({
  selector: 'app-content-provider-dashboard',
  templateUrl: './content-provider-dashboard.component.html',
  styleUrls: ['./content-provider-dashboard.component.scss']
})
export class ContentProviderDashboardComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('HLEP - Content providers Dashboard');
    this.metaService.addTags([
      {name: 'keywords', content: 'Research and Evidence based education'},
      {name: 'description', content: 'Unlock effective collaboration between content providers and schools with our powerful learning platform. Overcome challenges and enhance your educational impact.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/content-provider-dashboard');
    window.scroll(0,0);
  }

}
