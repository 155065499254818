<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <h3 class="modal-title h3 m-1" >{{list[0].id === 0?'Add Own Document':'Edit Own Document'}}</h3>
        </div>
        <div class="modal-body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white p-2">
                    <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0" >
                                <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                 name="service.formData.FileId" [(ngModel)]="service.formData.FileId" 
                                 (updatedResponseEmitter)="filesuploaded($event)" 
                                 title="{{'Upload File' | translate}}" canUpload="true"  canSendData="true" saveButtonText="" class="inner-btn-none">
                                </mb-large-files-uploader>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="saveDocument()" class="btn btn-success float-right" type="button" >Save</button>
            <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)" aria-label="Close">Close</button>
        </div>
    </div>
</div>
