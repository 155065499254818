import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';

@Component({
  selector: 'app-value-education',
  templateUrl: './value-education.component.html',
  styleUrls: ['./value-education.component.scss']
})
export class ValueEducationComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Teaching Social Emotional Learning in Scholls');
    this.metaService.addTags([
      {name: 'keywords', content: 'SEL, Social Emotional Learning, Humanity, Character, Responsibility, Respect, Honesty, Empathy, Not Be Lazy, Kindness'},
      {name: 'description', content: 'We need Social and Emotional Learning as one of the main goals of our formal education systems as we face todays challenging and changing learning environments.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL();
    window.scroll(0,0);
  }

}
