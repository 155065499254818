import { EventEmitter,Injectable } from '@angular/core';
//import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';  
import { MessageData,MessageMasterData } from './chat.model';
//import * as signalR from 'signalr';
//import * as $  from 'signalr/index';
import CONFIG from './Const';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  messageReceived = new EventEmitter<MessageData>();  
  chatStarted = new EventEmitter<MessageMasterData>();  
  chatClosed = new EventEmitter<MessageMasterData>();  
  connectionEstablished = new EventEmitter<Boolean>();  
  //private connectionIsEstablished = false;  
  //private _hubConnection: HubConnection;  
  //readonly baseUrl = 'http://172.24.235.14/ChatService/MessageHub';//CONFIG.BASE_URL+'api/SchoolHub';   
  // constructor() { 
  //  // this.createConnection(); 
  // }

  //connection: signalR.HubConnection;
  readonly baseUrl = CONFIG.BASE_URL;//+'schoolHub';
   //signalR connection reference
   private connection: any;
    
   //signalR proxy reference
   private proxy: any;


   constructor(@Inject(PLATFORM_ID) platformId: Object) {
       //initialize connection
      //  this.connection = $.connection;
       
      //  //to create proxy give your hub class name as parameter. IMPORTANT: notice that I followed camel casing in giving class name
      //  this.proxy = $.connection.hub.createHubProxy('chatHub');

      //  //define a callback method for proxy
      //  this.proxy.on('messageReceived', (latestMsg) => this.onMessageReceived(latestMsg));

      //  this.connection.hub.start();
      if(isPlatformBrowser(platformId)){
        this.connection = $.hubConnection(this.baseUrl, {  
        useDefaultPath: false  
    });  
    this.proxy = this.connection.createHubProxy('SchoolHub');  
  }
   }
   
   private onMessageReceived(latestMsg: string) {
       console.log('New message received: ' + latestMsg);
   }

   //method for sending message
   broadcastMessage(msg: string) {
       //invoke method by its name using proxy 
       this.proxy.invoke('sendMessage', msg);
   }

  // public initiateSignalrConnection(headerTxt,userId): Promise<any>{
  //   console.log(this.baseUrl);
  //   return new Promise((resolve, reject) => {
  //     this.connection = new signalR.HubConnectionBuilder()
  //       .withUrl(this.baseUrl,{
  //         headers:headerTxt
  //         // skipNegotiation: true,
  //         // transport: signalR.HttpTransportType.WebSockets 
  //       }) // the SignalR server url
  //       .build();
  //     this.connection
  //       .start()
  //       .then(() => {
  //         console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
  //         this.connection.invoke('Subscribe',userId.toString());
  //       })
  //       .catch((error) => {
  //         console.log(`SignalR connection error: ${error}`);
  //         reject();
  //       });
  //   });
  // }
  public initiateSignalrConnection(headerTxt,userId){
    // this.createConnection(headerTxt);
    // this.startConnection(userId);
    // this.registerOnServerEvents();
    //connecting the client to the signalr hub   
    console.log(userId.toString());
    var that = this;
    this.connection.start().done(function() {  
      
      that.proxy.invoke('Subscribe', userId.toString());
      that.registerOnServerEvents(that);
      //alert("Connected to Signalr Server");  
  })  
  .fail(function(err) {  
    console.log('failed in connecting to the signalr server'+err);
     // alert("failed in connecting to the signalr server");  
  })  
  }
  UnSubscribe(userId){
    //this._hubConnection.invoke('Unsubscribe',userId.toString());
    this.proxy.invoke('Unsubscribe', userId.toString());
  }
  startChat(messageMaster: MessageMasterData) {  
    this.proxy.invoke('StartChat', messageMaster);
   // this._hubConnection.invoke('StartChat', messageMaster);  
  }  
  closeChat(messageMaster: MessageMasterData) { 
    this.proxy.invoke('CloseChat', messageMaster); 
    //this._hubConnection.invoke('CloseChat', messageMaster);  
  }  
  sendMessage(message: MessageData) {  
    this.proxy.invoke('SendMessage', message); 
   // this._hubConnection.invoke('SendMessage', message);  
  }  
  joinGroup(groupId:string) {  
    this.proxy.invoke('Subscribe', groupId); 
  //  this._hubConnection.invoke('Subscribe',groupId);
  }  
  
  createConnection(headerTxt:string) {  
    // this._hubConnection = new HubConnectionBuilder()  
    //   .withUrl(this.baseUrl)  
    //   .build();  
  }  
  
  startConnection(userId:number): void {  
    // this._hubConnection  
    //   .start()  
    //   .then(() => {  
    //     //this.connectionIsEstablished = true;  
    //     console.log('Hub connection started');  
    //     this.connectionEstablished.emit(true);  
    //     this._hubConnection.invoke("Subscribe",userId.toString())
    //   })  
    //   .catch(err => {  
    //     console.log('Error while establishing connection, retrying...');  
    //     setTimeout(function () { this.startConnection(); }, 5000);  
    //   });  
  }  
  
  registerOnServerEvents(that): void {  
    that.proxy.on('chatStarted', function(data){
  console.log(data);
      that.chatStarted.emit(data);
    });
    that.proxy.on('chatClosed', function(data){ that.chatClosed.emit(data);});
    that.proxy.on('messageReceived', function(data){that.messageReceived.emit(data);});
    that.proxy.on('logoutUserReceived', function(data){
      console.log("logoutreceived" + data);
    //   if (data && data.indexOf($rootScope.repository.loggedUser.sessionId) > 0) {
    //     //do nothing as it's same user logout.
    // }
    // else {
     
    //         $rootScope.backendProxy.invoke('Unsubscribe', $rootScope.repository.loggedUser.cuID);            
    //         if (data.indexOf('LogoutMessage') >= 0) {
    //             modalService.showInformationByKey('LogoutMessage', true).then(function () {
    //                 $rootScope.Logout();                   
    //             });
    //         }
    //         else {
    //             $rootScope.Logout();               
    //         }
    //     }     
        });
    // this._hubConnection.on('ChatStarted', (data: any) => {  
    //   this.chatStarted.emit(data);  
    // }); 
    // this._hubConnection.on('ChatClosed', (data: any) => {  
    //   this.chatClosed.emit(data);  
    // }); 
    // this._hubConnection.on('MessageReceived', (data: any) => {  
    //   this.messageReceived.emit(data);  
    // });  
  }  
}
