import { Component, OnInit,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileData, ProfileFileData } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-editpic',
  templateUrl: './editpic.component.html',
  styleUrls: ['./editpic.component.scss']
})
export class EditpicComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public FileInfo:FileData = new FileData();
  public ProfileFileInfo:ProfileFileData = new ProfileFileData();
  list: any = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn : any;
  editimageof:any;
  
  constructor(public service:UserService,public bsModalRef: BsModalRef) { }
  
  ngOnInit(): void {
    this.editimageof = this.list[0].editImageOf;
  }
  
    
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.fileToReturn = this.base64ToFile(
          event.base64,
          this.imageChangedEvent.target.files[0].name,
        );
    this.FileInfo.FileName = this.imageChangedEvent.target.files[0].name;
    this.FileInfo.CroppedContentString = event.base64;
    this.FileInfo.FileSize = this.imageChangedEvent.target.files[0].size;
    this.FileInfo.FileType = this.imageChangedEvent.target.files[0].type;
        return this.fileToReturn;
    }
    coverImageCropped(event: ImageCroppedEvent){
      this.croppedImage = event.base64;
        this.fileToReturn = this.base64ToFile(
          event.base64,
          this.imageChangedEvent.target.files[0].name,
        );
    this.ProfileFileInfo.FileName = this.imageChangedEvent.target.files[0].name;
    this.ProfileFileInfo.CroppedContentString = event.base64;
    this.ProfileFileInfo.FileSize = this.imageChangedEvent.target.files[0].size;
    this.ProfileFileInfo.FileType = this.imageChangedEvent.target.files[0].type;
        return this.fileToReturn;
    }

    base64ToFile(data, filename) {

      const arr = data.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
  
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
  
      return new File([u8arr], filename, { type: mime });
    }
  
    imageLoaded(image: any) {
      
      this.FileInfo.OriginalContentString = image.original.base64;
    }
    coverImageLoaded(image: any) {
      
      this.ProfileFileInfo.OriginalContentString = image.original.base64;
    }
    cropperReady() {
        /* cropper ready */
    }
    loadImageFailed() {
        /* show message */
    }
    onSave(form:NgForm){
      if(this.editimageof === "profilePic"){
        this.triggerEvent(this.FileInfo);
      }
      else if(this.editimageof ==="coverImage"){
        this.triggerEvent(this.ProfileFileInfo);
      }
      this.bsModalRef.hide();
    }
    triggerEvent(item: FileData) {
      this.event.emit({ data: item , res:200 });
    }
}