import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  list: any[] = [];
  ConfirmMessage:string = "Are you sure want to submit?"
  constructor(public bsModalRef2: BsModalRef,public modalService:BsModalService,private commonService:CommonService) {
  //this.ConfirmMessage = this.list[0].message;
  this.CUSTOMCONST = CUSTOMCONST;
  }
  CUSTOMCONST : any;

  
  
  ngOnInit(): void {
  }
  onConfirm(){
    this.commonService.CallOnConfirm();
    this.modalService.hide(CUSTOMCONST.CONFIRM_MODAL);
  }
}
