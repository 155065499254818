<div class="container-fluid topnav" [ngClass]="{'bg-transparet': !isLogedIn, 'bg-light':isLogedIn}">
  <nav class="navbar navbar-expand-sm navbar-dark {{role}}">
    <a class="navbar-brand d-block d-sm-none" routerLink="/">
      <img [src]="commonServ.schoolLogoUrl" alt="" style="max-width:100px">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand mr-4 d-none d-sm-block" routerLink="/">
      <img [src]="commonServ.schoolLogoUrl" alt="" style="max-width:100px">
    </a>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto mr-auto mb-2 mb-lg-0" *ngIf="!isLogedIn">
        
        <li class="nav-item" >
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>Home
          </a>
          
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            About 
          </a>
          <div class="dropdown-menu" aria-labelledby="functionsDropdown">
            <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/about/" routerLinkActive="active">About Us</a>
            <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/social-impact/" routerLinkActive="active">Social impact</a>
            <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/research/" routerLinkActive="active">Research</a>
            <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/ecosystem-platform/" routerLinkActive="active">Ecosystem Platform</a>
         </div>
        </li>
      <li class="nav-item" >
        <a class="navbar-brand" (click)="changeMenu()" routerLink="/educators/" routerLinkActive="active">
          <i class="flag flag-united-states"></i>Educators
        </a>
      </li>
      <li class="nav-item" >
        <a class="navbar-brand" (click)="changeMenu()" routerLink="/content-provider/" routerLinkActive="active">
          <i class="flag flag-united-states"></i>Content Provider
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div class="dropdown-menu" aria-labelledby="functionsDropdown">
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/features/" routerLinkActive="active">Features</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/benefits/" routerLinkActive="active">Benefits</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/student-dashboard/" routerLinkActive="active">Student Dashboard</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/parent-dashboard/" routerLinkActive="active">Parent  Dashboard</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/teacher-dashboard/" routerLinkActive="active">Teacher Dashboard</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/educator-dashboard/" routerLinkActive="active">Educator Dashboard</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/content-provider-dashboard/" routerLinkActive="active">Content Provider Dashboard</a>
       </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Get Involved
        </a>
        <div class="dropdown-menu" aria-labelledby="functionsDropdown">
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/get-involved/school-boards/" routerLinkActive="active">School Boards</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/get-involved/publishers/" routerLinkActive="active">Publishers</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/get-involved/corporate-collaboration/" routerLinkActive="active">Corporate Collaboration</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/get-involved/global-network/" routerLinkActive="active">Global Network</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/get-involved/partner-program/" routerLinkActive="active">Partner Program</a>
          
       </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Our Programs
        </a>
        <div class="dropdown-menu" aria-labelledby="functionsDropdown">
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/academics/" routerLinkActive="active">Academics</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/sel/" routerLinkActive="active">SEL (Social Emotional Learning)</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/life-skills/" routerLinkActive="active">Life Skills</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/outside-the-box/" routerLinkActive="active">Outside the Box</a>
          <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/pbl/" routerLinkActive="active">PBL (Project Based Learning)</a>
       </div>
      </li>
      
      <li class="nav-item" >
        <a class="navbar-brand" (click)="changeMenu()" routerLink="/faqs/" routerLinkActive="active">
          <i class="flag flag-united-states"></i>FAQs
        </a>
      </li>

        <li class="nav-item" >
          <a class="navbar-brand" (click)="changeMenu()" routerLink="/login/" routerLinkActive="active">
            <i class="flag flag-united-states"></i>Login
          </a>
        </li>
        
      </ul>
      <ul class="navbar-nav ml-auto mr-auto mb-2 mb-lg-0" id="customized-scrollbar" *ngIf="isLogedIn">
        
        <li class="nav-item" *ngIf="role == 'Student'">
          <app-student-nav></app-student-nav>
        </li>
       
        <li class="nav-item" *ngIf="role == 'Parent'">
          <app-parent-nav></app-parent-nav>
        </li>
        
        <app-cordinator-nav class="d-flex" *ngIf="(role == 'Cordinator')"></app-cordinator-nav>
        <app-teacher-nav class="d-flex" *ngIf="(role == 'Teacher')"></app-teacher-nav>
        <app-admin-nav class="d-flex" *ngIf="(role == 'Admin')"></app-admin-nav>
        <app-superadmin-nav class="d-flex" *ngIf="(role == 'SuperAdmin')"></app-superadmin-nav>

        <li class="nav-item" *ngIf="(role == 'SuperAdmin' || role == 'Admin' || role == 'Cordinator' || role == 'Teacher')">
          <a class="navbar-brand" routerLink="" (click)="OnLogOut()">{{'Logout' | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="(role == 'Parent' || role == 'Student')">
          <section>
                    
            <div class="d-flex justify-content-center">
                <div class="mb-3 p-1 small-group-item">
                    <mat-card class="dasbord-info-card p-0 pt-2 bg-group10 shadow">
                      
                        <div class="grouup-body">
                          <a  routerLink="" (click)="OnLogOut()">
                            <div class="text-center text-black">   
                              <div class="icon">
                                  <fa-icon [icon]="logoutIcons"></fa-icon>
                                  <!-- <i class="fa fa-sign-out" aria-hidden="true"></i> -->
                              </div>
                              <div class="content">
                                  <p>Logout</p>
                              </div>
                          </div>
                          </a>
                            
                        </div>
                        
                    </mat-card>
                </div>
                </div>
                </section>
          
        </li>
      </ul>
    </div>
    <!-- <a class="navbar-brand mr-4 d-none d-sm-block" routerLink="/">
      <img src="../assets/images/2X/Icon-feather-search.png" alt="" style="max-width:20px">
    </a> -->
  </nav>
</div>
<div class="container-fluid p-0 bg-light">
  <!-- <app-register></app-register> -->
  <mat-progress-spinner class="progress-spinner-group" *ngIf="commonServ.isLoading |async" mode="indeterminate"
    value='66' diameter="45"></mat-progress-spinner>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <app-chatbox *ngIf="commonServ.isChatting |async"></app-chatbox>
  <!-- <app-chatbox ></app-chatbox> -->
</div>
<footer>
  <section *ngIf="!isLogedIn">
    <div class="container-fluid footer-section bg-footer">
      <div class="row justify-content-center pt-5">
        <!-- <div class="col-10">
          <div class="row mt-5 "> -->
        <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-6 mb-3">
          <div class="footer-logo mb-4">
            <a routerLink="/"><img [src]="commonServ.schoolLogoUrl" alt="" style="max-width:100px"></a>
          </div>

          <p>As the World's First Research and Evidence Based Learning Ecosystem Platform, The Hybrid Learning Ecosystem Platform is the newest zone in Educational Technologies.</p>

        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-6 mb-3">
          <div class="row footer-menu">
            <div class="col-6">
              <ul class="list-unstyled mb-0">
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/about/">About</a></li>
                <li><a routerLink="/benefits/">Benefits</a></li>
                <li><a routerLink="/features/">Features</a></li>
                
              </ul>
            </div>
            <div class="col-6">
              <ul class="list-unstyled mb-0">
                <li><a routerLink="/partners/">Partners</a></li>
                <li><a routerLink="/faqs/">FAQs</a></li>
                <li><a routerLink="/contact/">Contact</a></li>
                <li><a routerLink="/login/">Login</a></li>
              </ul>

            </div>
            <div class="col-12">
              <ul class="list-unstyled Social-media-group">
                <li>
                  <a href="https://www.facebook.com/HLEPEdtech" target="_blank">
                    <img src="../assets/images/2X/XMLID_834_1.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@HLEPEdTech" target="_blank">
                    <img src="../assets/images/2X/youtube_icon.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/HLEPEdtech" target="_blank">
                    <img src="../assets/images/2X/iconmonstr-twitter-1.png" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/hlepedtech/" target="_blank">
                    <img src="../assets/images/2X/XMLID_801_1.png" alt="">
                  </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/hlepedtech/" target="_blank">
                    <img src="../assets/images/2X/Instagram-Glyph1.png" alt="">
                  </a>
                </li>

              </ul>
            </div>
          </div>

        </div>

        <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
          <div class="section-header">
            <h2 class="d-block title pt-1 pb-1">Tools, Resources For K12 and Higher Education.</h2>
            <h2 class="d-block title pt-1 pb-1">PLEASE SIGN UP TO QUALIFY FREE ACCOUNT.</h2>
          </div>
          <p></p>
          <form novalidate #form="ngForm" autocomplete="off" (submit)="onRegister(form)">
            <div class="row form-row m-0">
              <div class="custom-p-2 d-flex" >
                <label class="d-block w-75px">I am an/a</label>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="Role" [(ngModel)]="commonServ.userFormData.UserInfo.Role">
                  <mat-radio-button class="example-radio-button ml-3" *ngFor="let role of SignupRoleList" [value]="role.Code">
                    {{role.Name}}
                  </mat-radio-button>
                </mat-radio-group>
               
            </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" placeholder="Your First Name (Required)" name="FirstName" required
                  minlength="5" [(ngModel)]="this.commonServ.userFormData.UserInfo.FirstName">
              </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" placeholder="Your Last Name (Required)" name="SurName" required
                  minlength="5" [(ngModel)]="this.commonServ.userFormData.UserInfo.Surname">
              </div>
              <div class="form-group col-6 mb-0">
                <input class="form-control form-control-sm" type="email" placeholder="Your Email Address (Required)" name="Email"
                  required minlength="10" [(ngModel)]="this.commonServ.userFormData.UserInfo.Email">
              </div>
              
              <div class="form-group col-6 mb-0">
                <button class="btn btn-primary form-control form-control-sm" type="submit">Join The Waitlist</button>
              </div>
            </div>
          </form>
          <div class="icon-group2">

          </div>
        </div>
      </div>
      <!-- </div>
      </div> -->

    </div>
  </section>



  <div class="bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="pb-3 pt-3 m-0 text-white text-center">Copyright &copy; HLEP EdTech | All Right Reserved
          </p>
        </div>
       
      </div>
    </div>
  </div>
</footer>