import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';

@Component({
  selector: 'app-educator-dashboard',
  templateUrl: './educator-dashboard.component.html',
  styleUrls: ['./educator-dashboard.component.scss']
})
export class EducatorDashboardComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Educator Dashboard | HLEP');
    this.metaService.addTags([
      {name: 'keywords', content: 'Ecosystem Educator Dashboard'},
      {name: 'description', content: 'HLEP provides better management, easier differentiation and greater visibility for educators teaching with Ecosystem Platform. HLEP helps teachers access students'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/educator-dashboard');
    window.scroll(0,0);
  }

}
