import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders 
} from '@angular/common/http';
import { Observable } from 'rxjs';
import{CommonService} from '../shared/common.service';
import { map, filter, finalize } from 'rxjs/operators';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public cService:CommonService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.cService.getAuthToken('schoolAuth');
    if(token){
        const newReq = request.clone({
            //setHeaders:{'Authorization':token}
            headers: request.headers.set('schoolAuth',token)
    });
 this.cService.isLoading.next(true);
    return next.handle(newReq).pipe(finalize(()=>{
      this.cService.isLoading.next(false);
    }));
}else{
    const newRequest = request.clone({ headers: request.headers });
    this.cService.isLoading.next(true);
    return next.handle(newRequest).pipe(finalize(()=>{
      this.cService.isLoading.next(false);
    }));
}
}
}