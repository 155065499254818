import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';


@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss']
})
export class TeacherDashboardComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hybrid Learning Ecosystem Teacher Dashboard');
    this.metaService.addTags([
      {name: 'keywords', content: 'Online and Offline Learning System'},
      {name: 'description', content: 'A well-research based platform that gives teachers valuable insight into the students every day learning process and activities.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/teacher-dashboard');
    window.scroll(0,0);
  }

}
