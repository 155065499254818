<div class="modal-dialog  modal-xl m-0">
    <div class="modal-content">
      <div class="modal-header bg-theam p-1">
        <h3 class="modal-title h3 m-1">Confirmation</h3>
      </div>
      <div class="modal-body p-2">
       <p>{{ConfirmMessage}}</p>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn btn-success" (click)="onConfirm()">Save</button>
        <button type="button" class="btn btn-danger"  (click)="modalService.hide(CUSTOMCONST.CONFIRM_MODAL)" aria-label="Close">Cancel</button>
      </div>
    </div>
  </div>
