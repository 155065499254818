export class OwnFile {
  FileId: number;
  Description: string;
  Url: string;
  FileName: string;
  OwnFileInfo: OwnFileData;


  ClinicId: number;
CreatedOn: string;
CroppedContentString: string;
CroppedFilepath: string;
ErrorMessage: string;
ExternalShareId: string;
FileHeader: string;
FileOrder: number;
FilePath: string;
FileSize: number;
FileType: string;
FileTypeId: number;
FileUploadName: string;
IsActive: boolean;
IsExternalShare: boolean;
IsOwnDocument: boolean;
IsSaved: boolean;
OriginalContentString: string;
Rotate: number;
UserDocumentId: number;
UserId: number;
}
export class OwnFileData {
  UserDocumentId: number;
  FileName: string;
  FilePath: string;
  CroppedFilepath: string;
  CroppedContentString: string;
  OriginalContentString: string;
  FileType: string;
  FileHeader: string;
  FileSize: number;
  Description: string;
}
