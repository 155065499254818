import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superadmin-nav',
  templateUrl: './superadmin-nav.component.html',
  styleUrls: ['./superadmin-nav.component.scss']
})
export class SuperadminNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  changeactiveMenu(event){
    if(event.target.className === 'dropdown-item'){
      event.target.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand active');
    }
    else{
      var activeClass = document.querySelector(".dropdown-toggle");
      activeClass.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    }
  }
  
  changeMenu(){
    var menuItem = document.querySelector("#navbarNav");
    if(menuItem.className === 'navbar-collapse collapse show'){
      menuItem.setAttribute('class','navbar-collapse collapse');
    }
    var activeClass = document.querySelector(".active");
    activeClass.setAttribute('class','nav-link dropdown-toggle navbar-brand');
    if(activeClass.getAttribute('class')=== 'dropdown-item'){
      activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','nav-link dropdown-toggle navbar-brand active');
    }
    else{
      activeClass.parentElement.parentElement.firstElementChild.setAttribute('class','navbar-brand');
    }
  }
}
