import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-youtube',
  templateUrl: './view-youtube.component.html',
  styleUrls: ['./view-youtube.component.scss']
})
export class ViewYoutubeComponent implements OnInit {
  @Input() list: any[];
  vid: string;
  winWidth:number=0;
  winHeight:number=0;
  player: any;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    if (this.list && this.list.length > 0) {
      this.vid = this.list[0].link.split("watch?v=").pop().split('&')[0];
    }
    // debugger;
    let innerWinWidth = window.innerWidth < 800? (window.innerWidth - 50 ):800
   this.winWidth = innerWinWidth;
   this.winHeight = innerWinWidth / 1.6;

  }

  closeModal() {
    this.modalService.hide();
  }

  
  playerVars = {
    cc_lang_pref: 'en',
  };
  version = '...';
  public ytEvent;


  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.target.pauseVideo();
  }
}
