
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/dashboard/" routerLinkActive="active">{{'Dashboard' | translate}}</a>
  </li>
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Functions
    </a>
    <div class="dropdown-menu" aria-labelledby="functionsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/question/" routerLinkActive="active">Question</a>
   </div>
  </li>