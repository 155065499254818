<div class="banner">
  <div class="banner-bg-image">
    <img src="../../assets/images/2X/common-banner.png" alt="common-banner-bg-image">
  </div>
  <!-- <div class="banner-image">
    <img src="../../assets/images/2X/common-banner.png" alt="faq-banner-image.png">
  </div> -->
  <div class="banner-content-center">
    <h1 class="w-100 text-center">SEL (Social Emotional Learning)</h1>
    <p>
      We need Social and Emotional Learning as one of the main goals of our formal education systems as we face today's challenging and changing learning environments. 
      Social and emotional learning refers to how people develop the skills, attitudes, and knowledge needed to manage their emotions, The skills to achieve goals, 
      show empathy for others, build relationships, and make responsible decisions are increasingly valued by employers, 
      and governments are beginning to emphasize these skills in national education strategies because we know these skills benefit individuals in their careers and daily lives.
      Social and emotional learning is good for the child, good for the work force and good for society. 
    </p>
  </div>
</div>
<section class="mt-2">
    <div class="container-fluid  pb-3 mt-4">
        <!-- <span class="d-block text-center h5 theam-color-3 section-title">Value Education</span> -->
        <div class="row">
          <!-- <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/', 'hybrid_learning']">
                <img mat-card-image src="../../assets/images/courseimages/hybrid_learning.png" alt="Photo of a Shiba Inu">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Hybrid learning</h1>
                <div class="card-devider"></div>
                <p>
                  An open and collaborative with schools, focused on sharing practice and the emergence of potential
                  pathways forward.
                </p>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'hybrid_learning']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
    
              </mat-card-actions>
            </mat-card>
          </div> -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'humanity']">
                <img mat-card-image src="../../assets/images/courseimages/humanity.png" alt="Photo of a Shiba Inu">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Humanity</h1>
                <div class="card-devider"></div>
                <p>
                  Humanity is above all, for any student before learning academics they should know and learn more about
                  humanity.
                </p>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'humanity']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'character']">
                <img mat-card-image src="../../assets/images/courseimages/character.png" alt="Photo of a Shiba Inu">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Character</h1>
                <div class="card-devider"></div>
                <p>
                  Character education is the process of learning common attitudes,
                  beliefs and behaviors that are important for people to have as responsible citizens.
                </p>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'character']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'responsibility']">
                <img mat-card-image src="../../assets/images/courseimages/responsibility.png" alt="Photo of a Shiba Inu">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Responsibility</h1>
                <div class="card-devider"></div>
                <p>
                  Students will understand the following:
                </p>
                <ol>
                  <li>Students will develop an understanding of the concept of responsibility.</li>
                </ol>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'responsibility']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'respect']">
                <img mat-card-image src="../../assets/images/courseimages/respect.png" alt="Photo of a Shiba Inu">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Respect</h1>
                <div class="card-devider"></div>
                <p>
                  Students will understand the following:
                </p>
                <ol>
                  <li>Even when feelings are hurt, we need to treat friends with respect.</li>
                </ol>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'respect']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'honesty']">
                <img mat-card-image src="../../assets/images/courseimages/honesty.png" alt="honesty">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Honesty</h1>
                <div class="card-devider"></div>
                <p>
                  Students will understand the following:
                </p>
                <ol>
                  <li>Honesty is about being truthful in what you say and in what you do.</li>
                </ol>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'honesty']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'empathy']">
                <img mat-card-image src="../../assets/images/courseimages/empathy.png" alt="empathy">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Empathy</h1>
                <div class="card-devider"></div>
                <p>
                  Students will understand the following:
                </p>
                <ol>
                  <li>What empathy is and why it is important. </li>
                </ol>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'empathy']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'not_be_lazy']">
                <img mat-card-image src="../../assets/images/courseimages/not_be_lazy.png" alt="not_be_lazy">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Not Be Lazy</h1>
                <div class="card-devider"></div>
                <p>
                  A lazy person is someone who does not like to work hard.
                  Someone who is not lazy will always work hard and not take shortcuts.
                </p>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'not_be_lazy']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 col-sm-12 mb-3">
            <mat-card class="example-card">
              <a [routerLink]="['/sel/',  'kindness']">
                <img mat-card-image src="../../assets/images/courseimages/kindness.png" alt="kindness">
              </a>
              <mat-card-content class="mb-0 min-height-200">
                <h1>Kindness</h1>
                <div class="card-devider"></div>
                <p>
                  Kindness in the lesson to teach kindness skills through a step-by step of Share, Inspire, Empower, Act,
                  and Reflect. lesson starts with the ‘share’ step to reinforce learning from previous lessons.
                </p>
              </mat-card-content>
              <div class="card-devider mb-2"></div>
              <mat-card-actions class="p-0">
                <a [routerLink]="['/sel/',  'kindness']">
                  <button mat-button class="p-0">Explor More</button>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
</section>
