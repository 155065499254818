import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';
 
 
@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
 
    constructor(private router:Router, private commonServ: CommonService , public notificationSrv: NotificationService) {
 
    }
 
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          if(localStorage.getItem('schoolAuth')){
            this.commonServ.isUserLoggedIn = true;
          }
        if(this.commonServ.isUserLoggedIn !== true) {
          this.notificationSrv.ShowError('Access Denied, Login is Required to Access This Page!');
          // window.alert('Access Denied, Login is Required to Access This Page!')
          //this.router.navigate('/login')
          this.router.navigateByUrl('/login');
          return false;
        }
        return true;
      }
 
    }