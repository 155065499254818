import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private router:Router, private commonServ: CommonService , public notificationSrv: NotificationService) {
 
  }
  canActivate(){
    if(this.commonServ.HaveAccsess() !== true) {
      this.notificationSrv.ShowError('Access Denied, You Do Not Have Authorize to Access This Page!');
      // window.alert('Access Denied, Login is Required to Access This Page!')
      //this.router.navigate('/login')
      this.router.navigateByUrl('');
      return false;
    }
    else{
      return true;
    }
    
  }
  
}
