<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-image">
          <img src="../../assets/images/2X/partner-banner-image.png" alt="faq-banner-image.png">
          
        </div>
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-0">Social Impact in Education:</h2>
          <h1 class="w-100 text-left theam-color-4">Measure the effectiveness of Impact</h1>
              <h4 class="w-100">What are the best ways for educators to prepare tomorrow's leaders for global challenges?</h4>
           
          
        </div>
      </div>
      <section class="bg-theam-body investing-yourself">
         <div class="row justify-content-center position-relative z-inxex-1">
          <div class="col-12 col-md-8 align-self-center">
            <h2 class="d-block text-left theam-color-10 title">Changing Lives Through Education.</h2>
                <p class="mb-5">In 2020, Hybrid Learning Ecosystem Platform has been started. Our purpose statement has guided us ever since. With our Ideology, we clearly state that we believe in the power and joy of learning. We also enrich the relationships between educators and students through innovative and meaningful digital learning experiences. <span class="font-weight-500">One student at a time</span>, we engage, empower, and discover millions of people around the world.</p>
                <p class="mb-4">Our faith influences our corporate responsibility strategy over the long term, as well as the decisions we make every day on behalf of our learners. <span class="font-weight-500">The impact we hope to have on individual learners, communities, and society is also guided by this goal.</span></p>
                <h2 class="d-block text-left theam-color-8 title">Social Impact - Making education accessible</h2>
                
                <p class="mb-4">With our Hybrid Learning Ecosystem Platform, we aim to eliminate barriers to <span class="font-weight-500">Quality Education and a Bright Future for Everyone.</span> Through our digital learning materials, partnerships, and product innovations, we make education more affordable and accessible.</p>
                <p class="mb-5">Through our state-of-the-art digital platforms, as well as our affordability efforts, we hope to reach millions of learners globally. With our technology and content, millions of users can benefit every year. Our goal is to gain the trust of hundreds of institutions and tens of thousands of faculty and content providers worldwide. Our platforms integrate <span class="font-weight-500">Content</span>, <span class="font-weight-500">Assignments</span>, <span class="font-weight-500">Assessments</span>, Interactive online Classes, adaptive Tools, and Analytics to reach a <span class="font-weight-500">Global</span> audience.</p>
                
                <h2 class="d-block text-left theam-color-9 title">The Impact of Job Skills on Social Value Stigmas</h2>
               
                <p class="mb-5">Millions of people around the world are unemployed and underemployed for a variety of reasons. Many capable adults cannot find meaningful employment because they did not pursue a multi-year degree-granting program. Due to this stigma, applicants are overlooked for their actual abilities and skills. As a result, an arbitrary requirement becomes the primary indicator of employability. More and more innovative institutions and employers are recognizing that acquiring a degree does not guarantee employment success. Millions of jobs remain unfilled, so it's time to focus on being job-ready, not just degree-ready. Through supporting learners' job and career goals, we aim to be socially responsible. Our unique position allows us to help reinvent education towards Education for Employment and support all learners in finding meaningful employment.</p>
                
            </div>
        </div>
      </section>

      
  <section class="bg-theam-body social-impact-youtube">

    <div class="container-fluid  pb-3">
      
    <div class="ourpartner-section">
      <div class="row m-0">
        
        <div class="col-12 ">

        <owl-carousel-o [options]="PartnerOptions" (translated)="getPassedData($event)">
          <ng-container *ngFor="let data of youtubeList">
            <ng-template carouselSlide class="border" [id]="data.id">
              <div class="m-auto text-center position-relative">
                <youtube-player 
    videoId="{{data.VideoId}}"
    suggestedQuality="highres" 
    [height]="winHeight" 
    [width]="winWidth" 
    [startSeconds]="7"
    [endSeconds]="80"
    (ready)="savePlayer($event)"
    (change)="onStateChange($event)"
    >
  </youtube-player>
              <!-- <youtube-player
          [videoId]="data.VideoId"
          (ready)="savePlayer($event)"
          (change)="onStateChange($event)"
          [playerVars]="playerVars"
          [height]="winHeight" 
    [width]="winWidth">
        </youtube-player> -->
            </div>
              
            </ng-template>
          </ng-container>
          
          
        </owl-carousel-o>
        </div>
        
      </div>
    </div>
<!-- <button class="btn btn-primary" (click)="pauseVideo(1)">Pause video</button> -->
    </div>
  </section>


      <section class="course-details-content-section">
        <div class="container-fluid theam-title-bg mt-5">
          <div class="row justify-content-center m-0">
              <div class="col-10">
                  <div class="row my-4 align-items-center ">
                      <div class="col-12">
                          <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1 text-center w-70 m-auto">
                              In Edtech, how can we develop an ecosystem platform for social impact?
                              </h2>
                              <p class="w-50 mt-3 mx-auto"></p>
                              <h2 class="d-block text-center text-blue title">We have compiled research and evidence-based case studies here.</h2>
                          </div>
                     </div>
                  </div>
              </div>
          </div>
      </div>
      
        <div class="container-fluid pb-3 px-0">
              <mat-tab-group animationDuration="0ms">
              <mat-tab label="Case Study: 1">
                <div class="mt-3 mx-2">
                  <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                        <ul>
                        <li>The teaching and learning of academic subjects related to school work.</li>
                    </ul>
                    <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                        <ul>
                        <li>Historically, every student learned from his or her families or from school. Students also learned ethics, values, skills, and spiritual well-being in the sameway. Currently, many students learn academics from a variety of sources, including open source and other platforms through which they access open source materials. Eventually, students become confused about the different learning styles available to them.</li>
                        <li>Using a variety of sources can diminish the effects of bias—the preference of one view over another. Bias may prevent objectivity and often leads to an incomplete representation of information.</li>
                        <li>Imagine if a teacher asked students to write a paper on a topic they didn't know anything about. How would they start? Research is the first step, of course! Using reliable books and websites is the best way to learn about a topic. An information source is a place that provides information about a topic.</li>
                        <li>One cannot trust every source, however, not all of them are reliable. In some cases, there can be mistakes or errors that have not been corrected by an editor. It is possible that some are under-researched and relied on guesswork to fill in the gaps. You might even receive incorrect information as a joke from some people.</li>
                    </ul>
                    <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                    <ul>
                        <li>HLEP is Providing limitless opportunities for students to supplement their curriculum that will engage and inspire them. HLEP has Content Providers who preserve histories, cultures, and places; advance scientific research and innovation; celebrate creativity and exploration; and are excited to share their world, which are reviewed by the teachers first and then shared with the students, so 	they are reviewed for accuracy and quality. For accurate, high-quality information, These can all be found from your schools and teachers, who are the best and most reliable sources.</li>
                        <li>Different sources of academic content can be assigned during practice by  the school and teachers using HLEP, depending on the children's class and their ability to learn, within or outside the books.</li>
                        
                    </ul>
                    <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                        <ul>
                        <li>This Ensures that students are not confused or misguided, and to ensure their academic success, students rely on the school's relationship with teachers and parents.</li>
                        <li>Experts and specialists can help students achieve so much more through interaction. Engage students' imaginations, creativity, and a greater passion for learning with interactive educational experiences. Our goal is to empower students of all backgrounds by supporting diverse voices.</li>
                        <li>It makes it easy for you to find programs that connect with your students' curriculum, interests, and lives when our Content Providers offer a variety of topics. To ensure quality learning experiences, all programs are well-researched and evaluated by teachers.</li>
                    </ul>
                </div>
            </mat-tab>
                <mat-tab label="Case Study: 2">
                    <div class="mt-3 mx-2">
                      <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                      <ul>
                          <li>Holistic action to support the learning and wellbeing of young people is lacking globally.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                      <ul>
                          <li>The history of education shows that students learn from home, in the community, and from society as a whole. There is a current focus on academics and students are not able to learn and understand social-emotional learning, values, life skills, and project-based learning.</li>
                          <li>In school, most students take part in academic and non-academic activities, and they develop a sense of belonging - their friends are there, they have good relationships with teachers and other students, and they value educational outcomes. However, many students are not engaged in their studies. They don't think their school experience is important to their future, and neither their classmates nor their teachers accept them. As a result, these students gradually withdraw from school life and become disaffected. Disaffected students can disrupt classes and negatively influence other students. Are we able to meet the needs of disaffected students? How does student engagement affect academic performance? Educators around the world are concerned about these questions.</li>
                          <li>Participation and belonging of students. Student engagement encompasses both of these aspects, not only because they have a direct relationship to student learning, but also because they demonstrate a disposition towards schooling and lifelong learning.</li>
                          <li>Student engagement levels and the prevalence of disaffected students vary considerably among countries. Moreover, the prevalence of disaffected students varies considerably within and among countries, and this variation cannot be attributed solely to students' family backgrounds. Furthermore, the results indicate that literacy performance and student engagement are not necessarily linked; many students with strong literacy performance are still disengaged from school in most countries. Additionally, the analyses identify some factors related to student engagement at school, and provide evidence that strong student engagement does not have to detract from students' reading ability.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                      <ul>
                          <li>Through HLEP, our students are provided with tools and resources to develop social emotional skills and values. Students can enroll in a variety of content providers. Schools and parents can track students' learning outcomes.</li>
                          <li>Both at home and at school, children benefit from parental guidance. It is important for parents to find the right balance between school involvement and parental attitudes about education. As stated in "A New Wave of Evidence" by Southwest Educational Development Laboratory, "when schools, families, and community groups work together to support learning, children tend to do better in school, stay in school longer, and enjoy school more."</li>
                          <li>Parental involvement at school can build positive relationships between parents and kids, as well as between parents and teachers. It will be easier for parents to communicate with their children if they have a better understanding of what their child's day is like. Younger children are often excited to see their parents in a volunteer role, and it may help to give them a positive outlook on school. Parent-teacher relationships can also be built and improved through it. This will allow the teacher to gain a better understanding of the dynamics of each family, their cultural background, and their challenges as well as their strengths. Parents may develop a better understanding of the teacher's expectations and the challenging dynamics that teacher may face in class. Getting to know one another can help resolve such frustrations as how a teacher treats discipline or why a parent is unhappy with the amount of homework her child is given.</li>
                          <li>Within the school environment, conditions must be created that facilitate the success of all stakeholders.</li>
                          <li>It is now beyond dispute that the evidence is conclusive. The more schools and families work together to support learning, the more likely children are to succeed not only in school, but throughout their lives.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                      <ul>
                          <li>A child's first school is at home. Healthy development, learning, wellbeing, and happiness are shaped by the characteristics and features of the home environment, including quality and security of relationships, and the availability of various resources. A Hybrid Learning Ecosystems platform supports the development of young people across physical, cognitive, social, and emotional developmental pathways to set them up for success academically and personally though Home based learning features.</li>
                          <li>A holistic approach to learning will help students develop values based on their ages and abilities, ultimately enabling them to integrate more into society and their families.</li>
                        </ul>

                    </div>
                </mat-tab>
                <mat-tab label="Case Study: 3">
                    <div class="mt-3 mx-2">
                      <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                      <ul>
                          <li>Learning the balance between nature and technology.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                      <ul>
                          <li>It is more common for 21st century parents to watch their children watching a screen than playing outside. Kids spending too much time indoors has become so extreme that it has its own name: nature deficit disorder. Children are suffering from many health problems caused by the digital world, including depression, social isolation, a lack of physical development, and obesity. Children's Environmental Health in the Digital Age. Myopia has increased, learning deficits in preschoolers have increased, vitamin D levels have decreased because of the lack of sunlight exposure, and neocortex has prematurely thinned.</li>
                          <li>While calling it a disorder might be merely rhetorical, kids spend significantly more time indoors than outdoors. As a result of technology, the average American child spends just four to seven minutes each day outdoors but spends over seven hours watching television.</li>
                          <li>There is no better example of a self-sustaining system than nature. With continuous harmony throughout, it automatically self-balances. Technology, on the other hand, is something we are still learning how to balance; it can be a complicated system to learn and manage</li>
                          
                      </ul>
                      <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                      <ul>
                          <li>Hybrid learning Ecosystem Platform provides tool for community-based learning which helps teachers during a day, as they are pulled in many directions. Nonetheless, technology is already integrated into nearly everything we do and nearly every job our students will face. Therefore, to multitask, connect beyond the workday, and collaborate and connect locally and globally, we must be able to multitask. Despite promoting the importance of exposure to technology and digital tools, we must do so responsibly. Proper tools help the schools to teach students how to balance technology use with offline socializing and interpersonal skills. Nevertheless, it is irresponsible to assert that technology merely distracts, diminishes social skills, and holds less value than other subjects.</li>
                          
                      </ul>
                      <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                      <ul>
                          <li>Technology should be seen as a tool, not the solution; we as people will always be the solution to our problems and nature will respond to it.</li>
                          <li>Children observe things with excitement, curiosity, and positivity, and tend to be more nurturing to nature than their adult cohorts. Adapting to this way of thinking enables us to develop technology that can protect, restore, and promote nature so that we can have a cleaner and happier environment.</li>
                          <li>The key in all of this is good instructional design along with a consistent vision and culture built by school administration. Hybrid Learning Ecosystem Platform promote and strengthen a variety of skill sets for students, not just one or two.</li>
                        </ul>
                    </div>
                </mat-tab>
                <mat-tab label="Case Study: 4">
                    <div class="mt-3 mx-2">
                      <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                      <ul>
                          <li>Displaying Student Work. Presentation is an activity in which someone who describes, or explains something to a group of people.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                      <ul>
                          <li>Any profession requires presentation skills to get the audience's attention and captivate them</li>
                          <li>A presentation should be one of the highlights of student learning life. Presentations are a chance to shine, plead a case, spread a word, and influence others.</li>
                          <li>Therefore, students should give an oral presentation to gain experience in delivering their materials in public with a proper language as well as to increase their self-confidence.</li>
                          <li>The difficulties that the student faces when presenting the material consist of: students often bring notes when presenting the material, they often fidget, jiggle or sway when presenting the material, they are stiff when delivering the presentation, and this leads to a lack of inspiration at the end of the presentation.</li>
                          
                      </ul>
                      <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                      <ul>
                          <li>Hybrid Learning Ecosystem Platform provide various options to present the work of the student which can inspire students to present their work from an early age, which can become a habit for life.</li>
                          <li>The best thing about presentations is that they help presenters improve their communication skills. By giving a presentation, the presenter learns how to do it. It is essential for the presenter to understand the intricate elements of what they are presenting, as well as the rationale behind their importance. To ensure that everyone in the audience is able (and willing) to process the information presented, the presenter must be articulate and meticulous during presentation delivery.</li>
                          
                      </ul>
                      <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                      <ul>
                          <li>The Hybrid Learning Ecosystem Platform tools will help students overcome the barriers they face when giving an oral presentation, including shyness, lack of confidence, lack of motivation, and anxiety. Students will be encouraged to reflect on their presentations.</li>
                          <li>By sharing ideas with their partners, they would be able to communicate their ideas without psychological barriers. As such can be done one-to-one, they may feel more relaxed to express their ideas.  As a result, they are also able to learn how to structure their thoughts and communicate their ideas.</li>
                          
                        </ul>
                    </div>
                </mat-tab>

                <mat-tab label="Case Study: 5">
                    <div class="mt-3 mx-2">
                      <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                      <ul>
                          <li>Learning balance between Inward knowledge and outward knowledge.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                      <ul>
                          <li>James has been successful in business for several years. Through a systematic approach, he achieved his goals. All his actions were ruthless, always considering only how to exploit others. 
                            He was rich, well-settled, and he sometimes gave to charity, so everyone called him a success. 
                            In the past, he used to take his family on annual trips to other localities, leaving their homes and cars in their care. The business, the houses, 
                            and the cars didn't seem to matter to him. Whenever he spent time with his family, he felt pride and satisfaction... but sometimes he felt lonely for no apparent reason. 
                            A growing burden of responsibilities left him highly stressed and irritable over time. Despite making public speeches about him, no one wanted to be near him. 
                            Without alcohol or pills, he was unable to sleep. Toward the end of his life, he began to wonder whether his efforts would be rewarded in heaven or if he had missed something important.</li>
                          <li>James is the tragic result of modern education, what we call objective education. It is concerned more with instructing than revealing and focuses only on transmitting information about the outer world. 
                            Young minds are trained to think, look, and investigate only the world around them, without considering who and what they themselves are or how they relate to it. Looking to the objective world for fulfillment becomes a way of life. 
                            We tend to focus on objects rather than finding happiness in ourselves. There will always be a hole of desire left unfilled, and the feeling of satisfaction will only last for a short time.</li>
                          <li>Education cannot ignore objective knowledge, as it has its place. Through the acquisition of objective knowledge at all levels of science, 
                            modern science has reached great heights. The various branches of knowledge are also learning more about the universe. 
                            The problem is that we lose our own orientation in the universe if we do not have knowledge of our own selves, or subjective knowledge. 
                            It is this type of knowledge that reveals what it means to be a human being, what makes us tick, and where we want to go. 
                            We believe that everything we encounter exists for our own exploitation and satisfaction of our desires in an imbalanced state of education, 
                            focused on the acquisition of objective knowledge. We become objects of everything we interact with, even people. It is material success that becomes the goal of life, 
                            but it does not bring true and lasting happiness to our lives. As a result of objective education, we lose the basic qualities of being human, and are plunged into sorrow.</li>
                          <li>All of us live in two worlds, the Inner World, and the Outer World. Although not everyone is aware of it. In an Outer World so intrusive, 
                            the Inner World can easily become invisible. That's why many people spend their entire lives occupied with the Outer World. 
                            There is a possibility that they may completely forget who or what they are.</li>
                            <li>These challenges require social innovation education to put just as much emphasis on developing the inner well-being of student change makers-those whose goal is to create a healthy, just, and caring society-as it does on learning about external systemic challenges.</li>
                            
                          </ul>
                          <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                          <ul>
                            <li>HLEP provides various sources for social innovation education which promotes development of the inner well-being of student and become change makers in the society. A student will be able to understand their own inner world directly which impacts their ability to achieve positive change and sustain themselves through social innovation work. To effect outer change, they must cultivate and use inner skills.</li>

                          </ul>
                          <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                          <ul>
                            <li>Young people who are motivated to solve social and environmental problems will benefit in terms of their lives, careers, and communities. In the event of a pandemic like COVID-19, for example, young people may have a higher level of well-being than their predecessors. Young people, especially those under 25, will experience less depression and anxiety.</li>
                              
                          
                      
                        </ul>
                    </div>
                </mat-tab>
                <mat-tab label="Case Study: 6">
                    <div class="mt-3 mx-2">
                      <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                      <ul>
                          <li>Importance of Human Body and Life.</li>
                      </ul>
                      <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                      <ul>
                          <li>Since the body and mind are inextricably connected, every time we have a thought, we set off a cascade of cellular reactions in our nervous system that influence all the molecules in our body. Our cells are constantly observing our thoughts and being changed by them.</li>
                          <li>Establishing a healthy dialogue between our thoughts and our molecules helps us shift from imbalance to balance. And when we’re in an optimal state of dynamic balance, we naturally tend to listen to our body with love and reverence and make choices that support balance, happiness, and wellbeing. </li>
                          <li>Healing and a feeling of personal wholeness only occur when the Body and life(Soul) are in balance. Balancing them all is necessary for a happy and fulfilled life. And in moments of crisis—especially in moments of crisis—it is vital for you to be vigilant in protecting that balance.</li>
                          <li>Once you know how you feel, it’s essential to know what you want.</li>
                          <li>You can become aware of your body’s subtle signals such as changes in energy level or mood. Bring these thoughts and feelings to your conscious mind. This allows you to change behaviors that don’t make you healthier. You can also learn new skills to change your automatic responses. Physical symptoms are messages from your body, telling you how it is doing and what it needs.</li>
                          <li>Underlying imbalances are commonly overlooked causes of children’s behavior and developmental problems. These imbalances can sometimes be complex and difficult to detect and can certainly affect your child’s brain development and behavior – in both subtle and serious ways.</li>
                          
                        </ul>
                        <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                        <ul>
                          <li>HLEP provides tools and resources that enables students realize the capacity of imagination that other species do not have. Children can communicate and experience being and existence.</li>

                        </ul>
                        <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                        <ul>
                          <li>Students will tell about their life which is powerful. It can help them understand the central themes of their life and find meaning in them. When your sense of meaning in life changes, it can lead to feelings of distress. Getting that sense of purpose back is important for feeling healthy in your body and mind.</li>
                        

                        </ul>
                      </div>
                    </mat-tab>
                    <mat-tab label="Case Study: 7">
                        <div class="mt-3 mx-2">
                          <h2 class="d-block text-left theam-color-1 title">The Definition:</h2>
                          <ul>
                              <li>Mind thinking and Heart thinking</li>
                          </ul>
                          <h2 class="d-block text-left theam-color-8 title">The Challenge:</h2>
                          <ul>
                              <li>It can be difficult for young children to understand the concept of what a thought is, 
                                and it can be especially difficult to tell the difference between a thought and a feeling. For 
                                example, a child may say his or her thought is “I’m scared” (which is actually a feeling) versus “That noise is a burglar trying to break in” (which is a real thought). 
                                It is important to expose the thoughts underneath the feelings! For example, “What is making you scared? What do you think that noise might be?”
                                </li>
                              <li>One way to describe the difference between a thought and a feeling to a young child is to explain that a thought comes from your head, and a feeling comes from your heart.</li>
                              <li>While children can usually describe thoughts that go with feelings of anxiety, in some cases children are unable to identify anxious thoughts, especially children who are very young or not very talkative. At any age, anxiety may be present before there are thoughts about the situation.By making too many suggestions, you may create anxious thoughts. where there were none before.When things are going well and we feel good, we may hardly notice that we are practicing these qualities It's when the going gets tough and we feel less than our best that we may notice that one or more areas of Heart-Mind well-being are lacking in our lives - which may show up as relationship difficulties, out of control emotions, underachieving, or stress. This is just as true for children as it is for adults</li>
                                                           
                          </ul>
                          <h2 class="d-block text-left theam-color-9 title">The Solution:</h2>
                          <ul>
                              <li>HLEP provides various sources and tools to connect deeply with the children who care for and learn together about how to make their hearts and minds stronger and more resilient and will enable them to sustain themselves through social innovation work.It is best not to press your child about any thoughts of anxiety too much .watch to see if your child mentions anxious thoughts in the future. The other approaches to managing anxiety work even when anxious thoughts are not identified.
                          </ul>
                          <h2 class="d-block text-left theam-color-10 title">The Result:</h2>
                          <ul>
                              <li>Children will be able to face challenging times as an opportunity to connect deeply with the others and receive care for and learn together about how to make their hearts and minds stronger and more resilient.</li>                                
                            

                            </ul>    

                    </div>
                    
                </mat-tab>
            </mat-tab-group>
        </div>
    </section>
  </div>