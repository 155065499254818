import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-stu-offer',
  templateUrl: './stu-offer.component.html',
  styleUrls: ['./stu-offer.component.scss']
})
export class StuOfferComponent implements OnInit {
  list:any[];
  studentId:number;
  acdemicYearId:number;
  CUSTOMCONST:any=CUSTOMCONST;
  constructor(public service:UserService, public commonServ:CommonService,
    public modalService:BsModalService, private notificationServ:NotificationService) {
    this.studentId = this.modalService.config.initialState.list[0].id;
   }

  ngOnInit(): void {
    
    this.commonServ.getMlListInfo('MLAcademicYear').subscribe((res : any) => {
      res = res.slice().reverse();
      this.acdemicYearId = res[0].Id;
      this.getStudentDiscount();
    });
  }
  

  getStudentDiscount(){
    this.service.getstudentdiscountinfo(this.studentId,this.acdemicYearId).subscribe((res: any) => {
       this.service.stdDiscountInfo = res;
    });
  }

  addOfferInfo(){
    
    this.service.savestudentdiscountinfo().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully Saved!');
        
      }else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      
    })
  }
}
