 import configData from '../../assets/config.json' ;


const CONFIG = configData;

const USER_EDIT_MODAL = 1;
 const QUESTION_EDIT_MODAL = 2;
 const CONFIRM_MODAL = 3;
 const STDASSIGNMENT_MODAL = 4;
 const RESET_PWD_MODAL = 6;

 const LABEL_ONE_MODAL = 1;
 const LABEL_TWO_MODAL = 2;
 const LABEL_THREE_MODAL = 3;
 
export const CUSTOMCONST =  { USER_EDIT_MODAL, QUESTION_EDIT_MODAL,CONFIRM_MODAL,STDASSIGNMENT_MODAL,
    LABEL_ONE_MODAL,LABEL_TWO_MODAL,LABEL_THREE_MODAL,RESET_PWD_MODAL} ;
export default CONFIG;