import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-youtube-test',
  templateUrl: './youtube-test.component.html',
  styleUrls: ['./youtube-test.component.scss']
})
export class YoutubeTestComponent implements OnInit {

  id = 'V462IsOV3js';
  playerVars = {
    cc_lang_pref: 'en',
  };
  public player:any;
  public ytEvent:any;

  constructor() {
   
  }
  onStateChange(event:any) {
    this.ytEvent = event.data;
  }
  savePlayer(player:any) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }
ngOnInit(): void {
  
}
}
