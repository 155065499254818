
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/teacher/dashboard/" routerLinkActive="active">{{'Dashboard' |
      translate}}</a>
  </li>
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Functions
    </a>
    <div class="dropdown-menu" aria-labelledby="functionsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/teacher/question/" routerLinkActive="active">Question</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/teacher/assignment/" routerLinkActive="active">Assignment</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)"  routerLink="/teacher/event/" routerLinkActive="active">Events</a>
   </div>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/teacher/user-list/" routerLinkActive="active">{{'Students' | translate}}</a>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/teacher/chat/" routerLinkActive="active">{{'Chat' | translate}}</a>
  </li>
<li class="nav-item" >
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/teacher/attendence/" routerLinkActive="active">Attendance</a>
  </li>