import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../common.service';
import { SurveyInfo } from '../../feedback.model';
import { FeedbackService } from '../../feedback.service';
import { NotificationService } from '../../notification.service';

@Component({
  selector: 'app-editsurvey',
  templateUrl: './editsurvey.component.html',
  styleUrls: ['./editsurvey.component.scss']
})
export class EditsurveyComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  list:any[]=[];
  datepickerConfig : BsDatepickerConfig;
  ratingTypeList:any[] = [];
  
  constructor(public surveyServ:FeedbackService,public bsModalRef: BsModalRef,
    private notificationServ:NotificationService,
    private commonService:CommonService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    
   }

  ngOnInit(): void {
    
    this.surveyServ.formData = new SurveyInfo();
    this.commonService.getMlListInfo('MLRatingType').subscribe((res : any) => {
      this.ratingTypeList = res
    });
    this.surveyServ.formData.RatingTypeId = 0;
    if(this.list[0].id > 0){
      var config = {
        params: {
          surveyId: this.list[0].id
        }
      };
      this.surveyServ.getsurvey(config).subscribe((res:any)=>{
        this.surveyServ.formData = res;
      });
    }
  }
  addSurveyInfo(form){
    this.surveyServ.formData.surveyId = this.list[0].id;
    this.surveyServ.formData.IsActive = true;
    this.surveyServ.saveSurvey(this.surveyServ.formData).subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
      }else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  
}
