<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/admin/dashboard/" routerLinkActive="active">{{'Dashboard' |
      translate}} </a>
  </li>
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="functionsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Functions
    </a>
    <div class="dropdown-menu" aria-labelledby="functionsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/question/" routerLinkActive="active">Question</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/analysis/" routerLinkActive="active">Analysis</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/survey/" routerLinkActive="active">Survey</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/assignment/" routerLinkActive="active">Assignment</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/event/" routerLinkActive="active">Events</a>
   </div>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/admin/school/" routerLinkActive="active">{{'School' | translate}}</a>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/admin/user/" routerLinkActive="active">{{'User' | translate}}</a>
  </li>
<li class="nav-item">
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/admin/chat/" routerLinkActive="active">{{'Chat' | translate}}</a>
  </li>
<li class="nav-item" >
    <a class="navbar-brand" (click)="changeMenu()" routerLink="/admin/attendence/" routerLinkActive="active">Attendance</a>
  </li>
  
<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="paymentsDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
      Payments
    </a>
    <div class="dropdown-menu" aria-labelledby="paymentsDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/terms/" routerLinkActive="active">Terms</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/payments/" routerLinkActive="active">Payments</a>
      
   </div>
  </li>
<li class="nav-item dropdown" >
    <a class="nav-link dropdown-toggle navbar-brand" href="#" id="filesDropdown" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Files
    </a>
    <div class="dropdown-menu" aria-labelledby="filesDropdown">
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/unusedfiles/" routerLinkActive="active">Unused files</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/file-management/" routerLinkActive="active">Filemanagement</a>
      <a class="dropdown-item" (click)="changeactiveMenu($event)" routerLink="/admin/own-documents/" routerLinkActive="active">Own Documents</a>
    </div>
  </li>