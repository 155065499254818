import { StylingService } from "ag-grid-community";

export class Assignemnt {    
    AssignmentId:number;
    Name:string;
    Description:string;
    TypeId: number;
    Type:string;
    StartDate:string;
    StartTime:string;
    EndDate:string;
    EndTime:string;
    Url:string;
    QuestionMasterId:number;
    ExplorDescription:string;
    DiscussionPoints:string;
    FileId:number;
    FileInfo:AssignamentFileInfo;
    IsActive:boolean;
    IsQuestionir:boolean;
    IsProject:boolean;
    EmbedUrl:string;
    UrlType:string;
    OptionType:string;
    ClassIdList:string;
    ResponseFileId:number;
    IsHumanityLife:boolean;
    IsSkills:boolean;
    IsAcademics:boolean;
    IsLearnOutOfBox:boolean;
    ResponseFileIds: string;
    AcademicYearId:number;
}
export class AssignamentView{
    AssignmentInfo:Assignemnt;
    //AssignmentTypeList:Array<AssignmentTypeList>;
    QuestionMasterInfo:any;
    QuestionInfo:any;
    ErrorMessage:string;
    IsSaved:boolean;
    QuestionTypeList:any;
}
export class AssignamentFileInfo {
    UserDocumentId:number;
    UserId:number;
    FileName:string;
    FilePath:string;
    CroppedFilepath:string;
    CroppedContentString:string;
    OriginalContentString:string;
    FileType:string;
    FileTypeId:number;
    FileHeader:string;
    Rotate:string;
    FileUploadName:string;
    FileSize:number;
    Description:string;
    FileOrder:number;
    IsActive:boolean;
}


export class AssignmentTypeList{
    Id:number;
    Name:string;
    Code:string;
}
// export class QuestionList{
//     QuestionMasterId: number = 0;
//     Name:string;
//     Description:string;
//     Subject:string;
//     Topic:string;
//     NoOfQuestions:number;
// }