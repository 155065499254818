import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import interactiveClassess from "../schoolConst"
//import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { CommonService } from '../shared/common.service';
import contactPageData from '../schoolConst';
import { NotificationService } from '../shared/notification.service';
import { DemoRequestViewData, RequestInfo } from '../shared/contact.model';
import { Loader } from '@googlemaps/js-api-loader';
import { CUSTOMCONST } from '../shared/Const';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewYoutubeComponent } from '../view-youtube/view-youtube.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  course: string;
  interactiveclassess:any;
  contactPageData:any;
  roleList:any[];
  sourceList:any[];
  requestInfo:RequestInfo = new RequestInfo();
  CUSTOMCONST:any=CUSTOMCONST;
  public bsModalRef:BsModalRef
  bannerFirst: AnimationOptions = {
    path: '../../assets/images/Json/home/assessments_and_assignments_interactive_classess.json',
  };
  bannerSecond: AnimationOptions = {
    path: '../../assets/images/Json/home/banner_1.json',
  };
  bannerThird: AnimationOptions = {
    path: '../../assets/images/Json/home/banner_2.json',
  };
  Assessment_Content_Generation: AnimationOptions = {
    path: '../../assets/images/Json/home/assessments_and_assignments_interactive_classess.json',
  };
  Student_Portfolio : AnimationOptions = {
    path: '../../assets/images/Json/home/student_portfolio.json',
  };
  Planning_Management : AnimationOptions = {
    path: '../../assets/images/Json/home/planning_management.json',
  };
  highlights: AnimationOptions = {
    path: '../../assets/images/Json/home/highlights.json',
  }
  constructor(private actRoute: ActivatedRoute, @Inject(PLATFORM_ID) platformId: Object,
  private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService,public service:CommonService,
  private notificationSrv:NotificationService,public commonServ:CommonService,public modalService:BsModalService,) {
    this.requestInfo = new RequestInfo();
    if(this.actRoute.snapshot.params.id){
      this.course = this.actRoute.snapshot.params.id;
    }
    else{
      this.course = '';
    }
    this.isBrowser = isPlatformBrowser(platformId);
    // this.commonServ.isLoading.next(true);
   }
   
   private isBrowser = false;
  private map:google.maps.Map;
 
  ngOnInit(): void {
    // this.commonServ.isLoading.next(true);
    // setTimeout(() =>  {
    //   this.commonServ.isLoading.next(false);
    // }, 30000);
   this.loadDefaultMap('all');
    
    
 console.log(this.CUSTOMCONST.MapStyle);
 
    //api key
    //AIzaSyDs8OlWeQJ0qVdw06_WKjGDfYymh3Xzyb8
    // this.DemoRequestFormData = new DemoRequestViewData();
    this.roleList = contactPageData.contact.RoleList; 
    this.sourceList = contactPageData.contact.SourceList;
    this.requestInfo.Role ="";
    this.requestInfo.Source ="";
     
    this.getdemorequestinfo();
    if(this.isBrowser)
    window.scroll(0,0);
    this.interactiveclassess = interactiveClassess.interactiveClassess.assets;
    console.log(interactiveClassess.interactiveClassess);
    this.titleService.setTitle('Hybrid Learning Platform For Edtech Solution | Hybrid Learning Platform');
    this.metaService.addTags([
      {name: 'keywords', content: 'Hybrid Learning Platform, Hybrid Learning Platform for EdTech Solution, Community Based ecosystem learning, Evidence Based Education Hybrid Platform, Evidence Based Learning Ecosystem Platform, Home Based ecosystem learning, Hybrid Learning Ecosystem, Hybrid Learning Ecosystem Platform, Hybrid Learning Platform For Ecosystem, Hybrid Learning Platform For Ecosystem Lms, Hybrid Platform For Ecosystem Learning, Platform Ecosystem Hybrid Learning, Positive Professional Learning Ecosystem, Hybrid Learning Platform For Inner Development, Learning Ecosystems For Inner Development'},
      {name: 'description', content: 'Revolutionize education with our hybrid learning platform, combining the best of digital and traditional methods for a flexible and engaging learning environment.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('<link rel="canonical" href="https://hybridlearning.net/" />');

    
    
    
  }
  
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoplay:true,
    autoplayTimeout:8000,
    autoplayHoverPause:true,
    // navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  PartnerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true
  }

  ClientOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  bannerAnimationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
  RequestForDemo(form){
    this.service.DemoRequestFormData.RequestInfo = this.requestInfo;
    this.service.savedemorequestinfo().subscribe((res: any) => {
      if(res.ErrorMessage){
        this.notificationSrv.ShowInfo(res.ErrorMessage);
      }
      // else{
      //   this.notificationSrv.ShowError(res.ErrorMessage);
      // }
    });
  }
  getdemorequestinfo(){
    this.service.getdemorequestinfo().subscribe((res: any) => {
      this.service.DemoRequestFormData = res;
      
      
    });
  }
  loadDefaultMap(location:string){
    const loader = new Loader({
      apiKey: "AIzaSyDs8OlWeQJ0qVdw06_WKjGDfYymh3Xzyb8",
      version: "weekly",
      libraries: ["places"]
    });
    loader.load().then((google) => {
     
      const mapOptions = {
        center: {lat:39.45296, lng:-3.70491},
        zoom: 2.5,
        styles:this.CUSTOMCONST.MapStyle
      };
      this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
      if(location === "all"){
        const canada= new google.maps.Marker({
          position:contactPageData.contact.Location.canada,
          map:this.map
        });
        const india= new google.maps.Marker({
          position:contactPageData.contact.Location.india,
          map:this.map
        });
        const danmark= new google.maps.Marker({
          position:contactPageData.contact.Location.danmark,
          map:this.map
        });
        const usa = new google.maps.Marker({
          position:contactPageData.contact.Location.usa,
          map:this.map
        });
      } else if(location === "canada"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.canada;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const canada= new google.maps.Marker({
          position:contactPageData.contact.Location.canada,
          map:this.map
        });
        window.open('https://www.hybridlearning.net', "_blank");
      } else if(location === "india"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.india;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const india= new google.maps.Marker({
          position:contactPageData.contact.Location.india,
          map:this.map
        });
        window.open('https://www.hybridlearning.in', "_blank");
      } else if(location === "danmark"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.danmark;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const danmark= new google.maps.Marker({
          position:contactPageData.contact.Location.danmark,
          map:this.map
        });
        window.open('https://www.hybridlearning.dk', "_blank");
      } else if(location === "usa"){
        mapOptions.zoom = 4;
        mapOptions.center = contactPageData.contact.Location.usa;
        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
        const usa = new google.maps.Marker({
          position:contactPageData.contact.Location.usa,
          map:this.map
        });
        window.open('https://www.hybridlearning.us', "_blank");
      }
      
  })
  .catch(e => {
    // do something
  });
  }
  playVideo(){
    const initialState ={
      list:[
        {
          id:0,
          link:"https://www.youtube.com/watch?v=0YjASgocidU&t=48s"
        }
      ]
    }
    this.bsModalRef = this.modalService.show(ViewYoutubeComponent,{initialState, class:"model-dialog w-fit-comtent"})
  }

}
