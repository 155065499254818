<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-image">
          <img src="../../assets/images/about_page/about_banner.png" alt="faq-banner-image.png">
        </div>
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="d-block title pt-1 pb-1">Roots of HLEP:</h2>
          <h1 class="w-100 text-left theam-color-4">We Have a Responsibility to Create Products that Help People Succeed.</h1>
              <h4 class="w-100">Around the world, HLEP Powers Learning Experiences.</h4>
                   
        </div>
      </div>
</div>

  <section class="bg-theam-body">
    <div class="container-fluid" >
      <div class="bg-primary py-5" >
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="row ">
              <div class="col-12 mb-3 cart-investing">
                <h2 class="d-block text-left text-white title">Hybrid Learning Ecosystem Platform was founded in 2020 to bring online learning into organisations in a collaborative way. 
                  We took that concept and built out the offer to enable performance improvement and modernisation. 
                  HLEP is used by hundreds of teachers and students in classrooms worldwide.
                   Our growing team is comprised of people who are passionate about empowering educators as they use technology to redefine and improve their classrooms.</h2>
                   <h2 class="d-block text-left text-white title">We collaborate with and advocate for impact-oriented partners who are committed to doing work that accelerates the future of teaching and learning. Our strategic solutions are tailored and impactful to best support these partners in achieving their goals and helping leaders know what to do next.</h2>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
<section class="bg-theam-body investing-yourself">
  <div class="container-fluid" >
    <div class="position-relative py-80px">
      <div class="row justify-content-center position-relative z-inxex-1">
        <div class="col-12 col-md-8 align-self-center">
          <div class="header ">
            <h2 class="d-block text-left theam-color-5 title">Who We Are?</h2>
                   
            <h2 class="theam-color-10 m-0 mb-1">With broad expertise in as Educators, Technologists, Catalysts, youth engagement,
              ecosystem learning (School Based, Home Based, Community Based and Society Based
              Learning), history and weaving, we craft collective impact. We leverage our networks,
              creativity, and capacity to get things done to accelerate transformation of education systems
              at scale. We are bringing theory of change in education.</h2>

              <h2 class="d-block text-left theam-color-5 title">Our Mission</h2>
                   
            <h2 class="theam-color-10 m-0 mb-1">Our mission is to catalyze and promote inspiring learning experiences in history in and
              outside the classroom.</h2>
              <h2 class="theam-color-10 m-0 mb-1">Using rigorous learning science so young people can develop the competencies,
                concepts, and context they need to thrive and succeed.</h2>
                <h2 class="theam-color-10 m-0 mb-1">We aim to support and accelerate the transformation of the entire education system so
                  that this type of learning becomes the norm.</h2>
            
            
          </div>
        </div>
       
      </div>
    </div>
    
  </div>
</section>  
  <section>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                        <div class="mt-2 mb-2 ml-auto mr-auto">
                            <img src="../../assets/images/about_page/Student_with_border.png" alt="Student" class="w-100 img img-fluid">
                        </div>
                      </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                      <div class="section-header">
                        <h2 class="d-block title pt-1 pb-1">What Is The Reality?</h2>
                      </div>
                      <p>We need history now more than ever because our world has changed and is changing so
                        rapidly and radically technological connection is way up, but human connection is way down
                        many of the old paradigms that held us together are gone.</p>
                    <p>We’re at an awkward in-between time many of our hard-fought advances are at real risk let’s
                      face it, our world is a messy & complicated place.</p>
                    <p>The HLEP is dedicated to empowering a generation of young
                      people to take on the challenges and opportunities of today and tomorrow with history as
                      their foundation, guide, and inspiration.</p>
                    <p>We invite educators and content providers to collaborate with us together will impact, and
                      implement, solutions that bring history to life connected to the problems that young people
                      care about handing them the tools to build a better world.</p>
                    <p>We’re an system thinkers into empathetic teams amplifying youth voices creating and
                      spreading a living culture of learning and measuring what really matters.</p>
                    <p>We’re relentlessly focused on impact at scale always looking for partners and partnerships to
                      go big and fast.</p>
                    <p>We believe in the power of ecosystem learning, because together we can do anything.</p>
                    <p>A shift is underway in how the world looks at learning. The communities are beginning to see
                    all the people, places, resources, and experiences that help young people learn as part of a
                    learning ecosystem.</p>                                                                    
                       
                    </div>
                </div>

          <div class="row mt-5 align-items-center">

            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h2 class="d-block text-left theam-color-5 title">HLEP EdTech FOUNDERS STORY</h2>
               <h2 class="d-block title pt-1 pb-1">Why is a powerful word?</h2>
              </div>
              <p><span class="font-weight-500">Why?</span> Because we have experienced the power of this word to transform our startup. Imagine
                how different sectors and contexts could transform through the word ‘why’.</p>
            <ul class="pl-0 custom-list-style1">
        <p><span class="font-weight-500">Let us share our story with you.</span> In 2020, we were in India during the pandemic. As educators and parents, we noticed that
          students were missing supportive technology-based systems, as well as holistic practices
          such as social emotional learning (SEL), life skills, and outside the box.</p>
        <p>We started to think about tech-based education solutions for our students as well as our
          children. Our previous IT experience helped us to see that the platforms available on the
          market had overlapping goals for ecosystem learning. We then began to think of ways to
          improve learning at home as well.</p>
        <p>Our search bore fruit as we explored educational challenges in more depth and hired
           developers to implement what is now known as the ecosystem platform
           (hybridlearning.net). The platform constructively integrates school, home, community, and
           societal learning.</p>
        <p>At the pilot phase of the project, we received positive feedback from students and parents.
          We noticed significant progress in student learning at home and improvement in
          performance in school-based evaluations.</p>
        <p>As a result, we started thinking about launching the Ecosystem EdTech platform globally. To
          do so, we moved to Canada in 2022, as it seemed to be the best place to expand an ecosystem
          business. Our initiative was then hosted by District3 Innovation Center as a social
          innovation startup.</p>
        <p>In the Incubator program, our coach asked us why our innovation is needed in the market;
          why our project is unique; why we are starting it. Why… Why… Why…</p>
        <p>We looked at <span class="font-weight-500">Simon Sinek's WHY</span> through different questions. Before, we were designing our
          strategy a different way. District3 allowed us to think about it more deeply.</p>
        <p>After working through these questions, we gained clarity on how our startup would make a
          difference — through values-and skills-based learning in an integrated platform that helps to
          educate with the goal of changing society for the better.</p>
          <p>The moral of the story is: an idea or an application are not powerful in and of themselves
          until you establish why they’re needed and what purpose they fulfill for the greater good.</p>
        <p>Questioning our approach helped us redefine our Theory of Change in education
          by developing an Ecosystem Platform that promotes systems
          thinking and empathy mapping for Students.</p>                              
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <!-- <img src="../../assets/images/about_page/Teacher_with_border.png" alt="Teacher" class="w-100 img img-fluid"> -->
                  <img src="../../assets/images/about_page/about_us.png" alt="about_us" class="w-100 img img-fluid img-border">
              </div>
            </div>
          </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid theam-title-bg mt-5">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row my-4 align-items-center ">
                    <div class="col-12">
                        <div class="section-header">
                          <h2 class="d-block title pt-1 pb-1 text-center w-70 m-auto">
                             Our Core Values
                            </h2>
                            <p class="w-50 mt-3 mx-auto"></p>
                            <h2 class="d-block text-center text-blue title">It is important for us to hold ourselves accountable to the following core values.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-theam-body">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row mt-5 align-items-center">

                    <div class="col-12 col-sm-12 mb-3">
                        <div>
                            <div class="section-header">
                              
                              <h2 class="theam-color-10 m-0 mb-1">Trust</h2>
                                
                            </div>
                            <p>As a company, we place a great deal of value on building trusted relationships with our customers and employees.</p>
                        </div>
                        <div>
                            <div class="section-header">
                              <h2 class="theam-color-9 m-0 mb-1">Customer Success</h2>
                                
                            </div>
                            <p>The success of our company depends on the success of our customers, and we are dedicated to providing every customer with a positive human experience.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                              <h2 class="theam-color-8 m-0 mb-1">Healthy living</h2>
                                
                            </div>
                            <p>We imagine bold possibilities, muster the courage to go after them, and consider system implications from the start.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                              <h2 class="theam-color-7 m-0 mb-1">Long–Term Plan</h2>
                                
                            </div>
                            <p>We play the long-Term plan and prioritize what has the greatest long-term impact for children.</p>
                            
                        </div>
                        <div>
                            <div class="section-header">
                              <h2 class="theam-color-5 m-0 mb-1">Love and Care</h2>
                               
                            </div>
                            <p>Relationships and genuine connection are at the heart of our work.</p>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  </section>
 