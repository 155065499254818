import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Ecosystem Student Dashboard');
    this.metaService.addTags([
      {name: 'keywords', content: 'Student Persona, Empathy Mapping'},
      {name: 'description', content: 'Elevate your learning experience with the Student Dashboard. A dynamic, all-in-one digital platform. Seamlessly access assignments, resources, and more.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/life-skills/critical-thinking');
    window.scroll(0,0);
  }

}
