import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import { Analysis, AnalysisQuestion, AnalysisView } from './analysis.model';
import CONFIG from './Const';
@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  readonly baseUrl = CONFIG.BASE_URL+'api/analysis';
  formData:AnalysisView = new AnalysisView();
  questionId = 0;
  QList:any[] = [];
  constructor(private http:HttpClient) {
    this.formData.AnalysisMasterInfo = new Analysis();
    // this.formData.AnalysisQuestionListInfo = new Array<AnalysisQuestion>();
   }
  

  getAnalytic(config){
    return this.http.get(this.baseUrl+'/getanalysis');
  }
  getAnalysisList(){
    return this.http.get(this.baseUrl+'/getanalysislist');
  }
  getanalysisview(analysisMasterId :number){
    var config = {
      params: {
        analysisMasterId : analysisMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getanalysisview',config);
  }
  deleteAnalytic(){
    return this.http.post(this.baseUrl+'/saveanalysisview',this.formData);
  }
  // For saving analysis 
  saveAnalysisView(){
    return this.http.post(this.baseUrl+'/saveanalysisview',this.formData);
  }

  // For brining students belongs to corresponding analysis
  getStudentsForAnalysis(analysisMasterId :number){
    var config = {
      params: {
        analysisMasterId : analysisMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getstudentsforAnalysis',config);
    
  }
  // For get analytics questions for specific student
  getStudentAnalysis(stuId:number,analysisMasterId :number){
    var config = {
      params: {
        StudentId : stuId,
        analysisMasterId : analysisMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getstudentanalysis',config);
    
  }
  // for saving specific student answers for analytics questions
  saveStudentAnalysis(){
    return this.http.post(this.baseUrl+'/savestudentanalysis',this.formData);
  }
  // For get specific student answers for analytics questions
  getStudentAnalysisResults(stuId:number,analysisMasterId :number){
    var config = {
      params: {
        studentId : stuId,
        analysisMasterId : analysisMasterId,
      }
    };
    return this.http.get(this.baseUrl+'/getstudentanalysisresults',config);
    
  }

}
