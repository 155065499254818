import { Component, OnInit } from '@angular/core'; 
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hybrid Learning Ecosystem for the Next Generation');
    this.metaService.addTags([
      {name: 'keywords', content: 'Hybrid Learning, Student needs, Teacher collaboration, Education, Empowerment, Future learning'},
      {name: 'description', content: 'At Hybrid Learning Platform, our core values guide how we work, collaborate, and impact education through innovation.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/about');
  }
}