export class FeedbackData {
    FeedbackId: number;
    UserId: number;
    CreatedOn: string;
    CreatedBy: number;
    CreatedByName: string;
    Feedback: string;
    IsActive: boolean;
}
export class SurveyInfo{
    SurveyId: number
    Description: string;
    StartDate: string;
    EndDate: string;
    RatingTypeId : number;
    IsActive: boolean;
    IsSaved: boolean;
    ErrorMessage: string;
    RatingType: string;
    CreatedOn: string;
}
export class SurveyResultInfo{
    SurveyResultId : number
SurveyId: number
Result: string
IsSaved: boolean
ErrorMessage: string
}
