import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-gride-cel',
  templateUrl: './custom-gride-cel.component.html',
  styleUrls: ['./custom-gride-cel.component.scss']
})
export class CustomGrideCelComponent implements OnInit {
  celName : any;
  params:any;
  IsEnableMeetingLink:boolean;
  IsEnableButton:boolean;
  IsMeeting:boolean;
  Url:any;
  ImageUrl:any;
  rowStatus :any;
  IsStatus:boolean;
  IsEnableStudentLink:boolean;
  username:any;
  IsImageUrl:boolean;
  IsCompleted:boolean=false;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params: any) {
  
    this.params=params.value;
    this.celName = params.celName;
    this.IsEnableMeetingLink=params.enableMeetingLink;
    this.IsEnableButton=params.enableButton;
    this.IsEnableStudentLink=params.enableStudentLink;
    // this.IsMeeting = params.value;
    if(params.data.Status){
      this.rowStatus = params.data.Status
      this.username = params.data.Name;
      this.IsStatus = true;
    }
    if(params.data.IsCompleted){
      this.IsCompleted = true;
    }
    if(params.enableMeetingLink){
      this.IsMeeting = params.data.IsMeeting;
      this.Url = params.value
    }
    if(params.IsImageUrl){
      this.ImageUrl = params.value;
      this.IsImageUrl = params.data.IsImageUrl;
      // alert(this.ImageUrl);
    }
    
    // this.IsEnableMeetingURL=params.enableMeetingURL;
    //this.SaveClick = params.SaveClick;
   }

  refresh(params: any) {
    return true;
  }

}
