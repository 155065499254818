import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";
import { SmsAuthenticateComponent } from "./sms-authenticate/sms-authenticate.component";
import { AuthGuard } from './auth/auth.gaurd.service'
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { PasswordResetComponent } from './login/password-reset.component';
import { RegistrationComponent } from './feature/reg/component/registration.component';
import { AdminAuthGuard } from './auth/admin-auth.guard.service';
import { TeacherAuthGuard } from './auth/teacher-auth.guard.service';
import { ParentAuthGuard } from './auth/parent-auth.guard.service';
import { StudentAuthGuard } from './auth/stydent-auth.guard.service';
import { SelfRegistrationComponent } from './feature/reg/component/self-registration.component';
import { FeaturesComponent } from './features/features.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { ParentDashboardComponent } from './parent-dashboard/parent-dashboard.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { ContentProviderDashboardComponent } from './content-provider-dashboard/content-provider-dashboard.component';
import { EducatorDashboardComponent } from './educator-dashboard/educator-dashboard.component';
import { SocialImpactComponent } from './social-impact/social-impact.component';
import { EcosystemPlatformComponent } from './ecosystem-platform/ecosystem-platform.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { YoutubeTestComponent } from './youtube-test/youtube-test.component';

const routes: Routes = [
  {path: 'login' , component: LoginComponent},
  {path: 'register' , component: RegisterComponent},
  {path: 'self-register' , component: SelfRegistrationComponent},
  // {path: 'home' ,redirectTo:"", component: HomeComponent},
  {path: 'contact' , component: ContactComponent},
  {path: 'smsauth' , component: SmsAuthenticateComponent},
  
  // {path: 'test', component: TestComponent},
  {path: 'smsauth' , component: SmsAuthenticateComponent,canActivate: [AuthGuard]  },
  
  { path:'sel',loadChildren:()=>import('./sel/sel.module').then(selMod=>selMod.SelModule)},
  {path:'life-skills',loadChildren:()=>import('./lifeskills/lifeskills.module').then(lifeskillsMod=>lifeskillsMod.LifeskillsModule)},
  {path:'outside-the-box',loadChildren:()=>import('./outside/outside.module').then(outsideMod=>outsideMod.OutsideModule)},
  {path:'pbl',loadChildren:()=>import('./pbl/pbl.module').then(pblMod=>pblMod.PblModule)},

  // {path: 'payment', component: PaymentComponent },
  // {path: 'super-admin', 
  // children:[
  //   {
  //     path:'',loadChildren:()=>import('./admin/admin.module').then(adminMod=>adminMod.AdminModule)
  //   }
  // ], canActivate: [AdminAuthGuard]},
  {path: 'admin', 
  children:[
    {
      path:'',loadChildren:()=>import('./admin/admin.module').then(adminMod=>adminMod.AdminModule)
    }
  ], canActivate: [AdminAuthGuard]},
  {path: 'teacher', 
  children:[
    {
      path:'',loadChildren:()=>import('./teacher/teacher.module').then(teacherMod=>teacherMod.TeacherModule) 
    }
  ],canActivate: [TeacherAuthGuard]},
  {path: 'parent', 
  children:[
    {
      path:'',loadChildren:()=>import('./parent/parent.module').then(parentMod=>parentMod.ParentModule) 
    }
  ],canActivate: [ParentAuthGuard]},
  {path: 'student', 
  children:[
    {
      path:'',loadChildren:()=>import('./student/student.module').then(studentMod=>studentMod.StudentModule) 
    }
  ],canActivate: [StudentAuthGuard]},
  {path: 'faqs' , component: FaqComponent},
  {path: 'about' , component: AboutComponent},
  
  {path:'benefits',loadChildren:()=>import('./benefits/benefits.module').then(benefitsMod=>benefitsMod.BenefitsModule)},
  {path:'academics',loadChildren:()=>import('./academics/academics.module').then(academicsMod=>academicsMod.AcademicsModule)},
  {path:'franchising',loadChildren:()=>import('./franchise/franchise.module').then(franchiseMod=>franchiseMod.FranchiseModule)},

  { path: 'features', loadChildren: () => import('./features/features.module').then(featuresm => featuresm.FeaturesModule) },
  //  {path: 'own-documents' , component:OwnDocumentsComponent,canActivate: [AuthGuard]},
  //  {path: 'features', component:FeaturesComponent},
  { path: 'research', loadChildren: () => import('./research/research.module').then(researchm => researchm.ResearchModule) },
  { path: 'get-involved', loadChildren: () => import('./get-involved/get-involved.module').then(getinvolvedm => getinvolvedm.GetInvolvedModule) },
  { path: 'educators', loadChildren: () => import('./educators/educators.module').then(educatorsm => educatorsm.EducatorsModule) },
  { path: 'content-provider', loadChildren: () => import('./content-providers/content-providers.module').then(CPM => CPM.ContentProvidersModule) },
  { path: 'student-dashboard', component:StudentDashboardComponent},
  { path: 'parent-dashboard', component:ParentDashboardComponent},
  { path: 'teacher-dashboard', component:TeacherDashboardComponent},
  { path: 'educator-dashboard', component:EducatorDashboardComponent},
  { path: 'content-provider-dashboard', component:ContentProviderDashboardComponent},
  { path: 'social-impact', component:SocialImpactComponent},
  { path: 'ecosystem-platform', component:EcosystemPlatformComponent},
  { path: 'test', component:YoutubeTestComponent},
  
  {path: 'reset-password', component: PasswordResetComponent,canActivate: [AuthGuard] },
 
  {path: 'registration', component:RegistrationComponent,canActivate: [AuthGuard]},
  { path: '',  component:HomeComponent, pathMatch: 'full' },
  { path: '**',   pathMatch: 'full' ,component:PageNotFoundComponent},
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
