import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from 'src/app/shared/notification.service';
import { OwnFileData } from 'src/app/shared/own-file.model';
import { OwnFileService } from 'src/app/shared/ownFile.service';

@Component({
  selector: 'app-edit-own-docs',
  templateUrl: './edit-own-docs.component.html',
  styleUrls: ['./edit-own-docs.component.scss']
})
export class EditOwnDocsComponent implements OnInit {
  list:any[]=[];
  url:any;
  CUSTOMCONST:any=CUSTOMCONST; 
  constructor(public service:OwnFileService,private notificationServ:NotificationService,
    private commonService:CommonService,
    public modalService:BsModalService, public bsModalRef: BsModalRef) { 
      this.service.formData.FileId = 0;
    }

  ngOnInit(): void {
    this.getowndocumentlist();
  }
  getowndocumentlist(){
   
    this.service.getowndocument(this.list[0].id).subscribe((res : any) => {
        
      this.service.formData = res;

      //   let arr = res.FileName.split(".");
      //   this.extension = arr.pop();
      // if((this.extension === 'docs' || this.extension === 'doc')){
      //   this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src='+res.FilePath);
      // }else{
      //   this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(res.FilePath);
      // }
       
      //  if(res.FilePath){
      //   this.url = res.FilePath;
      //  }
       
       
    });
  }
  selectDocumentFile(event){
    
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      
        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
         if(!this.service.formData.OwnFileInfo)


         this.service.formData.OwnFileInfo = new OwnFileData();
          this.service.formData.FileName = file.name;
          
          this.service.formData.FileSize = file.size;
          this.service.formData.FileType = file.type;
          this.service.formData.IsOwnDocument = true;
          this.service.formData.OriginalContentString = this.url;
          
          this.service.formData.Url = this.url;
          this.service.formData.OwnFileInfo.OriginalContentString = this.url;
          // debugger;
        }
    }
  }
  saveDocument(){
    this.service.formData.IsOwnDocument = true;
    this.service.updatedocumentsettings().subscribe((res:any)=>{
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
      }
        });
  }
  largefileschange(event){
  }
  filesuploaded(fileInfo){
    if(fileInfo && fileInfo.IsSaved){
      this.service.formData = fileInfo;
    }
    
  
  }
}
