<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-11">Parent Dashboard:</h2> 
          <h1 class="w-100 text-left theam-color-4">Instantly see their child's progress</h1> 
          <h4 class="w-100">With HLEP Learning Analytics, you can demonstrate how your programs create healthy parents, strengthen parent relationships, and improve teacher performance.</h4>
                  
        </div>
      </div>
      <section class="bg-theam-body investing-yourself">
        <div class="container-fluid" >
          <div class="position-relative py-80px">
            <div class="row justify-content-center position-relative z-inxex-1">
              <div class="col-12 col-md-8 align-self-center">
                <div class="header ">

                  <h2 class="d-block text-left theam-color-5 title">World’s most reliable parental control dashboard</h2>
                  
                  <h2 class="theam-color-11 m-0 mb-1">We've made the Parent Dashboard an exciting and powerful place where you'll find tools for motivating and supporting your child's learning journey.</h2>
                  <h2 class="d-block text-left theam-color-5 title">Cutting-edge Technology featuring.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Complete Monitoring Suite</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Our Parenting Control application provide child safety for every developmental stage. The best parental control should follow the child through every phase.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">User friendly dashboard</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">We giver parent’s convenient tools to enable parental monitoring while setting agreeable limits. Our parental tracking application is just as simple and accessible.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Connected and Safe</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">It isn’t enough to develop the best parental control dashboard. With our numerous blog posts and media content. We help parents teach children’s how to use technology properly.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Reliable and Secure</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">We have spent more than 10 years improving our parental awareness to meet the needs and wants millions of families around the world.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Child Empowerment</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Internet parental controls create fun and protected areas, so children can enjoy autonomous activities while harmful content is blocked.</h2>

                  <h2 class="d-block text-left theam-color-9 title">Discrete Control</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Our parental tracking dashboard is both effective and discreet while our parental monitoring application allows remote access and is reliable.</h2>
                  
                  <h2 class="d-block text-left theam-color-5 title">Join the HLEP EdTech parents’ hub now.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Signup with waitlist to get access. </h2>
                  <h2 class="d-block text-left theam-color-5 title">How To Create A Parent Account?</h2>
                  <h2 class="theam-color-11 m-0 mb-1">The Hybrid Learning Platform requires that you create an account if you want to access learning materials and monitor your child’s progress.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">•	Select the Create your free account button on the hybrid learning platform.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">•	Choose Parent from the available options.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">•	Please enter your first and last name, e-mail address, and click Create Account.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">•	Then you will receive an email for the activation and to create the account for your child.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">•	You can also create your Parent Account by clicking Sign up with Google. Depending on your choice, you must select Sign in with Google to access your account moving forward.</h2>
                  <h2 class="d-block text-left theam-color-9 title">That's it, your parent account is created!</h2>
                  <h2 class="theam-color-11 m-0 mb-1">You will now be taken to your account where you can either link your child's existing account or create one for them.</h2>
                  <h2 class="d-block text-left theam-color-5 title">How to link a child's account with a parent's account?</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Your child's account can be linked to your Parent Account, which allows you to monitor their progress, reward them, and manage any memberships that you may have purchased.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">If you don't already have a Parent Account, please follow the steps find above steps to Create a Parent Account.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Questions about mapping child to parent account:</h2>
                  <h2 class="d-block text-left theam-color-5 title">Before logging in, did you give your email address to the school or content Provider?</h2>
                  <h2 class="theam-color-11 m-0 mb-1">This is an essential condition to be able to make the link between you and your children's account. You must deliver your email to each of your children's school or content Provider. Once it has been entered into the school's database, you will be able to log in.</h2>
                  <h2 class="d-block text-left theam-color-9 title">IMPORTANT: You must use the same email for all your children if you want to see them at the same time.</h2>
                  <h2 class="d-block text-left theam-color-5 title">Where can I find the information, I need to link my child to my account?</h2>
                  <h2 class="theam-color-11 m-0 mb-1">school or content Provider code.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">If you do not know the code in which your child's opted for school or content Provider, know the code from them.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Your child's First Name and Last Name</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Be sure to enter your child's full first and last name as they appear in their school record. Don't use a diminutive and be sure to enter all the last names in their school record.</h2>
                  <h2 class="d-block text-left theam-color-5 title">Childs unique ID</h2>
                  <h2 class="theam-color-11 m-0 mb-1">This ID you can find in student profile or can get you from school or Service Provider.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">NOTE: You'll also need to have at least one child account linked to your own in order to access the dashboard!</h2>
                 
                  
                </div>
              </div>
             </div>
          </div>
          </div>
          
        
      </section>
</div>