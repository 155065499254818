import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { EventService } from 'src/app/shared/event.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-editevent',
  templateUrl: './editevent.component.html',
  styleUrls: ['./editevent.component.scss']
})
export class EditeventComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  list:any[]=[];
  datepickerConfig : BsDatepickerConfig;
  classList:any[] = [];
  minDate = new Date();
  dropdownSettings:IDropdownSettings;
  role:string;
  yearList:any=[];
  constructor(public service:EventService,public bsModalRef: BsModalRef,private notificationServ:NotificationService,private commonService:CommonService) {
    this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: true, todayPosition: 'center'  });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.role = commonService.role;
   }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.service.getEvent(this.list[0].id).subscribe((res: any) => {
    this.service.formData = res;
    this.yearList = res.AcademicYearList;
  });
  this.commonService.getclasses().subscribe((res:any)=>{
    this.classList = res;
  });
  this.service.formData.EventInfo.EventId = this.list[0].id;
 
  }
  addEventInfo(form){
    this.service.formData.EventInfo.IsActive = true;
    this.service.saveEvent().subscribe((res:any)=>{
      if(res.IsSaved){
        this.notificationServ.ShowInfo('Successfully saved!');
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
      //   this.service.getEvents().subscribe((res: any) => {
      //     this.gridReload.emit(res);   ;
      // });
      
      }else{
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){
      
      var ids = item.Id;
      for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+this.service.formData.ClassMappingList[i].Id;
      }
    }
  }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){
      
      var ids = '';
      for(var i=0;i<items.length;i++){
        if(ids){
          ids = ids+',';
        }
        ids = ids+items[i].Id;
      }
    
    }
  }
}
