import { Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { CommonService } from 'src/app/shared/common.service';
import { User, UserView } from '../shared/user.model';
import {UserLogin} from '../shared/user.model';
import { Router,ActivatedRoute } from '@angular/router';
import { SignalrService } from 'src/app/shared/signalr.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CUSTOMCONST } from '../shared/Const';

import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { SelfRegistrationComponent } from '../feature/reg/component/self-registration.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // hide = true;
  socialUser:SocialUser
  isLoggedIn:boolean = false;
  LoginMessage:string = '';
  role: string;
  CUSTOMCONST:any=CUSTOMCONST;
  public bsModalRef:BsModalRef;
  //@Output() messageEvent = new EventEmitter<boolean>();
  constructor(public service:UserService,public commonServ: CommonService,
    private notificationSrv:NotificationService,public router:Router,private route: ActivatedRoute,private signalRServ:SignalrService,
    private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService,
    private modalService: BsModalService, private socialAuthSrv: SocialAuthService) { 
      
    }

  ngOnInit(): void {
    this.socialAuthSrv.authState.subscribe((User)=>{
      this.socialUser = User;
      console.log("this.socialUser");
      console.log(this.socialUser);
      
    });
    this.titleService.setTitle('Welcome to New ERA of Education Begins with Hybrid Learning Platform');
    this.metaService.addTags([
      {name: 'keywords', content: 'New ERA of Education, Hybrid Learning Platform, Login, Signup'},
      {name: 'description', content: 'Students, Parents, Teachers. Login with your account to access the online classroom, portfolio and a variety of assignments and assessment tools for Learning Academics, SEL, Life Skills, STEM in Hybrid Learning Platform'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/login');
    window.scroll(0,0);
    this.service.formData =  new UserView();
this.service.formData.UserInfo  = new User();
this.getschoollogo();

if(this.commonServ.isUserLoggedIn){
  if(this.role == 'Student'){
    this.router.navigateByUrl('/student/dashboard');
  }
  else if(this.role == 'Teacher'){
    this.router.navigateByUrl('/teacherdashboard');
  }
  else if(this.role == 'Parent'){
    this.router.navigateByUrl('/parent/dashboard');
  }
  else if(this.role == 'SuperAdmin'){
    this.router.navigateByUrl('/admin/dashboard');
  }
  else if(this.role == 'Admin'){
    this.router.navigateByUrl('/dashboard');
  }
  
}
else{
  this.commonServ.clearLocalStorage();
  var isauthenticate = this.route.snapshot.queryParamMap.get('authenticate') || '';
    var accessCode = this.route.snapshot.queryParamMap.get('accessCode') || '';
    if(isauthenticate == 'Y' && accessCode){
      //console.log(accessCode);
      var decodedStr = atob(accessCode);
      //console.log(decodedStr);
      if(decodedStr && decodedStr.split(":").length == 2){
        this.service.formData.UserInfo.UserLogin = decodedStr.split(":")[0];
        this.service.formData.UserInfo.Password = decodedStr.split(":")[1];
        if(this.service.formData.UserInfo.UserLogin && this.service.formData.UserInfo.Password){
          this.onLogin(undefined);
        }
      }
    }
}
  }
  onLogin(form:NgForm){
    //this.commonServ.isLogoLoaded.next(false);
this.service.login().subscribe((res:UserLogin)=>
  {
    if(res.AuthToken){
    this.resetForm(form);
    //this.toastr.success('Login successfully');
    localStorage.setItem('schoolAuth',res.AuthToken);
    localStorage.setItem('UserRole',res.Role);
    this.isLoggedIn =  true;
    this.commonServ.isUserLoggedIn = true;
    this.commonServ.schoolLogoUrl = res.LogoPath;
    this.commonServ.role = res.Role;
    if(this.commonServ.schoolLogoUrl){
    //this.commonServ.isLogoLoaded.next(true);
    }
    //this.messageEvent.emit(this.isLoggedIn);
    this.commonServ.CallOnLoggedIn();
    this.role = res.Role;
    //open the chat once user is loggedin
    if(this.commonServ.isUserLoggedIn)
      this.commonServ.OpenChat();
    if(res.Role == 'Student'){
      this.router.navigateByUrl('/student/dashboard');
    }
    else if(res.Role == 'Teacher'){
      this.router.navigateByUrl('teacher/dashboard');
    }
    else if(res.Role == 'Parent'){
      this.router.navigateByUrl('/parent/dashboard');
    }
    else if(res.Role == 'SuperAdmin'){
      this.router.navigateByUrl('/admin/dashboard');
    }
    else{
    this.router.navigateByUrl('/dashboard');
    }
    this.signalRServ.initiateSignalrConnection(res.AuthToken,res.UserId);
    this.commonServ.UserId = res.UserId;
    }else if(res.ErrorMessage){
      this.notificationSrv.ShowError(res.ErrorMessage);
    }
  },err=>{console.log(err); });
  }
resetForm(form:NgForm){
  if(form){
    form.form.reset();
  }
this.service.formData =  new UserView();
this.service.formData.UserInfo  = new User();
}
getschoollogo(){
  var guid = '';
  this.route.queryParams.subscribe(params =>{
    guid = params.Id;
  });
  if(guid){
    //this.commonServ.isLogoLoaded.next(false);
 this.service.getschoollogo(guid).subscribe((res:any)=>{
if(res && res.SchoolId > 0){
  this.commonServ.schoolLogoUrl = res.FilePath;
  this.LoginMessage = res.LoginMessage;
  if(this.commonServ.schoolLogoUrl){
    //this.commonServ.isLogoLoaded.next(true);
    }
}
 });
}
}
openSelfRegistrationModel(){
  const initialState = {
    list: [
      {id:0}
    ]      
  };
  this.bsModalRef = this.modalService.show(SelfRegistrationComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
}
signInWithGoogle():any {

  this.socialAuthSrv.signIn(GoogleLoginProvider.PROVIDER_ID);
}
}
