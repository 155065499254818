<div class="container-fluid pt-0">
  <!-- <mat-progress-spinner class="progress-spinner-group" *ngIf="commonServ.isLoading |async" mode="indeterminate"
    value='66' diameter="45"></mat-progress-spinner> -->
  <owl-carousel-o [options]="customOptions">
    
    <ng-container>
      <ng-template carouselSlide>
        <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
        <div class="slider text-content d-sm-flex align-items-center h-100 d-none">
          <h2 class="d-block text-center theam-color-4 title m-0">Helping Teachers and Parents to Help Students</h2>
          
          <p class="mt-2"></p>
          
        </div>

        
        <div class="slider images-style1 h-100">
          <div class="image1 d-xs-none">
            <img src="../../assets/images/2X/Pattern.png" alt="">
          </div>
          <div class="image2 d-flex justify-content-center justify-content-sm-end align-items-center h-100">
            <img class="d-none d-sm-block" src="../../assets/images/home_page/bg-transparent.png" alt="">
            <!-- <img  class="d-block d-sm-none w-80" src="../../assets/images/home_page/Banner_3.png" alt=""> -->
            <ng-lottie 
              [options]="bannerSecond" 
              (animationCreated)="bannerAnimationCreated($event)"
              width="100%"
              height="100%"
              class="d-flex justify-content-end w-100 pr-5" >
            </ng-lottie>
          </div>
         <div class="image2 d-flex justify-content-end align-items-center h-100">
            <div class="w-100 w-sm-60" ><img src="../../assets/GIF/Home_Page/Banner_1.gif" alt="">
            </div>
            <ng-lottie 
                                [options]="bannerFirst" 
                                (animationCreated)="bannerAnimationCreated($event)"
                                width="100%"
                                height="100%"
                                class="w-100"
                            ></ng-lottie>
          </div>
          <div class="image3 d-none d-sm-block">
            <img src="../../assets/images/2X/Icon.png" alt="">
          </div>
          <div class="image4">
            <img src="../../assets/images/2X/Pattern-3.png" alt="">
          </div>
        </div>
        
      </ng-template>
    </ng-container>
    <ng-container>
      <ng-template carouselSlide>
        <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
        <div class="slider text-content d-sm-flex align-items-center h-100 d-none">
          <h2 class="d-block text-center theam-color-4 title m-0">Facilitating Educators to Meet Every Student Needs</h2>
          <p class="mt-2"></p>
          
        </div>
        <div class="slider images-style1  w-100 h-100">
          <div class="image1 d-none d-sm-block">
            <img src="../../assets/images/2X/Pattern.png" alt="">
          </div>
          
          <div class="image2 d-flex justify-content-center justify-content-sm-end w-100 align-items-center h-100">
            <div class="w-60 w-sm-40" ><img src="../../assets/GIF/Home_Page/Banner_2.gif" alt=""></div>
            
          </div>
          <div class="image3 d-none d-sm-block">
            <img src="../../assets/images/2X/Icon.png" alt="">
          </div>
          <div class="image4">
            <img src="../../assets/images/2X/Pattern-3.png" alt="">
          </div>
        </div>
        
      </ng-template>
    </ng-container>
    <ng-container>
      <ng-template carouselSlide>
        <img src="../../assets/images/2X/Group 605.1.png" alt="" class="mx-h-100vh">
        <div class="slider text-content  d-sm-flex align-items-center h-100 d-none">
          <h2 class="d-block text-center theam-color-4 title m-0">Research & Evidence-Based Decisions for Schools</h2>
          <p class="mt-2"></p>
        </div>
        <div class="slider images-style1 h-100">
          <div class="image2 d-flex justify-content-center justify-content-sm-end align-items-center h-100">
            <img class="d-none d-sm-block" src="../../assets/images/home_page/Banner3.png" alt="">
            <img  class="d-block d-sm-none w-80" src="../../assets/images/home_page/Banner_3.png" alt="">
          </div>
          <div class="image1 d-none d-sm-block">
            <img src="../../assets/images/2X/Pattern.png" alt="">
          </div>
          <div class="image3 d-none d-sm-block">
            <img src="../../assets/images/2X/Icon.png" alt="">
          </div>
          <div class="image4">
            <img src="../../assets/images/2X/Pattern-3.png" alt="">
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<section class="bg-theam-body investing-yourself">
  <div class="container-fluid" >
    <div class="position-relative ourpartner-section">
      <div class="row justify-content-center position-relative z-inxex-1">
        <div class="col-xs-12 col-md-8 align-self-center">
          <div class="header ">
            <h2 class="d-block text-center theam-color-8 title m-0">What is Impact in HLEP EdTech ?</h2>
            <h2 class="d-block text-center theam-color-4 title m-0">How does it differ from everything else?</h2>
            <h2 class="d-block text-center text-white title">Human-Centered AI Approach</h2>
              <h2 class="d-block text-center text-white title">Systems Thinking Framework</h2>
              <h2 class="d-block text-center text-white title">Ecosystem Learning Platform</h2>
          <!-- <p>It is a <span class="theam-color-8">network of people</span> connected from all over the world, Sharing knowledge, resources, skills and tools, to <span class="theam-color-8">collaborate</span> of teach and learn through a Hybrid Learning Platform to reach <span class="theam-color-8">Every Country,</span> Every City and Every child in the <span class="theam-color-8">Next Generation</span>.</p> -->
          </div>
        </div>
        <div class="col-xs-12 col-md-4 xs-mt-20px">
          <div class="w-70 position-relative m-auto" (click)="playVideo()">
            <img src="../../assets/images/common/video_thumbnail.png" class="img img-fluid" alt="thumbnail">
            <img src="../../assets/images/common/play_icon.png" class="playicon" alt="playicon">

            <!-- <span class="playicon position-absolute" (click)="playVideo()"></span> -->
          </div>
        </div>
       
      </div>
    </div>
    
  </div>
</section> 
<section class="bg-theam-body">
  <div class="container-fluid" >
      <div class="theam-title-bg">
          <div class="row justify-content-center">
              <div class="col-10">
                  <div class="row my-4 align-items-center ">
                      <div class="col-12 px-xs-0px">
                          <div class="section-header">
                            <h1 class="d-block title pt-1 pb-1 text-center w-70 m-auto xs-w-100">
                              "Our mission is to catalyze and promote inspiring learning experiences in history in and outside the classroom."
                              </h1>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
</section>
<section>
  <div class="container-fluid bg-theam-body">
      <div class="row justify-content-center">
          <div class="col-10">
              <div class="row mt-5 align-items-center">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
                      <div class="mt-2 mb-2 ml-auto mr-auto">
                          <img src="../../assets/images/about_page/Student_with_border.png" alt="Student" class="w-100 img img-fluid">
                      </div>
                    </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
                    <div class="section-header">
                      <h2 class="d-block title pt-1 pb-1">How to Bridge the Gap from Ethics to Practice?</h2>
                    </div>
                       <p>We believe in a Human-Centered Approach to Artificial Intelligence that ensures human control over powerful and helpful tools for the future of education. 
                        As researchers, policymakers, educators, business leaders, developers, and innovators, we are committed to expanding the technology-centered scope of AI by embracing human-centered ways of thinking. 
                        By shifting from a purely algorithm-focused view to one that prioritizes human needs, we aim to shape technology to better serve the people it was designed for.</p>
                                     
                  </div>
              </div>

        <div class="row mt-5 align-items-center">

          <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
            <div class="section-header">
            <h2 class="d-block title pt-1 pb-1">Why Systems Thinking in Education Matters?</h2>
            </div>
            <p><span class="font-weight-500">See the Whole Picture:</span> Instead of focusing on individual problems, this approach allows educators, administrators, and policymakers to understand how various factors—like teaching methods, 
              student well-being, technology, and community involvement—work together.</p>
          <ul class="pl-0 custom-list-style1">
      <p><span class="font-weight-500">Foster Collaboration:</span> It encourages schools, families, and communities to collaborate, creating a shared vision for student success that goes beyond the classroom.</p>
      <p><span class="font-weight-500">Support the Whole Student:</span> A system-wide perspective considers the diverse needs of every student—academically, emotionally, and socially—ensuring a more personalized and comprehensive learning experience.</p>
      <p><span class="font-weight-500">Adapt to Complexity:</span> In today’s rapidly changing world, Systems Thinking helps schools adapt to new challenges by focusing on flexible, sustainable solutions that evolve with the needs of students and educators.</p>
                                 
          </ul>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
            <div class="mt-2 mb-2 ml-auto mr-auto">
                <img src="../../assets/images/about_page/Teacher_with_border.png" alt="Teacher" class="w-100 img img-fluid">
            </div>
          </div>
        </div>
          </div>
      </div>
  </div>
</section>


  <section class="bg-theam-body investing-yourself">
    <div class="container-fluid" >
      <div class="bg-half-color position-relative" >
        <div class="row justify-content-center position-relative z-inxex-1">
          <div class="col-12 col-md-8 align-self-center mb-3">
            <div class="header ">
                           
              <h2 class="d-block text-center text-white pt-5 pb-5 title">Four pillars of Evidence based on the latest research</h2>
              <h2 class="d-block text-center text-white mx-4"> There is an increasing number of people asking themselves: “Where do they learn in their everyday lives?”</h2>
            </div>
          </div>
          <div class="col-10">
            <div class="row ">
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-562.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">School Based Learning</span>
                     <div class="text-center">
                       <ul class="list-unstyled p-0">
                         <li>More emphasis is needed on developing 
                          a positive school climate to support the academic success of young people.
                          In order to provide an exceptional education to students, school buildings have to be purposefully built in such a way as to inspire creativity,
                           independence, and a love of learning.</li>
                       </ul>
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Path-948.png" alt="">
                </div>
                <a class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Home Based Learning</span>
                     <div class="text-center">
                      <ul class="list-unstyled p-0">
                        <li>“Skills” are centrally important for human capital development and workforce success.
                          Key “SKILLS” that foster youth workforce success: Toward a consensus across fields.</li>
                        <li>SEL Skills</li>
                        <li>Life Skills</li>
                        <li>Personalised Learning</li>
                        
                        </ul>
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-561.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Community Based Learning</span>
                     <div class="text-center">
                       <ul class="list-unstyled p-0">
                         <li>In community-based learning, educators connect the classroom with the community by using a variety of instructional methods and programs.</li>
                         <li>Project Based Learning</li>
                         <li>Outside the Box Learning</li>
                         <li>STEM Learning</li>
                         </ul>
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-4 mb-3 cart-investing">
                <div class="card-title-image">
                  <img src="../../assets/images/2X/Group-560.png" alt="">
                </div>
                <a  class="text-decoration-none">
                  <mat-card class="investing-yourself-card h-100">
                    <mat-card-content class="mb-0">
                      <span class="d-block text-center h5 title">Society Based Learning</span>
                     <div class="text-center">
                      <ul class="list-unstyled p-0">
                        <li>Education and society are closely intertwined. Values and cultural standards are taught by society. Every one of us has a strong foundation in society that makes us who we are. We grow by learning from our surroundings and experiences. Overall, society provides us with the best means of getting educated.</li>
                        
                       
                      </ul>
                     </div>
                    </mat-card-content>
                    
                  </mat-card>
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  
 
  <section class="bg-theam-body ">
    <div class="container-fluid" >
      <div class="position-relative ourpartner-section">
        <div class="row justify-content-center position-relative z-inxex-1">
          <div class="col-12 col-md-8 align-self-center">
            <div class="header ">
              <h2 class="d-block text-center text-white title">Join Our Team</h2>
                <h2 class="d-block text-center text-white title">Engage With Us.</h2>
                <h2 class="d-block text-center text-white title">Learn With Us.</h2>
                <h2 class="d-block text-center text-white title">Grow With Us.</h2>
              <h2 class="d-block text-center theam-color-8 title m-0">We're Open for Collaboration!</h2>
              <!-- <p>It is a <span class="theam-color-8">network of people</span> connected from all over the world, Sharing knowledge, resources, skills and tools, to <span class="theam-color-8">collaborate</span> of teach and learn through a Hybrid Learning Platform to reach <span class="theam-color-8">Every Country,</span> Every City and Every child in the <span class="theam-color-8">Next Generation</span>.</p> -->
            </div>
          </div>
         
        </div>
      </div>
      
    </div>
  </section> 
   <section class="bg-theam-body">
    <div class="container-fluid py-80px" >
      <div class="row m-0 justify-content-md-center">
        <div class="order-1 order-md-2 col-xs-12 col-md-6">
          <h2 class="heading-text">Collaboration with HLEP EdTech offers unique advantages for various stakeholders, including researchers, policymakers, educators, content providers, business leaders, and innovators. 
            Each group stands to gain from HLEP's all-in-one hybrid learning platform, human-centered AI approach, and systems thinking framework.</h2>
          <form novalidate #form="ngForm" (submit)="RequestForDemo(form)" >
              <div class="form-row mr-1">
                  
                  <div class="form-group full-group mb-0">
                      <input class="form-control form-control-sm" placeholder="Name" name="LastName"  #LastName="ngModel" maxlength="100"
                      [(ngModel)]="requestInfo.Name">
                  </div>
                  <div class="form-group full-group mb-0">
                      <input class="form-control form-control-sm" type="email" placeholder="Email" name="Email" #Email="ngModel" 
                      [(ngModel)]="requestInfo.Email" required maxlength="50" [class.invalid]="Email.invalid && Email.touched">
                  </div>
                  <div class="form-group full-group mb-0">
                      <input class="form-control form-control-sm" type="number" placeholder="Phone No" name="Phone" #Phone="ngModel" 
                      [(ngModel)]="requestInfo.Phone" required maxlength="10" [class.invalid]="Phone.invalid && Phone.touched">
                  </div>
              
                  <div class="form-group full-group mb-0">
                    <select class="form-control form-control-sm appearance-auto" name="JobTitle"  
                    #Role="ngModel" [(ngModel)]="requestInfo.Role" 
                      required [class.invalid]="Role.invalid && Role.touched" >
                      <option value="" >Select Role</option>
                    <option *ngFor="let role of roleList" [ngValue]="role.Name">{{role.Name}}</option>
                    </select>
                    
                    
                  </div>
                  <div class="form-group full-group mb-0">
                    <input class="form-control form-control-sm" type="text" placeholder="{{'Institute/Organisation Name'| translate}}"
                     name="Organization" #Organization="ngModel" 
                    [(ngModel)]="requestInfo.Organization" required maxlength="10" [class.invalid]="Organization.invalid && Organization.touched">
                  </div>
                  <div class="form-group full-group mb-0">
                    <select class="form-control form-control-sm appearance-auto" name="Source" #Source="ngModel" 
                    [(ngModel)]="requestInfo.Source" required [class.invalid]="Source.invalid && Source.touched">
                    <option value="" selected>How Did you Hear</option>
                    <option *ngFor="let source of sourceList" [ngValue]="source.Name">{{source.Name}}</option>
                    </select>
                  </div>
                  
                  <div class="form-group full-group mb-0">
                      <textarea class="form-control form-control-lg" placeholder="Message/Comments" 
                      name="Message" #Message="ngModel" 
                      [(ngModel)]="requestInfo.Message" required  [class.invalid]="Message.invalid && Message.touched" cols="10" rows="2"></textarea>
                  </div>
              </div>
              <div class="form-row mt-3 mr-1 justify-content-center">
                  <div class="mb-0 text-center">
                      <button class="btn btn-primary custom btn-sm bd-dadios-0" type="submit" [disabled]="form.invalid">Request For Collaboration</button>
                     <!--  <h2 class="d-block text-left theam-color-5 title">The Next Generation Learning Management System for K12 and Higher Education</h2> -->
                     
                  </div>
              </div>
          </form>
      </div>
      </div>
    </div>
   
   </section>

   <section class="bg-theam-body investing-yourself">
    <div class="container-fluid" >
      <div class="position-relative py-80px">
        <div class="row justify-content-center position-relative z-inxex-1">
          <div class="col-12 col-md-8 align-self-center">
            <div class="header ">
              <h2 class="d-block text-center theam-color-5 title">Leading Partners</h2>
                         
            </div>
            
          </div>
         
        </div>
      </div>
      
    </div>
  </section> 
   
  <section class="bg-theam-body">

    <div class="container-fluid  pb-3">
      
    <div class="ourpartner-section">
      <div class="row m-0">
        
        <div class="col-12 ">

        <owl-carousel-o class="multy-item-carousel" [options]="PartnerOptions">
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/catalyst.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/d3.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/hundred.png" alt="">
              
            </ng-template>
          </ng-container>
          <ng-container>
            <ng-template carouselSlide class="border">
              <img src="../../assets/images/partner/microsoft.png" alt="">
              
            </ng-template>
          </ng-container>
          
        </owl-carousel-o>
        </div>
        
      </div>
    </div>

    </div>
  </section>
  <section>
    <!-- <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1zandSgH420U881cFr_R0Qw7l_PgdTWM&ehbc=2E312F" width="100%" height="500"></iframe> -->
    <div id="map" style="height: 500px;"></div>
    <!-- <ul class="list-group rounded-0">
      <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('all')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP,  All Location </li>
      <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('canada')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP, Canada          www.hybridlearning.net</li>
      <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('india')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;">  <label> HLP, India    www.hybridlearning.in</label></li>
       <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('usa')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;"> HLP, USA                 www.hybridlearning.us</li>
      <li class="list-group-item cursor-pointer" (click)="loadDefaultMap('danmark')"><img src="../../assets/images/icons/localisation.png" alt="localisation.png" class="img-fluid" style="width: 24px;">  HLP, Danmark       www.hybridlearning.dk</li>
    </ul> -->
    <button (click)="loadDefaultMap('canada')">Canada</button>
    <button (click)="loadDefaultMap('india')">India</button>
    <button (click)="loadDefaultMap('danmark')">Danmark</button>
    <button (click)="loadDefaultMap('usa')">USA</button>
    <!--<a (click)="loadDefaultMap('all')" target="_blank">All</a>
    <a (click)="loadDefaultMap('canada')" target="_blank">Canada</a>
    <a (click)="loadDefaultMap('india')" target="_blank">India</a>
    <a (click)="loadDefaultMap('danmark')" target="_blank">Danmark</a>
    <a (click)="loadDefaultMap('usa')" target="_blank">USA</a>-->
  </section>
 