import { Injectable,ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
    import { InitialState } from '../store/reducer';
    import { Login, Guest } from '../store/actions';


@Injectable({ providedIn: 'root' })
export class ProfileService {
  private isLoggedIn = new BehaviorSubject(false);
  isLoggedIn$ = this.isLoggedIn.asObservable();

  constructor(private cfr: ComponentFactoryResolver, private ngRedux: NgRedux<InitialState>) {
    this.ngRedux
          .select('User')
          .subscribe((user: string) => {
            this.User = user;
          });
  }
  User: string;

  login() {
    //this.isLoggedIn.next(true);
    this.ngRedux.dispatch( Login(  "Login"));
  }

  logout() {
    //this.isLoggedIn.next(false);
    this.ngRedux.dispatch( Login(  "Guest"));
  }

  async loadComponent(vcr: ViewContainerRef, isLoggedIn: boolean) {
    const { GuestCardComponentComponent } = await import('./guest-card/guest-card.component');

    const { UserCardComponentComponent } = await import('./user-card/user-card.component');

    vcr.clear();
    let component : any = this.User == "Login" ? UserCardComponentComponent : GuestCardComponentComponent;
   
    return vcr.createComponent(
      this.cfr.resolveComponentFactory(component))    
}}