import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/common.service';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.scss']
})
export class SelfRegistrationComponent implements OnInit {
  list:any[]=[];
  genderlist:any[];
  rolelist:any[];
  datepickerConfig : BsDatepickerConfig;
  public bsModalRef:BsModalRef;

  CUSTOMCONST:any=CUSTOMCONST;


  constructor(public commServ:CommonService,public modalService: BsModalService,private notificationServ:NotificationService,) { 
    this.datepickerConfig = Object.assign({ dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: false  });
  }

  ngOnInit(): void {
    this.commServ.userFormData
    this.commServ.getuseregisterinfo().subscribe((res : any) => {
      
      this.commServ.userFormData = res;
      // this.commServ.userFormData.SchoolId = res.UserInfo.SchoolId;
      // this.commServ.userFormData.SchoolName = res.UserInfo.SchoolName;
      // this.commServ.userFormData.GenderId = res.UserInfo.GenderId;
      this.genderlist = res.MLGenderInfo;
      this.rolelist = res.MLRoleInfo;
      
  });
  }
  register(){
    // this.commServ.userFormData.IsSaved = true;
    this.commServ.saveuseregisterinfo().subscribe((res:any)=>{
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        // this.saved.emit('someData');
      this.notificationServ.ShowInfo("User saved successfully");
      // this.commServ.CallOnRefreshGrid();
      }
      // else
      // this.saved.emit('someData');
      });
  }
}
