<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
          <!-- <h3 class="modal-title h3 m-1" >{{'QuestionEdit' | translate}}</h3> -->
          <h3 class="modal-title h3 m-1" >Add Item</h3>
        </div>
        <form novalidate #form="ngForm" (submit)="addMLSubject(form.value)">
            <div class="modal-body p-2">
                <div class="row form-row m-0">
                    <!-- <div class="col-12 ">
                        <div class="form-group mb-0" >
                            <label>{{'SelectTopic' | translate}}</label>
                            <select class="form-control form-control-sm appearance-auto" name="TopicId"  >
                                <option  [ngValue]="0">Select Topic</option>
                            </select> 
                        </div>
                    </div> -->
                    <div class="col-12">
                        <div class="form-group mb-0">
                            <label>{{'Name' | translate}}</label>
                            <input class="form-control form-control-sm" type="test" placeholder="{{'Name' | translate}}" name="Name" 
                            ngModel >
                        </div>
                    </div>                   
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success"  type="submit">Save</button>
                <!-- <button type="button" class="btn btn-primary"  type="button">Next</button> -->
                <button type="button" class="btn btn-danger"  (click)="bsModalRef2.hide()" aria-label="Close">Close</button>
              </div>
        </form>
    </div>
</div>
