<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        <div class="banner-image">
          <img src="../../assets/images/2X/benefits-banner-image.png" alt="faq-banner-image.png">
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-11">Empower educators to adapt changing conditions.</h2> 
          <h1 class="w-100 text-left theam-color-4">Ecosystem in Education</h1> 
          <h4 class="w-100">The world's largest decentralized learning ecosystem is being built by us.</h4>
        </div>
      </div>
      <section class="bg-theam-body investing-yourself">
        <div class="container-fluid" >
          <div class="position-relative py-80px">
            <div class="row justify-content-center position-relative z-inxex-1">
              <div class="col-12 col-md-8 align-self-center">
                <div class="header ">

                  <h2 class="d-block text-left theam-color-5 title">What is a Ecosystem Education?</h2>
                  
                  <h2 class="theam-color-11 m-0 mb-1">The National Research Council defines a learning ecosystem as a dynamic interaction among
                    individual learners, diverse learning settings, and the communities and cultures in which
                    they reside. An understanding of learning as something that takes place at different times,
                    places, and contexts is a learning ecosystem on a theoretical level. Learning ecosystems are
                    metaphors for all the people, places, and experiences that contribute to lifelong learning. As
                    a practical matter, a learning ecosystem consists of schools and families, museums and
                    libraries, community centers and public parks, afterschool and summer programs,
                    internships and mentorships, and everyday encounters where learning takes place. With a
                    future of unprecedented complexity and novelty, it's easy to see why learning ecosystems
                    are gaining global attention. The prevailing system of education emphasizes school alone,
                    whereas a learning ecosystem sees school as one node in a complex web of learning
                    opportunities. Traditional in-school and out-of-school divisions isolate teachers, librarians,
                    mentors, and other educators from one another. Learning ecosystems connect and
                    coordinate the many caring adults who help young people succeed. Learning ecosystems
                    evolve with intention, aiming to solve problems and shift paradigms, unlike conventional
                    education ideas. As a result of these inherent qualities, learning ecosystems can transform
                    the way communities structure and support young people's learning experiences.</h2>
                  <h2 class="d-block text-left theam-color-5 title">HLEP wisdom plays an essential role in restoring the planet and saving humanity for several reasons:</h2>
                  <h2 class="d-block text-left theam-color-9 title">1. Nature's Connection:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">The HLEP are deeply spiritual and culturally connected to the land and understand that all living things are interconnected. By connecting these resources, we can effectively manage them in a sustainable and regenerative way.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">2. An Integrated Approach:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Understanding the world and recognizing that all aspects of the environment and society are interconnected. In this way, the impacts of human actions on the planet and guidelines for living in harmony with nature can be understood.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">3. A traditional practice from the past:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">HLEP have developed and refined historical knowledge and practices over hundreds of years. Sustainable communities, societies, agriculture, natural medicine, and resource management practices have been successful for centuries and could be adapted and applied to modern problems.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">4. A Community Approach:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Working together and making decisions with a strong sense of community. As a result of this community-based approach, both people and the land are taken into account.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">5. Environmentalism:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Deep respect for nature and an understanding that the planet's health is essential to all living things. Practicing this respect leads to practices that prioritize life over economic gain.</h2>
                  
                  <h2 class="d-block text-left theam-color-5 title">HLEP wisdom is crucial to saving humanity and regenerating the planet.</h2>
                  <h2 class="d-block text-left theam-color-6 title">Through HLEP, the Systems Change Journey offers a holistic, (School-based, Home-based, community-based, society-based learning) and sustainable approach to managing resources.</h2>

                  <h2 class="d-block text-left theam-color-10 title">Positive Professional Learning Ecosystems for Inner Development Goal:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">The Hybrid Learning Ecosystem Platform Framework provides new ways of working and thinking in education that prepares teachers, schools, systems and communities to thrive in the complex and connected contexts in which they work. 
                    It is a source of discovery and problem-solving that enriches learning by applying five disciplines to practice Purposeful Pedagogy, Systems Thinking, Looped Learning, Democratic Engagement and Digital Inclusion. 
                    It frames professional learning as both a collaborative and self-directed endeavor. The goal of the HLEP is to support educators and systems produce effective learning outcomes for all.</h2>
                    <h2 class="theam-color-11 m-0 mb-1">It is how we foster innovation on a grand scale in diverse settings. As the context of education becomes increasingly complex, educators need flexible approaches to teaching and learning and environments that enable them to be responsive.</h2>
                      <h2 class="theam-color-11 m-0 mb-1">The HLEP Framework helps educators navigate towards their goals; despite the challenges they face daily. The HLEP enables inclusive practices, health and well-being, teacher professional development, and more. The power of the HLEP process is its integrated practices of collaboration, Research and Evidence-based learning, community building and knowledge sharing.</h2>
                        <h2 class="theam-color-11 m-0 mb-1">Based on our work with partners and clients worldwide and in response to the multiple global disruptive forces that have erupted in 2020, we are updating the HLEP Framework. A Hybrid Learning Ecosystem Platform Toolkit will be available in world wide.</h2>
                </div>
              </div>
             </div>
          </div>
          </div>
          
        
      </section>
</div>