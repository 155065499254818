<div class="banner">
    <div class="banner-bg-image">
        <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
    </div>
    <div class="banner-image w-100">
      <img class="img-fluid w-100" src="../../assets/images/contact_page/contact_banner.png" alt="contact-banner-image">
    </div>
    <div class="banner-content-left">
        <h1>Contact Us</h1>
        <p>If you have any query feel free to reach us</p>
    </div>
</div>

<section>
    <div class="container-fluid mt-5 pb-5">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row align-items-center">
            <div class="order-2 order-md-1 col-xs-12 col-md-6 ">
                <h2 class="heading-text">All-in-One (Academics, SEL, Life Skills, Outside the box Learning through national and Global Collaboration) Learning Management System through Hybrid Learning Platform. </h2>
                <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img src="../../assets/images/contact_page/All in one.jpg" alt="value_education" class="m-0 w-100 img-fluid">
              </div>
                <!-- <address class="address">
                    <div class="contact-number mb-3">
                        <div class="d-flex">
                            <div class="icon-group">
                                <span><i class="fa fa-phone" aria-hidden="true"></i></span>
                            </div>
                            <div class="contact-content">
                                <p></p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div class="email mb-3">
                        <div class="d-flex">
                            <div class="icon-group">
                                <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            </div>
                            <div class="contact-content">
                                <p></p>
                                <p> </p>
                            </div>
                        </div>
                    </div>
                    <div class="address">
                        <div class="d-flex">
                            <div class="icon-group">
                                <span>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                </span>
                                
                            </div>
                            <div class="contact-content">
                                <p>
                                    <br>
                                    <br>
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </address> -->
            </div>
            <div class="order-1 order-md-2 col-xs-12 col-md-6">
                <h2 class="heading-text">Please fill your details for a FREE Demo</h2>
                <form novalidate #form="ngForm" (submit)="onContact(form)" >
                    <div class="form-row mr-1">
                        <div class="form-group full-group mb-0 ">
                            <input class="form-control form-control-sm" placeholder="{{'FirstName'| translate}}" name="FirstName" #FirstName="ngModel" 
                            [(ngModel)]="service.formData.FirstName" required [class.invalid]="FirstName.invalid && FirstName.touched" maxlength="100">
                        </div>
                        <div class="form-group full-group mb-0">
                            <input class="form-control form-control-sm" placeholder="{{'LastName'| translate}}" name="LastName"  #LastName="ngModel" maxlength="100"
                            [(ngModel)]="service.formData.LastName">
                        </div>
                        <div class="form-group full-group mb-0">
                            <input class="form-control form-control-sm" type="email" placeholder="{{'Email'| translate}}" name="Email" #Email="ngModel" 
                            [(ngModel)]="service.formData.Email" required maxlength="50" [class.invalid]="Email.invalid && Email.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                            <input class="form-control form-control-sm" type="number" placeholder="{{'Mobile'| translate}}" name="Mobile" #Mobile="ngModel" 
                            [(ngModel)]="service.formData.Mobile" required maxlength="10" [class.invalid]="Mobile.invalid && Mobile.touched">
                        </div>
                    
                        <div class="form-group full-group mb-0">
                          <select class="form-control form-control-sm appearance-auto" name="JobTitle"  #JobTitle="ngModel" [(ngModel)]="service.formData.JobTitle" 
                            required [class.invalid]="JobTitle.invalid && JobTitle.touched" >
                            <option value="" >Select Job Title</option>
                          <option *ngFor="let jobTitle of jobTitleList" [ngValue]="jobTitle.Name">{{jobTitle.Name}}</option>
                          </select>
                          
                          
                        </div>
                        <div class="form-group full-group mb-0">
                          <input class="form-control form-control-sm" type="text" placeholder="{{'Institute/Organisation Name'| translate}}" name="SchoolName" #SchoolName="ngModel" 
                          [(ngModel)]="service.formData.SchoolName" required maxlength="10" [class.invalid]="SchoolName.invalid && SchoolName.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                          <select class="form-control form-control-sm appearance-auto" name="SchoolType" #SchoolType="ngModel" 
                          [(ngModel)]="service.formData.SchoolType" required [class.invalid]="SchoolType.invalid && SchoolType.touched">
                          <option value="" selected>Type of Institute/Organisation</option>
                          <option *ngFor="let schoolType of schoolTypeList" [ngValue]="schoolType.Name">{{schoolType.Name}}</option>
                          </select>
                        </div>
                        <div class="form-group full-group mb-0">
                          <input class="form-control form-control-sm" type="text" placeholder="{{'City'| translate}}" name="City" #City="ngModel" 
                          [(ngModel)]="service.formData.City" required maxlength="50" [class.invalid]="City.invalid && City.touched">
                        </div>
                        <div class="form-group full-group mb-0">
                          <select class="form-control form-control-sm appearance-auto" name="Country" #Country="ngModel" 
                          [(ngModel)]="service.formData.Country" required [class.invalid]="Country.invalid && Country.touched">
                            <option value="" selected>Select Country</option>
                            <option *ngFor="let country of countryList" [ngValue]="country.Name">{{country.Name}}</option>
                          </select>
                        </div>
                        <div class="form-group full-group mb-0">
                          <select class="form-control form-control-sm appearance-auto" name="Source" #Source="ngModel" 
                          [(ngModel)]="service.formData.Source" required [class.invalid]="Source.invalid && Source.touched">
                          <option value="">How did you hear about us?</option>
                          <option *ngFor="let source of sourceList" [ngValue]="source.Name">{{source.Name}}</option>
                          </select>
                        </div>
                        <div class="form-group full-group mb-0">
                            <textarea class="form-control form-control-lg" placeholder="{{'Questions'| translate}}/{{'Comments'| translate}}" name="Description" #Description="ngModel" 
                            [(ngModel)]="service.formData.Description" required  [class.invalid]="Description.invalid && Description.touched" cols="10" rows="2"></textarea>
                        </div>
                    </div>
                    <div class="form-row mt-3 mr-1 justify-content-center">
                        <div class="mb-0">
                            <button class="btn btn-primary custom btn-sm bd-dadios-0" type="submit" [disabled]="form.invalid">{{'Register Now for Free'| translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
      </div>
        
    </div>
</section>
<section>
    <div class="container-fluid bg-theam-body">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h2 class="d-block title pt-1 pb-1">How to Collaborate to Get Free Access</h2>
              </div>
    
            <p></p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">Free live demo for Central and international schools. </li>
              <li class="mb-2 right-angle2">The demo will be held to see Hybrid Learning Platform in action.</li>
              <li class="mb-2 right-angle3">Free trial accounts for teachers, students, and school admins.</li>
              
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3  order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/2X/Group-600.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
          </div>

          <div class="row mt-5 align-items-center">
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/2X/Group-597.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h2 class="d-block title pt-1 pb-1">How Does Hybrid Learning Platform Help You Easily Manage Your School?</h2>
              </div>
    
              <p></p>
                
              <ul class="pl-0 custom-list-style1"> 
                <li class="mb-2 right-angle1">Full control of employee affairs, including attendance, leaves and more.</li>
                <li class="mb-2 right-angle2">Reduce paperwork costs and digitize all school managerial processes.</li>
                <li class="mb-2 right-angle3">Help school administrators staff to easily follow up on teacher's performance.</li>
                <li class="mb-2 right-angle4">Easily create Assignments and Assessments, and Daily homeworks, and send them to students.</li>
                <li class="mb-2 right-angle5">Motivating students using cutting-edge techniques through Points and reward-based assignments.</li>
                <li class="mb-2 right-angle1">Parents can easily follow up on their children at any time via application application.</li>
              </ul>
            </div>
            
          </div>

          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h2 class="d-block title pt-1 pb-1">Why Hybrid Learning Platform Is the Ideal System to Manage Your School for teaching and learning?</h2>
              </div>
              <p><span class="font-weight-500"></span>  </p>    
            <p></p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">Contains 3 systems (Learning Management + Students and Staff affairs Management + Calendar Management). </li>
              <li class="mb-2 right-angle2">User-friendly interfaces for school management staff, Teachers, Students, and Parents.</li>
              <li class="mb-2 right-angle3">Virtual classrooms and the latest teaching methods to keep up with the digital transformation of education. </li>
              <li class="mb-2 right-angle4">Powerful student portfolio, deep results analysis, and detailed reports to measure student's performance.</li>
              <li class="mb-2 right-angle5">Native web/mobile app for teachers, students, and parents to facilitate using Hybrid Learning Platform on desktops, phones and tablets.</li>
              <li class="mb-2 right-angle1">Enhances communication among all parties of the learning process at school, anytime and anywhere.</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/2X/Group-598.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
          </div>

          <!-- <div class="row mt-5 align-items-center">
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/2X/Group-599.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3">
              <div class="section-header">
                <h2 class="d-block title pt-1 pb-1">ASSIGNMENTS and ASSESSMENTS</h2>
              </div>
    
              <p><span class="font-weight-500">Greater Flexibility, Happier Students</span>  </p>

              <li class="mb-2 right-angle1">Easily assign homework online and set custom due dates to accommodate the needs of individual learners.</li>
              <li class="mb-2 right-angle2">Allow students multiple attempts when resonding to assignments questions, or give them immediate eedback by making the correct answer visible so they can learn as they go.</li>
              <li class="mb-2 right-angle3">Send customised and individual feedback to guide the students.</li>
              <p><span class="font-weight-500">Get a real-time view of student performance</span> </p>
                
              <ul class="pl-0 custom-list-style1"> 
                <li class="mb-2 right-angle1">Let notifications and insights give you an immediate pulse on student completion and comprehension</li>
                <li class="mb-2 right-angle2">Receive a weekly smmmary repor to assess class and individual progress and pinpoint struggling students.</li>
                <li class="mb-2 right-angle3">View all assignment score in the application automatically</li>
              </ul>
            </div>
            
          </div>

          <div class="row mt-5 align-items-center">
        
            <div class="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-3 order-2 order-md-1">
              <div class="section-header">
                <h2 class="d-block title pt-1 pb-1">PLANNING & MANAGEMENT SYSTEM</h2>
              </div>
    
            <p>Hybrid Learning Platform, with its Planning and Management feature, offers school administrators a foresight of the future of the institution through a comprehensive evaluation of all systems and thus enables them to create a strategical plan for anall inclusive growth within and beyond the school. It orients the school to a common goal and increases the quality of administration.</p>
              
            <ul class="pl-0 custom-list-style1"> 
              <li class="mb-2 right-angle1">Insightful planning</li>
              <li class="mb-2 right-angle2">Single Point Access</li>
              <li class="mb-2 right-angle3">Time Saving Data Integrity</li>
              <li class="mb-2 right-angle4">Data Integrity</li>
            </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-3 order-1 order-md-2">
              <div class="mt-2 mb-2 ml-auto mr-auto">
                  <img mat-card-image src="../../assets/images/2X/Group-600.png" alt="value_education" class="m-0 w-100">
              </div>
            </div>
          </div> -->

        </div>
      </div>
      
    </div>
  </section>



<!-- <div class="d-flex align-items-center">
   
    <div class="container-fluid">
        <div class="row justify-content-c mt-3 mb-3">
            <div class="col-xs-12 col-sm-8 col-md-5 bg-light p-4 ">
                <h2 class="display-4 text-center">{{'Contact'| translate}}</h2>
                
            </div>
        </div>
    </div>
 </div>
  -->
  