import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ProfileHostDirective } from './profile-host.directive';
import { ProfileService } from './profile.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
    import { InitialState } from '../store/reducer';

@Component({
  selector: 'app-profile-container',
  template: `
    <ng-template appProfileHost></ng-template>
  `
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild(ProfileHostDirective, { static: true })
  profileHost: ProfileHostDirective;
  private destroySubject = new Subject();

  constructor(private profileService: ProfileService, private ngRedux: NgRedux<InitialState>) {
      
}
User: string;

  ngOnInit() {
    const viewContainerRef = this.profileHost.viewContainerRef;
    this.ngRedux
    .select('User')
    .subscribe((user: string) => {
      this.User = user;
      this.profileService.loadComponent(viewContainerRef, this.User == "Login");
    });
    
  }

  ngOnDestroy() {
    this.destroySubject.next(true);
    this.destroySubject.complete();
  }
}