import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../shared/canonical.service';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import contact from "../schoolConst"

@Component({
  selector: 'app-social-impact',
  templateUrl: './social-impact.component.html',
  styleUrls: ['./social-impact.component.scss']
})
export class SocialImpactComponent implements OnInit {
  constData=contact;
  winWidth:number=0;
  winHeight:number=0;
  youtubeList:any[]=[];
  done:boolean=false;

  activeSlides: SlidesOutputData;

  slidesStore: any[];
playList:any[]=[];
  constructor(private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService) {
    
   }

  ngOnInit(): void {
    
    this.titleService.setTitle('Social Impact for Education - Hybrid Learning Ecosystem Platform.');
    this.metaService.addTags([
      {name: 'keywords', content: 'Social Impact, Ecosystem, System thinking for education, Student needs, Teachers can help, '},
      {name: 'description', content: 'Unlocking global potential through accessible education. Join us in reshaping the future of learning and empowering learners for meaningful employment.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/social-impact');

    let innerWinWidth = window.innerWidth < 768? (window.innerWidth - 50 ):(window.innerWidth - 250 )
   this.winWidth = innerWinWidth;
   this.winHeight = innerWinWidth < 768?innerWinWidth / 1.6:innerWinWidth / 2.5;

  this.youtubeList =  this.constData.contact.socialImpactYoutube;
  console.log(this.PartnerOptions);
  
  }
  

  PartnerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:false,
    margin:10,
autoplayTimeout:5000,
autoplayHoverPause:true,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items:1
      }
    },
    nav: true,
   
  };
  


     

      getPassedData(data: SlidesOutputData) {
        this.activeSlides = data;
        this.pauseVideo(data.startPosition);
        
      }


      
     public id = 'V462IsOV3js';
  playerVars = {
    cc_lang_pref: 'en',
  };
  public player:any;
  public ytEvent:any;

  onStateChange(event:any) {
    this.ytEvent = event.data;
  }
  savePlayer(player:any) {
    this.player = player;
    // setTimeout(()=>{
    //   if(this.playList.length){
    //     this.playList.forEach((item)=>{
    //       if(item.videoId !== player.target.videoId){
    //         this.playList.push(player.target)
    //       }
          
    //     })
    //   }else{
    //     this.playList.push(player.target)
    //   }
    // },3000);
    
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo(startPosition:number) {
    // this.playList[startPosition-1].pauseVideo()
    // this.player.target.pauseVideo(this.youtubeList[startPosition-1].VideoId);
    this.player.target.pauseVideo();
  }
  
}
