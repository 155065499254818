<section>
                    
    <div class="d-flex justify-content-center">
        <!-- <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group1 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/calendar/">
                        <div class="text-center text-black">   
                            <div class="icon">
                                <fa-icon [icon]="calendarIcon"></fa-icon>
                            </div>
                            <div class="content">
                                <p>Calendar</p>
                            </div>
                        </div>
                    </a>
                    
                </div>
                
            </mat-card>
        </div> -->
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group2 shadow">
                <div class="grouup-body">
                    <div class="text-center">   
                        <div class="icon">
                            <i class="fa fa-user-times" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                            <p>Attendance</p>
                        </div>
                    </div>
                </div>
                
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group3 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/homework/">
                        <div class="text-center text-black">   
                            <div class="icon">
                                <i class="fa fa-fw fa-book"></i>
                            </div>
                            <div class="content">
                                <p>Home Work</p>
                            </div>
                        </div>
                    </a>
                </div>
                
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group4 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/result/">
                        <div class="text-center text-black">   
                            <div class="icon">
                                <i class="fa fa-fw fa-signal"></i>
                            </div>
                            <div class="content">
                                <p >Analytics</p>
                            </div>
                        </div>
                    </a>
                </div>
                
            </mat-card>
        </div>
       
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group5 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/finance/">
                        <div class="text-center text-black">   
                            <div class="icon">
                                <fa-icon [icon]="financeIcon"></fa-icon>
                            </div>
                            <div class="content">
                                <p>Payments</p>
                            </div>
                        </div>
                        
                    </a>
                    </div>
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group6 shadow">
                <div class="grouup-body">
                    <div class="text-center">   
                        <div class="icon">
                            <fa-icon [icon]="folderIcon"></fa-icon>
                        </div>
                        <div class="content">
                            <p>Folder</p>
                        </div>
                    </div>
                </div>
                
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group7 shadow">
                <div class="grouup-body">
                    <div class="text-center">   
                        <div class="icon">
                            <fa-icon [icon]="messageIcon"></fa-icon>
                        </div>
                        <div class="content">
                            <p>Chat</p>
                        </div>
                    </div>
                </div>
                
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group8 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/referral/">
                        <div class="text-center text-black"> 
                       
                            <div class="icon">
                                <i class="fa fa-fw fa-info-circle"></i>
                            </div>
                            <div class="content">
                                <p class="">Referral</p>
                            </div>
                        
                        </div>
                    </a>
                   
                </div>
                
            </mat-card>
        </div>
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group9 shadow">
                <div class="grouup-body">
                    <a routerLink="/parent/add-child/">
                        <div class="text-center text-black"> 
                       
                            <div class="icon">
                                <fa-icon [icon]="editIcon"></fa-icon>
                            </div>
                            <div class="content">
                                <p>Registration</p>
                            </div>
                        
                        </div>
                    </a> 
                </div>
                
            </mat-card>
        </div>
    </div>
    </section>