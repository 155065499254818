import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-ecosystem-platform',
  templateUrl: './ecosystem-platform.component.html',
  styleUrls: ['./ecosystem-platform.component.scss']
})
export class EcosystemPlatformComponent implements OnInit {
  constructor(private actRoute: ActivatedRoute,
    private titleService: Title, private metaService: Meta,private canonicalService: CanonicalService){}
  ngOnInit(): void {
    this.titleService.setTitle('Community Based Ecosystem Learning | Hybrid Learning Platform');
    this.metaService.addTags([
      {name: 'keywords', content: 'Community Based ecosystem learning, Society Based ecosystem learning, Hybrid Learning Ecosystem Platform, Hybrid Learning Ecosystem, Ecosystem LMS, Ecosystem EdTech, Evidence Based Learning Ecosystem Platform, Home Based ecosystem learning, School Based ecosystem learning, Learning Ecosystem for Higher Education'},
      {name: 'description', content: 'Immerse in collaborative learning with our hybrid platform. Join a community-based ecosystem for diverse, interactive education, fostering growth and engagement.'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.canonicalService.setCanonicalURL('https://hybridlearning.net/ecosystem-platform');
    window.scroll(0,0);
  }
}
