<div class="user-form d-flex align-items-center">
    <div class="container-fluid">
      <div class="row justify-content-c m-0">
        <div class="col-md-12 p-0">
          <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">
  
  
  
            <div class="modal-content">
              <div class="modal-header bg-theam p-1">
                <h3 class="modal-title h3 m-1">Registration</h3>
              </div>
              <div class="modal-body p-2">
                <form novalidate #form="ngForm" (submit)="onSave(form)" autocomplete="off">
                  <div class="row form-row m-0">
                    <!-- <div class="form-group col-12 p-0 pr-1 mb-2 p-0 pr-1 mb-0 border border-secondary">
                      <div class="row form-row m-0 pt-0">
                          
                        <div class="form-group col-4 mb-0">
                          <label>Selected Child</label>
                          <select class="form-control form-control-sm appearance-auto" name="selectedChild" [(ngModel)]="selectedChileId" (change)="changeChild()">
                            <option *ngFor="let child of childrenList" [ngValue]="child.Id">{{child.Name}}</option>
                            <option [ngValue]="0">Link a child to my account</option>
                          </select>
                        </div>
                        <div class="form-group col-4 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                          <label>User Share Id</label>
                          <input class="form-control form-control-sm" placeholder="User Share Id" name="userShareId"  [(ngModel)]="userShareId" />
                        </div>
                        <div class="form-group col-4 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                          
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>&nbsp;</label>
                          <div class="form-control form-control-sm border-0 p-0">
                              <button type="button" (click)="searchUserWithSharedId()" class="btn btn-warning float-right btn-sm" type="button">Search</button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="form-group col-8 p-0 pr-1 mb-0 border border-secondary ">
                     
                      <div class="row form-row m-0 pt-2">
                        <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                          <label>{{'UserId' | translate}}</label>
                          <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}" name="UserLogin" 
                          #UserLogin="ngModel" 
                          [(ngModel)]="service.formData.UserInfo.UserLogin" [readonly]="service.formData.UserInfo.UserId > 0">
                        </div>
                        <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                          <label>{{'Password' | translate}}</label>
                          <input class="form-control form-control-sm" type="password" placeholder="********" name="Password" #Password="ngModel" 
                          [(ngModel)]="service.formData.UserInfo.Password" required [class.invalid]="Password.invalid && Password.touched">
                        </div>
                        <div class="form-group col-6 mb-0">
                          <label>School Organization</label>
                          <select class="form-control form-control-sm appearance-auto" name="SchoolId" #SchoolId="ngModel"
                            [(ngModel)]="service.formData.UserInfo.SchoolId" (change)="schoolChange()">
                            <option [ngValue]="0">Select School Organization</option>
                            <option *ngFor="let school of schoollist" [ngValue]="school.Id">{{school.Name}}</option>
                          </select>
                        </div>
                        <div class="form-group col-6 mb-0">
                          <label>{{'EnrollmentId' | translate}}</label>
                          <input class="form-control form-control-sm" type="text"
                            placeholder="{{'EnrollmentId' | translate}}" name="EnrollmentId" #EnrollmentId="ngModel"
                            [(ngModel)]="service.formData.UserInfo.EnrollmentId" required minlength="8"
                            [class.invalid]="EnrollmentId.invalid && EnrollmentId.touched">
                        </div>
                        <div class="form-group col-6 mb-0">
                          <label>&nbsp;</label>
                          <mat-card class="p-0 border-0 mb-3">
                            <mat-card-content>
                              <section class="example-section">
                                <mat-checkbox class="example-margin" name="IsAttendPrivateTution"
                                  #IsAttendPrivateTution="ngModel"
                                  [(ngModel)]="service.formData.StudentInfo.IsAttendPrivateTution">I attends a private
                                  institution</mat-checkbox>
                              </section>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <!-- <div class="form-group col-4 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                          <label class="d-block">User Share Id - {{service.formData.UserInfo.UserShareId}}</label>
                          <mat-checkbox class="example-margin h-30px lh-30px"  name="IsUserShare" #IsUserShare="ngModel" 
                          [(ngModel)]="service.formData.UserInfo.IsUserShare" >Is User Share</mat-checkbox>
                        </div> -->
                        <div class="form-group col-6 mb-0" *ngIf="service.formData.StudentInfo.IsAttendPrivateTution">
                          <label>Institute Name</label>
                          <input class="form-control form-control-sm" type="text" placeholder="Institute Name"
                            name="InstituteName" #InstituteName="ngModel"
                            [(ngModel)]="service.formData.StudentInfo.InstituteName" required
                            [class.invalid]="InstituteName.invalid && InstituteName.touched">
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-4 mb-0 p-0 pl-1">
                      <div class="row form-row m-0 border border-secondary h-100 pt-2 position-relative">
                        <div class="cover-image position-absolute">
                          <img src="{{service.formData.UserInfo.ProfileFileInfo.CroppedContentString}}" alt=""
                            class="w-100 img-fluid"
                            *ngIf="service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                          <img [src]="service.formData.UserInfo.CroppedProfileFilepath" alt="" class="w-100 img-fluid"
                            *ngIf="!service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                        </div>
                        <div class="form-group col-12 text-center">
  
                          <a class="addImageIcon cursor-pointer" (click)="editCoverImage()">
                            <mat-icon>add_a_photo</mat-icon>
                          </a>
                          <img src="{{service.formData.UserInfo.FileInfo.CroppedContentString}}" alt="" height="110"
                            width="110" class="img-circle border border-w-5 shadow-08" (click)="editPic()"
                            *ngIf="service.formData.UserInfo.FileInfo.CroppedContentString">
                          <img [src]="service.formData.UserInfo.CroppedFilepath" alt="" height="110" width="110"
                            class="border border-w-5 img-circle shadow-08" (click)="editPic()"
                            *ngIf="!service.formData.UserInfo.FileInfo.CroppedContentString">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-row m-0 mb-2">
                    <div class="form-group col-12 p-0 mb-0">
                      <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
  
                        <!-- <div class="form-group col-4 mb-0">
                  <label>School Organization</label>
                  <select class="form-control form-control-sm appearance-auto" name="SchoolId" #SchoolId="ngModel" 
                  [(ngModel)]="service.formData.UserInfo.SchoolId" (change)="schoolChange()">
                    <option [ngValue]="0">Select School Organization</option>
                    <option *ngFor="let school of schoollist" [ngValue]="school.Id">{{school.Name}}</option>
                  </select>
                </div> -->
                        <div class="form-group col-4 mb-0">
                          <label>First Name</label>
                          <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}"
                            name="FirstName" #FirstName="ngModel" [(ngModel)]="service.formData.UserInfo.FirstName"
                            required [class.invalid]="FirstName.invalid && FirstName.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>Mid Name</label>
                          <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}"
                            name="MiddleName" #MiddleName="ngModel" [(ngModel)]="service.formData.UserInfo.MiddleName"
                            required [class.invalid]="MiddleName.invalid && MiddleName.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>Last Name</label>
                          <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}"
                            name="Surname" #Surname="ngModel" [(ngModel)]="service.formData.UserInfo.Surname" required
                            [class.invalid]="Surname.invalid && Surname.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>Gender</label>
                          <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel"
                            [(ngModel)]="service.formData.UserInfo.GenderId">
                            <option [ngValue]="0">Select Gender</option>
                            <option *ngFor="let gender of genderlist" [ngValue]="gender.Id">{{gender.Name}}</option>
                          </select>
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>Date Of Birth</label>
                          <input type="text" class="form-control form-control-sm"
                            placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel"
                            [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig"
                            bsDatepicker>
                        </div>
  
                        <div class="form-group col-4 mb-0">
                          <label>Email</label>
                          <input class="form-control form-control-sm" type="email" placeholder="{{'Email' | translate}}"
                            name="Email" #Email="ngModel" [readonly]="service.formData.UserInfo.UserId > 0" aria-readonly="true" [(ngModel)]="service.formData.UserInfo.Email" required
                            [class.invalid]="Email.invalid && Email.touched">
                            
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>{{'Mobile' | translate}}</label>
                          <input class="form-control form-control-sm appearance-none" type="number"
                            placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel"
                            [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8"
                            [class.invalid]="Mobile.invalid && Mobile.touched">
                        </div>
                        <div class="form-group col-8 mb-0">
                          <label>{{'Address' | translate}}</label>
                          <input class="form-control form-control-sm" type="text" placeholder="{{'Address' | translate}}"
                            name="Address" #Address="ngModel" [(ngModel)]="service.formData.UserInfo.Address" required
                            [class.invalid]="Address.invalid && Address.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>{{'Street' | translate}}</label>
                          <input class="form-control form-control-sm" type="text" placeholder="{{'Street' | translate}}"
                            name="Street" #Street="ngModel" [(ngModel)]="service.formData.UserInfo.Street">
                        </div>
  
                        <div class="form-group col-4 mb-0">
                          <label>{{'PostalNo' | translate}}</label>
                          <input class="form-control form-control-sm" type="text" placeholder="{{'PostalNo' | translate}}"
                            name="PostalNo" #PostalNo="ngModel" [(ngModel)]="service.formData.UserInfo.PostalNo" required
                            [class.invalid]="PostalNo.invalid && PostalNo.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>{{'City' | translate}}</label>
                          <input class="form-control form-control-sm" type="text" placeholder="{{'City' | translate}}"
                            name="City" #City="ngModel" [(ngModel)]="service.formData.UserInfo.City" required
                            [class.invalid]="City.invalid && City.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>{{'State' | translate}}</label>
                          <input class="form-control form-control-sm" type="text" placeholder="{{'State' | translate}}"
                            name="State" #State="ngModel" [(ngModel)]="service.formData.UserInfo.State" required
                            [class.invalid]="State.invalid && State.touched">
                        </div>
                        <div class="form-group col-4 mb-0">
                          <label>Country</label>
                          <input class="form-control form-control-sm" type="text" placeholder="Country"
                            name="Country" #Country="ngModel" [(ngModel)]="service.formData.StudentInfo.Country" required
                            [class.invalid]="Country.invalid && Country.touched">
                        </div>
                      </div>
  
  
                    </div>
                  </div>
  
                  <div class="row form-row m-0 mb-2">
                    <div class="form-group col-12 p-0 mb-0">
                      <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                        <div class="form-group col-3 mb-0">
                          <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                            [(ngModel)]="service.formData.StudentInfo.IsHumanityLife">{{'HumanityLife' | translate}}
                          </mat-checkbox>
  
                        </div>
                        <div class="form-group col-3 mb-0">
                          <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                            [(ngModel)]="service.formData.StudentInfo.IsSkills">{{'Skills' | translate}}</mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                          <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                            [(ngModel)]="service.formData.StudentInfo.IsAcademics">{{'Academics' | translate}}
                          </mat-checkbox>
                        </div>
                        <div class="form-group col-3 mb-0">
                          <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                            [(ngModel)]="service.formData.StudentInfo.IsLearnOutOfBox">{{'LearnOutOfBox' | translate}}
                          </mat-checkbox>
                        </div>
  
                      </div>
  
                    </div>
                  </div>
  
                  <div class="row form-row m-0 mb-2">
                    <div class="form-group col-12 p-0 mb-0">
                      <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                        <div class="form-group col-12 mb-0">
                          <label class="d-block">About Child <span
                              class="d-inline-block float-right">{{currentLength}}/{{maxLength}}</span></label>
                          <textarea class="form-control form-control-sm appearance-auto resizebele-false m-0" name="About"
                            #About="ngModel" [(ngModel)]="service.formData.UserInfo.About" rows="5" maxlength="2000"
                            (keyup)="lenghtCounter()"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-row m-0 mt-0">
                    <div class="form-group col-12 p-0 mb-0 mt-2">
                      <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2Ø">
                        <div class="form-group col-12 mb-2">
                          <p class="mb-1">What motivated you to become an Hybrid Learning Platform member?</p>
                          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="Motivation"
                            [(ngModel)]="service.formData.StudentInfo.Motivation">
                            <mat-radio-button class="example-radio-button mb-2 d-block"
                              *ngFor="let motivation of motivatedList" [value]="motivation.Name">
                              {{motivation.Name}}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <div class="form-group col-12 mb-0">
                          <p class="mb-1">How did you hear about Hybrid Learning Platform?</p>
                          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="Source"
                            [(ngModel)]="service.formData.StudentInfo.Source">
                            <mat-radio-button class="example-radio-button mb-2 d-block" *ngFor="let source of sourceList"
                              [value]="source.Name">
                              {{source.Name}}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </form>
              </div>
              <div class="modal-footer p-1">
                <!-- <button type="button" class="btn btn-warning" (click)="searchUserWithSharedId()" *ngIf="service.formData.UserInfo.UserId <= 0">Search</button> -->
                <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>
                
              </div>
            </div>
  
          </div>
  
        </div>
      </div>
    </div>
  
  </div>