<div class="sms-auth-form d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-c">
            <div class="col-xs-12 col-sm-8 col-md-5 bg-light p-4 ">
                <h1 class="display-4 text-center">{{'smsauth'| translate}}</h1>
                <form novalidate #form="ngForm" (submit)="onVerificationAuthPin(form)">
                    <div class="form-row">
                        <div class="form-group full-group mb-0">
                            <label>{{'Logpin'| translate}}</label>
                            <input class="form-control form-control-lg" placeholder="{{'Logpin'| translate}}" name="userlogin" #UserLogin="ngModel" 
                            [(ngModel)]="service.formData.UserInfo.UserLogin" required minlength="8" [class.invalid]="UserLogin.invalid && UserLogin.touched">
                        </div>
                        
                    </div>
                    <div class="form-row mt-3">
                        <div class="form-group full-group mb-0">
                            <button class="btn btn-primary btn-lg btn-block form-control bd-dadios-0" type="submit" [disabled]="form.invalid">{{'Verify'| translate}}</button>
                        </div>
                    </div>       
                </form>
            </div>
        </div>
    </div>
 </div>
 