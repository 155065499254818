import { ArrayType } from "@angular/compiler";

export class Analysis {
    AnalysisMasterId:number;
    Name:string;
    ClassId:number;
    AcademicYearId:number;
    IsActive: boolean;
    questionList: Array<AnalysisQuestion>
}
export class AnalysisQuestion {
    AnalysisQuestionId:number;
    AnalysisMasterId:number;
    Question:string;
    CategoryId:number;
    Score:number;
    IsActive: boolean;
    OrderNo:number;
    MaxScore:number;
}
export class StudentAnalysisData
{
    StudentAnalysisId : number;
    AnalysisQuestionId: number;
    AnalysisMasterId: number;
    Question: string;
    CategoryId: number;
    Score: number
    OrderNo: number;
    IsActive: boolean;
    }
    export class StudentsAnalysisData 
    {
    StudentId: number;
    StuAnalysisQuestionListInfo : Array<StudentAnalysisData>;
    IsSaved: boolean;
    Message : string
    }
export class AnalysisView {
    AnalysisMasterInfo:Analysis;
    AnalysisQuestionListInfo:Array<AnalysisQuestion>;
    CategoryList:Array<[]>;
    ClassList:Array<[]>;
    StudentsAnalysisData:StudentsAnalysisData;
    StudentAnalysisData:StudentAnalysisData;

}
