import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { User ,UserView} from '../shared/user.model';

@Component({
  selector: 'app-sms-authenticate',
  templateUrl: './sms-authenticate.component.html',
  styleUrls: ['./sms-authenticate.component.scss']
})
export class SmsAuthenticateComponent implements OnInit {

  constructor(public service:UserService,
    private toastr:ToastrService) { }

  ngOnInit(): void {
    this.service.formData =  new UserView();
    this.service.formData.UserInfo  = new User();
  }
  onVerificationAuthPin(form:NgForm){
this.service.saveUser().subscribe(res=>
  {
    this.resetForm(form);
    this.toastr.success('Login successfully');
  },err=>{console.log(err); });
  }
resetForm(form:NgForm){
form.form.reset();
// this.service.formData =  new User();
this.service.formData =  new UserView();
this.service.formData.UserInfo  = new User();
}

}
