<section>
                    
    <div class="d-flex justify-content-center">
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group1 shadow">
                <div class="grouup-body">
                    <a routerLink="/student/dashboard/">
                        <div class="text-center text-black">   
                            <div class="icon">
                                <fa-icon [icon]="dashboardIcon"></fa-icon>
                            </div>
                            <div class="content">
                                <p>Dashboard</p>
                            </div>
                        </div>
                    </a>
                </div>
                
            </mat-card>
        </div>

       
        <div class="mb-3 p-1 small-group-item">
            <mat-card class="dasbord-info-card p-0 pt-2 bg-group9 shadow">
                <div class="grouup-body">
                    <a routerLink="/student/registration/">
                        <div class="text-center text-black"> 
                       
                            <div class="icon">
                                <fa-icon [icon]="editIcon"></fa-icon>
                            </div>
                            <div class="content">
                                <p>Registration</p>
                            </div>
                        
                        </div>
                    </a> 
                </div>
                
            </mat-card>
        </div>
    </div>
    </section>