import { Component, OnInit,EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef  } from "ngx-bootstrap/modal";
import { UserService } from 'src/app/shared/user.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { EditpicComponent } from './editpic.component';
import { CUSTOMCONST } from 'src/app/shared/Const';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserLogInfoComponent } from './user-log-info.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { CommonService } from 'src/app/shared/common.service';
import { User, UserView } from 'src/app/shared/user.model';
import { AddmlinfoComponent } from 'src/app/shared/component/question/addmlinfo/addmlinfo.component';
import { PasswordResetComponent } from 'src/app/login/password-reset.component';
import { UserAcademicYearLogComponent } from './user-academic-year-log.component';
import { StuOfferComponent } from '../../offer/component/stu-offer.component';


//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
//import {  IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.scss']
})
export class UsereditComponent implements OnInit {
  gridApi;
  timepickerVisible = false;
  public time: Date = new Date();
  public mytime: string;
  maxLength:number;
  currentLength:number;
  gridColumnApi;
  datepickerConfig : BsDatepickerConfig;
  public event: EventEmitter<any> = new EventEmitter();
  public saved: EventEmitter<any> = new EventEmitter();
  CUSTOMCONST : any;
  dropdownSettings : IDropdownSettings;
  selectedItems = [];
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  childList:any =  [];
  yearList:any;
  genderlist : any = [];
  branchlist : any = [];
  rolelist : any = [];
  schoollist : any = [];
  list: any[] = [];
  classList:any[] = [];
  batchList:any[] = [];
  role: string;

  constructor(public service:UserService,public bModalRef: BsModalRef, public modalService:BsModalService,
    private notificationServ:NotificationService,private commonService:CommonService) {
      this.datepickerConfig = Object.assign( { dateInputFormat:'DD-MM-YYYY', showWeekNumbers:false,showTodayButton: false  });
    this.CUSTOMCONST = CUSTOMCONST;
    this.service.formData = new UserView();
    this.getUserInfo(this.modalService.config.initialState.list[0].id);
    this.commonService.RefreshItemList$.subscribe(
      item => {        
        this.service.getUserInfo(this.modalService.config.initialState.list[0].id).subscribe((res: any) => {
    this.classList = res.ClassList;
    this.batchList = res.BatchList;
    this.yearList = res.AcademicYearList;
    this.service.formData.StudentInfo.BatchId = 0;
    this.service.formData.StudentInfo.ClassId = 0;
    // this.service.formData.UserInfo = 
    console.log(res);
    
    // this.service.formData.TeacherInfo.IsHumanityLife;
        });
       }
    );
   }
   ngOnChanges(){
    this.getUserInfo(this.list[0].id);
   }
    //formData : User;
    // rowData : any;

  
  // formData:User.studentData = StudentData
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
    this.getUserInfo(this.list[0].id);
//     this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
//       this.service.formData =  res;       
// this.genderlist = res.GenderList;
// this.branchlist = res.BranchList;
// this.rolelist = res.RoleList;
// this.schoollist = res.SchoolList;
// this.role = this.service.formData.UserInfo.Role; 
// this.classList = res.ClassList;
// this.batchList = res.BatchList;
// this.yearList = res.AcademicYearList;
// this.maxLength = 2000;
// this.currentLength = 0;
// // disabled:Boolean;
// //this.mytime = new Date();
//       // this.rowData =    res.UserInfo;
//     });
    
  }
  getUserInfo(id){
    this.service.getUserInfo(id).subscribe((res: any) => {
      this.service.formData =  res;       
      this.genderlist = res.GenderList;
      this.branchlist = res.BranchList;
      this.rolelist = res.RoleList;
      this.schoollist = res.SchoolList;
      this.role = this.service.formData.UserInfo.Role; 
      this.classList = res.ClassList;
      this.batchList = res.BatchList;
      this.yearList = res.AcademicYearList;
      this.maxLength = 2000;
      this.currentLength = 0;
      console.log(res);
      
    });
  }
  onItemSelect(item: any,type:string) {
    if(type == 'Class' && this.service.formData.ClassMappingList){
      
    var ids = item.Id;
    for(var i=0;i<this.service.formData.ClassMappingList.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+this.service.formData.ClassMappingList[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
        }
        else{
          this.service.getbatches(item.Id).subscribe((res:any)=>{
            this.batchList = res;
                  });
        }
       
      }
  onSelectAll(items: any,type:string) {
    if(type == 'Class'){
      
    var ids = '';
    for(var i=0;i<items.length;i++){
      if(ids){
        ids = ids+',';
      }
      ids = ids+items[i].Id;
    }
    if(ids){
    this.service.getbatches(ids).subscribe((res:any)=>{
      this.batchList = res;
            });
          } else{
            this.batchList =[];
          }
          }
        }
  schoolChange(){
    if(this.service.formData.UserInfo.SchoolId > 0){
      this.branchlist = [];
      this.childList =  [];
      this.service.getBranchList(this.service.formData.UserInfo.SchoolId).subscribe((res:any)=>{
        this.branchlist = res;
      });
      this.service.getBasicStudentList(this.service.formData.UserInfo.SchoolId).subscribe((res: any) => {
        this.childList = res;
      });
    }
    else{
      this.branchlist = [];
      this.childList =  [];
    }
  }

  getTime(time){
    return "s";
  }
  onSave(form:NgForm){
    this.service.formData.UserInfo.IsSaved = false;
    if(this.service.formData.UserInfo.UserId > 0){
      this.service.saveUser().subscribe((res:User)=>{
      if(res.ErrorMessage){
        this.notificationServ.ShowError(res.ErrorMessage);
      }
      else if(res.IsSaved){
        this.saved.emit('someData');
      this.notificationServ.ShowInfo("User saved successfully");
      this.commonService.CallOnRefreshGrid();
      }
      else
      this.saved.emit('someData');
      });
    }
    else{
      this.service.formData.UserInfo.IsActive = true;
      this.saved.emit('someData');
      this.service.saveUser().subscribe((res:User)=>{
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
        this.notificationServ.ShowInfo("User saved successfully");
        this.commonService.CallOnRefreshGrid();
        this.bModalRef.hide();
        }
        });
    }
  }
 
  openbranchmodal(data:string) {
    const initialState = {
      list: [
        {editImageOf:data}
      ]
    };
    this.bModalRef = this.modalService.show(EditpicComponent,{initialState,class: 'modal-xl box-shadow',ignoreBackdropClick: true});
    this.bModalRef.content.event.subscribe(res => {
       if(data === "profilePic")
      {
        this.service.formData.UserInfo.FileInfo = res.data;
      }
      else if(data === "coverImage")
      {
        this.service.formData.UserInfo.ProfileFileInfo = res.data;
      }
    });
  }
  editPic()
  {
    this.openbranchmodal("profilePic");
  }
  editCoverImage(){
    this.openbranchmodal("coverImage");
  }
  onRoleChange(){
    //this.rolelist
    // debugger;
    this.role = '';
    if(this.service.formData.UserInfo.RoleId > 0){
      for(var i = 0 ;i<this.rolelist.length;i++){
        if(this.rolelist[i].Id == this.service.formData.UserInfo.RoleId){
          this.role = this.rolelist[i].Name;
          if(this.role == "Student" || this.role == "Teacher" || this.role == "Parent"){
            this.commonService.getclasses().subscribe((res:any)=>{
              this.classList = res;
            });
          }
          break;
        }
      }
    }
  }
  classChange(){
    if(this.service.formData.StudentInfo.ClassId > 0){
      this.commonService.getbatches(this.service.formData.StudentInfo.ClassId).subscribe((res:any)=>{
this.batchList = res;
      });
    }
    if(this.service.formData.StudentInfo.ClassId < 0){
      this.batchList = [];
      this.openAddMLInfoModal('ClassData');
    }
    else{

      this.batchList = [];
    }
  }
  branchChange() {
    if(this.service.formData.UserInfo.SchoolId > 0 && this.service.formData.UserInfo.BranchId > 0){
      this.service.getclasss(this.service.formData.UserInfo.SchoolId,this.service.formData.UserInfo.BranchId).subscribe((res:any)=>{
        this.classList = res;
              });
            }
          }
  lenghtCounter(){
    this.currentLength = this.service.formData.UserInfo.About.length;
    // this.disabled = this.currentLength<this.maxLength?true;
  }
 

openAddMLInfoModal(tableName) {
  const initialState = {
    
    list: [
      {id:0},
      {Text:tableName},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]
    
  };
  
  this.bsModalRef2 = this.modalService.show(AddmlinfoComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
}
resetPwd(){  
  const initialState = {
    
    list: [
      {id:0},
      {classId:this.service.formData.StudentInfo.ClassId}
    ]
    
  };
  this.bModalRef = this.modalService.show(PasswordResetComponent, { id: CUSTOMCONST.RESET_PWD_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
  this.bModalRef.content.event.subscribe(res => {
   
 });
}
userAcademicYearLogInfo(){
  
  const initialState = {
    
    list: [
      {id:this.list[0].id},
    ]
    
  };
  this.bModalRef = this.modalService.show(UserAcademicYearLogComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
 
}
userLogHistory(){
  const initialState = {
    
    list: [
      {id:this.list[0].id},
    ]
    
  };
  this.bModalRef = this.modalService.show(UserLogInfoComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });
 
}
studentOffer(studentId:number){
  const initialState = {
    list: [
      { id: studentId }
    ]

  };
  this.bModalRef = this.modalService.show(StuOfferComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-xl col-12', ignoreBackdropClick: true });
}
}
