import { Component, OnInit } from '@angular/core';
import { faFolder,faCalendarDays,faDollarSign,faEnvelope,faPencilSquare } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-parent-nav',
  templateUrl: './parent-nav.component.html',
  styleUrls: ['./parent-nav.component.scss']
})
export class ParentNavComponent implements OnInit {

  folderIcon = faFolder;
  calendarIcon = faCalendarDays;
  financeIcon = faDollarSign;
  messageIcon = faEnvelope;
  editIcon = faPencilSquare;
  
  constructor() { }

  ngOnInit(): void {
  }

}
