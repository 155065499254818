<div class="container-fluid pt-0">
    <div class="banner">
        <div class="banner-bg-image">
          <img src="../../assets/images/2X/common-banner-bg-image.png" alt="common-banner-bg-image">
        </div>
        
        <div class="banner-content-left d-none d-sm-block">
          <h2 class="w-100 text-left theam-color-11">Student Dashboard:</h2> 
          <h1 class="w-100 text-left theam-color-4">Create a self learning culture</h1> 
          <h4 class="w-100">The flagship of our learning management system offers sharp design, even sharper functionality, and scalability for any student need.</h4>
                    
        </div>
      </div>
      <section class="bg-theam-body investing-yourself">
        <div class="container-fluid" >
          <div class="position-relative py-80px">
            <div class="row justify-content-center position-relative z-inxex-1">
              <div class="col-12 col-md-8 align-self-center">
                <div class="header ">

                  <h2 class="d-block text-left theam-color-5 title">An all-in-one dashboard for digital learning is the best way to ensure students' success.</h2>
                  
                  <h2 class="theam-color-11 m-0 mb-1">Students can access all their learning via the Student Dashboard, including assignments, assessments, resources, online interactive classes, notifications, and announcements from the school.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">A mobile-friendly hub where schools can share assignments, resources, announcements, and badges.</h2>
                  <h2 class="d-block text-left theam-color-5 title">Everyone can develop executive functioning skills if they are taught.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">One-stop learning and resources for academics, skills, and projects</h2>
                  <h2 class="theam-color-11 m-0 mb-1">In school and at home, learners can access everything</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Designed especially for K-12 and higher education students, this digital learning tool.</h2>
                  <h2 class="d-block text-left theam-color-9 title">Designed to improve executive functioning</h2>
                  <h2 class="theam-color-11 m-0 mb-1">The Student Dashboard is a hybrid learning platform that provides one-stop access to assignments, assessments, and online classes.</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Various tools and resources are available. The tool helps learners better manage their time, improve organization, and develop executive function skills by promoting student agency.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Dealing with digital noise with this solution</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Despite the multitude of digital learning tools available to learners, like academics, skills, third-party apps, and others, learners can get lost or confused. Because learners haven't necessarily developed the capacity to cope with the noise of the digital world, this can sometimes lead to information overload. As a result of such challenges, we developed Student Dashboard to empower students to take control of their own learning.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">A single online learning hub</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Student Dashboard helps students develop those important skills and provides context for why they are so valuable by providing a hub for all their assignments, announcements, and resources.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">A product of learner feedback</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">The Student Dashboard differs from other education dashboards because it was developed using feedback from learners worldwide. Our tool empowers learners to take control of their learning journey based on their interests in learning at school, home, in the community, and in society.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Powered by amazing e-learning dashboards</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">In addition to providing clarity and improving usability, Student Dashboard teaches essential skills that can be transferred to the workplace, while inspiring students to discover a path that sparks their curiosity.</h2>

                  <h2 class="d-block text-left theam-color-5 title">Why Choose HLEP's Student Dashboard?</h2>
                  <h2 class="d-block text-left theam-color-9 title">Comprehensive Learning Ecosystem:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">HLEP offers a one-stop solution for all your educational needs, making it easier to manage your learning experience.</h2>
                                    
                  <h2 class="d-block text-left theam-color-9 title">Adaptive and Student-Centric:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Our platform adapts to your learning style, ensuring that you get the most out of every educational opportunity.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Empowering Learners:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">HLEP empowers students to take control of their education, fostering independence and a growth mindset.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Innovation in Education:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">We're at the forefront of educational technology, constantly evolving to provide the latest tools and resources.</h2>
                 
                  <h2 class="d-block text-left theam-color-9 title">Global Community:</h2>                
                  <h2 class="theam-color-11 m-0 mb-1">Join a vibrant community of learners, educators, and experts, expanding your horizons and fostering connections worldwide.</h2>
                  
                  <h2 class="d-block text-left theam-color-9 title">Career and College Planning:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Begin planning your future with dedicated career and college preparation resources. Explore career paths, college options, and scholarship opportunities.</h2>

                  <h2 class="d-block text-left theam-color-9 title">Parental Involvement:</h2>
                  <h2 class="theam-color-11 m-0 mb-1">Parents can stay engaged in their child's education with a secure parent portal, providing real-time insights into their academic journey.</h2>

                  <h2 class="d-block text-left theam-color-10 title">HLEP's Student Dashboard is not just a tool; it's your passport to a world of knowledge, growth, and achievement. With our platform, you can explore, learn, and excel on your terms. Join us in embracing the future of education, where every student is at the center of their learning journey. Experience the world's most advanced student dashboard with HLEP and unlock your full potential as a learner. Your future begins here.</h2>                

                </div>
              </div>
             </div>
          </div>
          </div>
          
        
      </section>
</div>